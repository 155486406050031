export enum GenderEnum
{
	"Man" = 0,
	"Woman" = 1
}

export const Genders = {
	[GenderEnum.Man]: {
		name: {
			en: "Man",
			fa: "مرد",
		},
		thumbnail: "/file-manager/static/gender/man.png"
	},

	[GenderEnum.Woman]: {
		name: {
			en: "Woman",
			fa: "زن",
		},
		thumbnail: "/file-manager/static/gender/woman.png"
	}
}
