


































































import { Vue, Component, Provide } from "vue-property-decorator"
import { FadeTransition } from "vue2-transitions"
import TopNavbar from "./TopNavbar.vue"
import { Route } from "vue-router"
import { ThemeConfigurationType } from "../../types"

@Component({ components: { FadeTransition, TopNavbar } })
export class DashboardLayout extends Vue
{
	private readonly year: number = new Date().getFullYear()

	private readonly sidebarRoutes: Route[] = [
		this.$router.resolve({ name: "Dashboard_Researches" }).route,
		this.$router.resolve({ name: "Dashboard_Select_Research" }).route,
		this.$router.resolve({ name: "Dashboard_Users" }).route,
		this.$router.resolve({ name: "Dashboard_Profile" }).route,
		this.$router.resolve({ name: "Dashboard_Logout" }).route,
		this.$router.resolve({ name: "Dashboard_Disconnect" }).route
	].filter(
		sideBarRoute => !( sideBarRoute.meta?.accessControlList instanceof Array ) || sideBarRoute.meta.accessControlList.includes( this.$authentication.userToken?.payloadParsed.role )
	)

	@Provide()
	private readonly isSidebarExtended = { flag: false }

	@Provide("themeSelector")
	private readonly themeSelector: ThemeConfigurationType = {
		"isOpen": false,
		"backgroundColors": [ "white", "dark" ],
		"sidebarColors": [
			{ "color": "primary", "active": false, "value": "primary" },
			{ "color": "vue", "active": true, "value": "vue" },
			{ "color": "info", "active": false, "value": "blue" },
			{ "color": "success", "active": false, "value": "green" }
		],
		"theme": { "backgroundColor": 1, "sidebarColor": 1 }
	}

	themeSelectorSaveTheme(): void
	{
		window.localStorage.setItem(
			"theme",
			`${this.themeSelector.theme[ "backgroundColor" ]}$${this.themeSelector.theme[ "sidebarColor" ]}`
		)
	}

	themeSelectorLoadTheme(): void
	{
		const userTheme = window.localStorage.getItem( "theme" )
		if( userTheme !== null && /^[0,1]\$[0-3]$/.test( userTheme ) )
		{
			const [ backgroundColor, sidebarColor ] = userTheme.split( "$" )

			if( backgroundColor in this.themeSelector.backgroundColors )
				this.themeSelector.theme[ "backgroundColor" ] = Number( backgroundColor )

			if( sidebarColor in this.themeSelector.sidebarColors )
				this.themeSelector.theme[ "sidebarColor" ] = Number( sidebarColor )
		}

		this.themeSelectorChangeToggleMode(
			this.themeSelector.backgroundColors[
				this.themeSelector.theme[ "backgroundColor" ]
			]
		)
		this.themeSelectorChangeSidebarBackground(
			this.themeSelector.sidebarColors[
				this.themeSelector.theme[ "sidebarColor" ]
			]
		)
	}

	themeSelectorChangeSidebarBackground( item: ThemeConfigurationType[ "sidebarColors" ][ number ] ): void
	{
		this.themeSelector.theme[ "sidebarColor" ] = this.themeSelector.sidebarColors.indexOf( item )
		this.themeSelectorSaveTheme()

		this.themeSelector.sidebarColors.forEach(sidebarColor => { sidebarColor.active = false })
		item.active = true
	}

	themeSelectorChangeToggleMode( type: ThemeConfigurationType[ "backgroundColors" ][ number ] ): void
	{
		this.themeSelector.theme[ "backgroundColor" ] = this.themeSelector.backgroundColors.indexOf( type )
		this.themeSelectorSaveTheme()

		if( this.themeSelector.backgroundColors[ 0 ] === type )
			document.body.classList.add( "white-content" )
		else
			document.body.classList.remove( "white-content" )
	}

	public created(): void
	{
		const isSidebarExtended = window.localStorage.getItem( "showSidebar" )
		if( isSidebarExtended !== null && ["0", "1"].includes( isSidebarExtended ) )
			this.isSidebarExtended.flag = Boolean(Number( isSidebarExtended ))

		this.$watch(
			"isSidebarExtended.flag",
			( newValue: boolean, oldValue: boolean ) => {
				if( newValue )
				{
					document.body.classList.add( "nav-open" )
					document.body.classList.remove("sidebar-mini")
				}
				else
				{
					document.body.classList.remove( "nav-open" )
					document.body.classList.add("sidebar-mini")
				}

				window.localStorage.setItem(
					"showSidebar",
					Number( newValue ).toString()
				)
			},
			{ deep: false, immediate: true }
		)

		this.themeSelectorLoadTheme()
	}
}

export default DashboardLayout
