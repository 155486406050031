import { RouterEventHandlerError } from './errors'
import { ErrorHandler, Route, NavigationGuard, RedirectOption, VueRouter } from "vue-router/types/router"
import { UserRole as UserRoleEnum } from "./enums"


const RootPaths = {
	[UserRoleEnum.SuperAdmin]: { name: 'Dashboard_Researches', query: undefined, params: undefined },
	[UserRoleEnum.Admin]: { name: 'Dashboard_Researches', query: undefined, params: undefined },
	[UserRoleEnum.Agent]: { name: 'Dashboard_Select_Research', query: undefined, params: undefined },
	[UserRoleEnum.Operator]: { name: 'Dashboard_Select_Research', query: undefined, params: undefined }
}

export const Global: { beforeEach?: ( thisPointer: VueRouter ) => NavigationGuard, beforeResolve?: ( thisPointer: VueRouter ) => NavigationGuard, afterEach?: ( thisPointer: VueRouter ) => ( (to: Route, from: Route) => any ), onReady?: ( thisPointer: VueRouter ) => ( ...args: any[] ) => any, onError?: ( thisPointer: VueRouter ) => ErrorHandler } = {
	beforeEach( thisPointer )
	{
		return (
			<NavigationGuard>function( this: VueRouter, to, from, next )
			{
				// this => VueRouter Instance
				// this.app => Vue Instance

				if( to.meta?.accessControlList instanceof Array )
				{
					if( to.meta.accessControlList.includes( this.app.$authentication.userToken?.payloadParsed.role ) )
					{
						next()
						return
					}

					next(RootPaths[ this.app.$authentication.userToken?.payloadParsed.role as number ?? UserRoleEnum.Operator ])
					return
				}

				next()
			}
		).bind( thisPointer )
	}
}

export const Root: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	redirect( this: { Router: VueRouter }, to)
	{
		return RootPaths[ this.Router.app.$authentication.userToken?.payloadParsed.role as number ?? UserRoleEnum.Operator ]

		/*if (to.query.to === 'foo')
			return { path: '/foo', query: undefined }
		else if (to.hash === '#baz')
			return { name: 'baz', hash: '' }
		//else if (to.params.id)
		//	return '/with-params/:id'
		else
			return { name: 'Dashboard', query: undefined, params: undefined }
		*/
	}
}

export const DashboardResearchesGroups: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Researches_Groups' && 'researchId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/check-exist?with-disabled=true`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardResearchesQuestions: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Researches_Questions' && 'researchId' in to.params && 'groupId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/group/${to.params.groupId}/check-exist?with-deleted=true`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardResearchesQuestionChoices: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if ( to.name === 'Dashboard_Researches_Question_Choices' && 'researchId' in to.params && 'groupId' in to.params && 'questionId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/group/${to.params.groupId}/question/${to.params.questionId}/check-is-selectable`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardResearchesQuestionConditions: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Researches_Question_Conditions' && 'researchId' in to.params && 'groupId' in to.params && 'questionId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/group/${to.params.groupId}/question/${to.params.questionId}/check-exist`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardDocuments: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Documents' && 'researchId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/check-exist`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardDocumentsNew: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Documents_New' && 'researchId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/check-exist`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardDocumentsEdit: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Documents_Edit' && 'researchId' in to.params && 'documentId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/document/${to.params.documentId}/check-exist`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardDocumentsQuestionnaire: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_Documents_Questionnaire' && 'researchId' in to.params && 'documentId' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/research/${to.params.researchId}/document/${to.params.documentId}/check-exist`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardUserPermissions: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		if( to.name === 'Dashboard_User_Permissions' && 'userMobile' in to.params )
		{
			try
			{
				const response = await fetch(
					`${this.Router.app.$authentication.serverAddress}/user/${to.params.userMobile}/check-exist?search-conditions={"role":${UserRoleEnum.Operator}}`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.Router.app.$authentication.jsonWebToken
						}
					}
				)

				if( response.ok )
				{
					next()
					return
				}
				else
					throw new RouterEventHandlerError(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json(),
						}
					)
			}
			catch( error )
			{
				console.error( error )
			}
		}

		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}

export const DashboardLogout: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		this.Router.app.$authentication.userTokenFlush()
	}
}

export const DashboardDisconnect: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		this.Router.app.$authentication.serverConnectionFlush()
	}
}

export const NotFound: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		let fromName!: string
		if( typeof to.query.from === 'string' )
			fromName = to.query.from
		else if( to.query.from instanceof Array && typeof to.query.from[ to.query.from.length - 1 ] === 'string' )
			fromName = to.query.from[ to.query.from.length - 1 ] as string

		if( to.name === 'NotFound' && 'from' in to.query && /^\/.*$/.test( fromName ) )
			next()
		else
			next('/')
	}
}

export const FallBackRoute: { [key: string]: NavigationGuard } & { redirect?: RedirectOption } = {
	async beforeEnter( this: { Router: VueRouter }, to, from, next)
	{
		next(
			{
				name: 'NotFound',
				query: {
					from: from?.fullPath ?? '/'
				}
			}
		)
	}
}
