























import { Vue, Component, Prop } from "vue-property-decorator"

@Component({ name: 'base-table' })
export class BaseTable extends Vue
{
	// Table columns
	@Prop({ type: Array, default: () => [] })
	private readonly columns!: string[]
	// Table records
	@Prop({ type: Array, default: () => [] })
	private readonly data!: { [key: string]: unknown }[]
	// Whether table is striped or hover type
	@Prop({ type: String, default: "", validator: (type: unknown) => typeof type === "string" && [ "", "hover", "striped" ].includes( type ) })
	private readonly type!: string // striped | hover
	// <thead> css classes
	@Prop({ type: String, default: "" })
	private readonly theadClasses!: string
	// <tbody> css classes
	@Prop({ type: String, default: "" })
	private readonly tbodyClasses!: string

	private get tableClass(): string
	{
		return this.type && `table-${this.type}`
	}

	hasValue( record: { [key: string]: unknown }, column: string ): boolean
	{
		return this.recordValue( record, column ) !== undefined
	}

	recordValue( record: { [key: string]: unknown }, column: string ): unknown
	{
		return record[ column.toLowerCase() ]
	}
}

export default BaseTable
