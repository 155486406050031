import { default as Vue } from "vue"
import { default as VueRouterPlugin } from "vue-router"
import { default as VueRouterPrefetchPlugin } from 'vue-router-prefetch'
import { default as BaseComponent } from "./App.vue"
import { Router as RouterInstance } from "./router"
import './registerServiceWorker'
import * as Helpers from "./helpers"
import { VueAuthenticationPlugin } from "@smn7231/web-authentication-plugin"
import { BaseInput as BaseInputComponent, Card as CardComponent, BaseDropdown as BaseDropdownComponent, BaseButton as BaseButtonComponent, BaseCheckbox as BaseCheckboxComponent, SideBar, NotificationPlugin } from "./components"

// Importing styles
import "@/assets/sass/black-dashboard.scss"
import "@/assets/css/font-awesome.css"
import "@/assets/css/nucleo-icons.css"
import "@/assets/css/poppins-font.css"
import "@/assets/css/vazir-font.css"
import "@/assets/demo/demo.css"


// Initializing global helper functions
Vue.prototype.$helpers = Helpers
declare module 'vue/types/vue' {
	interface Vue {
		$helpers: typeof Helpers
	}
}

// Initializing global components
Vue.component(BaseInputComponent.name, BaseInputComponent)
Vue.component(CardComponent.name, CardComponent)
Vue.component(BaseDropdownComponent.name, BaseDropdownComponent)
Vue.component(BaseButtonComponent.name, BaseButtonComponent)
Vue.component(BaseCheckboxComponent.name, BaseCheckboxComponent)
Vue.component("SideBar", SideBar)

// Initializing global directives
Vue.directive(
	'click-outside',
	{
		bind: ( el, binding, vnode, oldVnode ) => {
			// here I check that click was outside the el and his childrens
			// and if it did, call method provided in attribute value
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			el.clickOutsideEvent = event => !( el === event.target || el.contains(event.target) ) && vnode.context[ binding.expression ]( event )

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			document.body.addEventListener('click', el.clickOutsideEvent)
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		unbind: ( el, binding, vnode, oldVnode ) => document.body.removeEventListener('click', el.clickOutsideEvent)
	}
)

// Initializing global plugins
Vue.use(VueAuthenticationPlugin)
Vue.use(VueRouterPlugin)
Vue.use(VueRouterPrefetchPlugin)
Vue.use(NotificationPlugin)

/* eslint-disable no-new */
new Vue(
	{
		router: RouterInstance,
		render: ( createElement, context ) => createElement( BaseComponent ),
		created()
		{
			const onConnected = () => {
				setTimeout(
					() => {
						this.$authentication.onAuthenticated = onAuthenticated

						this.$router.push( this.$router.resolve({ name: 'Login', query: undefined, params: undefined }).location )
					},
					250
				)

			}
			const onAuthenticated = () => {
				setTimeout(
					() => { this.$router.push( this.$router.resolve({ name: 'Dashboard', query: undefined, params: undefined }).location ) },
					250
				)
			}

			this.$authentication.onConnected = () => { this.$authentication.onConnected = onConnected }
			this.$authentication.onDisconnected = () => {
				this.$authentication.onConnected = onConnected
				this.$router.push( this.$router.resolve({ name: 'SetServerConnection', query: undefined, params: undefined }).location )
			}
			this.$authentication.onAuthenticated = () => { this.$authentication.onAuthenticated = onAuthenticated }
			this.$authentication.onUnauthenticated = () => {
				this.$authentication.onAuthenticated = onAuthenticated
				this.$router.push( this.$router.resolve({ name: 'Login', query: undefined, params: undefined }).location )
			}

			this.$authentication.start()
		}
	}
).$mount("#app")
