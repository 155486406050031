import { Vue, Component } from "vue-property-decorator"
import { default as jMoment } from "moment-jalaali"
import { FetchOptionsType, UserPermissionModuleType, UserPermissionRepositoryType, UserPermissionType, ResearchPermissionEnum } from "./Types"

@Component
export class UserPermissionMixin extends Vue {
	protected readonly userPermissionModule: UserPermissionModuleType = {
		"repository": {
			"loading": true,
			"count": 0,
			"data": [],
			"idMap": {}
		},
		"new": {
			"showModal": false,
			"loading": false,
			"data": {
				researchId: null,
				permissions: []
			}
		}
	}

	protected static researchIdValidator( id?: unknown ): boolean
	{
		return typeof id === "number" && id > 0
	}

	protected static permissionsValidator( permissions?: unknown ): boolean
	{
		if( permissions instanceof Array && permissions.length > 0 )
			for( const element of permissions )
			{
				if(
					[
						ResearchPermissionEnum.Design,
						ResearchPermissionEnum.Export,
						ResearchPermissionEnum.Disable,
						ResearchPermissionEnum.ViewLogs,
						ResearchPermissionEnum.DocumentInsert,
						ResearchPermissionEnum.DocumentQuestionnaire,
						ResearchPermissionEnum.DocumentEdit,
						ResearchPermissionEnum.DocumentDelete
					].includes( element )
				) continue

				return false
			}
		else
			return false

		return true
	}

	protected async userPermissionsFetch( userMobile: UserPermissionType[ "userMobile" ], fetchOptions?: null | FetchOptionsType ): Promise<void>
	{
		this.userPermissionModule.repository.loading = true

		try
		{
			let groupingQuery: string | null = null
			let researchName: string | null = null
			let searchConditions: string | null = null
			if( fetchOptions && typeof fetchOptions === "object" )
			{
				if( fetchOptions[ "grouping" ] && typeof fetchOptions[ "grouping" ] === "object" && typeof fetchOptions[ "grouping" ].offset === "number" && typeof fetchOptions[ "grouping" ].limit === "number" )
					groupingQuery = "&grouping=" + `${fetchOptions[ "grouping" ].limit}@${fetchOptions[ "grouping" ].offset}`

				if ( typeof fetchOptions[ "researchName" ] === "string" && fetchOptions[ "researchName" ].length > 0 )
					researchName = "&research-name=" + fetchOptions[ "researchName" ]

				if( fetchOptions[ "searchConditions" ] && typeof fetchOptions[ "searchConditions" ] === "object" )
					searchConditions = "&search-conditions=" + JSON.stringify( fetchOptions[ "searchConditions" ] )
			}

			const response = await fetch(
					`${this.$authentication.serverAddress}/user/${userMobile}/acl?${researchName ?? ""}${searchConditions ?? ""}${groupingQuery ?? ""}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					}
				}
			)

			if( response.ok )
			{
				const responseBody: { count: number, data: UserPermissionType[] } = await response.json()

				this.userPermissionModule.repository.count = responseBody.count
				this.userPermissionModule.repository.data = []
				this.userPermissionModule.repository.idMap = {}
				responseBody.data.forEach(
					userPermission => {
						this.userPermissionModule.repository.idMap[ userPermission.id ] = this.userPermissionModule.repository.data.push(
							{
								...userPermission,
								"createAt": jMoment.utc( userPermission.createAt ).local(),
								"updateAt": typeof userPermission.updateAt as string | null === "string" ? jMoment.utc( userPermission.updateAt ).local() : null,

								"updateRepository": {
									"showModal": false,
									"loading": false,
									"data": { "permissions": userPermission.permissions }
								},

								"removeRepository": {
									"showConfirmModal": false,
									"loading": false
								}
							}
						) - 1
					}
				)
			}
			else if( response.status === 404 )
			{
				this.$notifications.warning( "هیچ دسترسی یافت نشد" )

				this.userPermissionModule.repository.count = 0
				this.userPermissionModule.repository.data = []
				this.userPermissionModule.repository.idMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری دسترسی‌ها رخ داد" )
		}

		this.userPermissionModule.repository.loading = false
	}

	protected async userPermissionFetch( userMobile: UserPermissionType[ "userMobile" ], userPermissionId: UserPermissionType[ "id" ] ): Promise<UserPermissionRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}/acl/${userPermissionId}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const userPermission: UserPermissionType = await response.json()

				return {
					...userPermission,
					"createAt": jMoment.utc( userPermission.createAt ).local(),
					"updateAt": typeof userPermission.updateAt as string | null === "string" ? jMoment.utc( userPermission.updateAt ).local() : null,

					"updateRepository": {
						"showModal": false,
						"loading": false,
						"data": { "permissions": userPermission.permissions }
					},

					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					}
				}
			}
			else if ( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( exception )
		{
			if( exception === 404 )
			{
				this.$notifications.warning( "دسترسی یافت نشد" )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری اطلاعات دسترسی رخ داد" )

			throw exception
		}
	}

	protected async userPermissionAdd( userMobile: UserPermissionType[ "userMobile" ] ): Promise<boolean>
	{
		this.userPermissionModule.new.loading = true

		if( !UserPermissionMixin.researchIdValidator( this.userPermissionModule.new.data.researchId ) )
		{
			this.$notifications.error( "طرح انتخابی نامعتبر می‌باشد" )

			this.userPermissionModule.new.loading = false
			return false
		}

		if( !UserPermissionMixin.permissionsValidator( this.userPermissionModule.new.data.permissions ) )
		{
			this.$notifications.error( "دسترسی‌های انتخاب شده نامعتبر می‌باشند" )

			this.userPermissionModule.new.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}/acl`,
				{
					method: "PUT",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify(
						{
							"researchId": this.userPermissionModule.new.data.researchId,
							"permissions": this.userPermissionModule.new.data.permissions
						}
					)
				}
			)

			if( response.ok )
			{
				(async () => {
					this.userPermissionModule.new.data = {
						researchId: null,
						permissions: []
					}

					this.$notifications.successful( "دسترسی با موفقیت ایجاد شد" )

					this.userPermissionModule.new.showModal = false
					this.userPermissionModule.new.loading = false
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.userPermissionModule.new.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام افزودن دسترسی رخ داد" )

			return false
		}
	}

	protected async userPermissionEdit( userPermission: UserPermissionRepositoryType ): Promise<boolean>
	{
		userPermission.updateRepository.loading = true

		if( !UserPermissionMixin.permissionsValidator( userPermission.updateRepository.data?.permissions ) )
		{
			this.$notifications.error( "دسترسی‌های انتخاب شده نامعتبر می‌باشند" )

			userPermission.updateRepository.loading = false
			return false
		}

		userPermission.updateRepository.data = userPermission.updateRepository.data as UserPermissionType

		equalityCheck:
		if( userPermission.updateRepository.data.permissions.length === userPermission.permissions.length )
		{
			for( let newPermissionIndex = 0, newPermissionsLength = userPermission.updateRepository.data.permissions.length; newPermissionIndex < newPermissionsLength; newPermissionIndex++)
			{
				if(userPermission.permissions.includes( userPermission.updateRepository.data.permissions[ newPermissionIndex ] ))
					continue

				break equalityCheck
			}
			for( let oldPermissionIndex = 0, oldPermissionsLength = userPermission.permissions.length; oldPermissionIndex < oldPermissionsLength; oldPermissionIndex++)
			{
				if(userPermission.updateRepository.data.permissions.includes( userPermission.permissions[ oldPermissionIndex ] ))
					continue

				break equalityCheck
			}

			userPermission.updateRepository.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userPermission.userMobile}/acl/${userPermission.id}`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ "permissions": userPermission.updateRepository.data.permissions })
				}
			)

			if( response.ok )
			{
				(async () => {
					userPermission.updateRepository.showModal = false
					userPermission.updateRepository.loading = false

					this.$notifications.successful( "دسترسی با موفقیت ویرایش شد" )
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			userPermission.updateRepository.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام ویرایش دسترسی رخ داد" )

			return false
		}
	}

	protected async userPermissionRemove( userMobile: UserPermissionType[ "userMobile" ], userPermissionId: UserPermissionType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}/acl/${userPermissionId}`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "دسترسی با موفقیت حذف شد" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام حدف دسترسی رخ داد" )
		}
	}
}

export default UserPermissionMixin
