
































































































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { ResearchMixin, DocumentMixin } from '../mixins'
import { BaseTable, Paginator, Modal } from '../components'
import type { ResearchRepositoryType } from '../mixins/ResearchMixin'
import type { DocumentRepositoryType } from "../mixins/DocumentMixin"
import { ResearchPermissionEnum } from '../mixins/UserPermissionMixin'

@Component({ components: { BaseTable, Modal, Paginator } })
export default class Documents extends Mixins( DocumentMixin )
{
	private readonly ResearchPermissionEnum = ResearchPermissionEnum
	private research: ResearchRepositoryType | null = null
	private maxRecordsPerPage = 7
	private currentPage = Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1

	private get pagesCount(): number
	{
		return Math.ceil( this.documentModule.repository.count / this.maxRecordsPerPage )
	}

	private get researchName(): string
	{
		return this.research?.name ?? ""
	}

	getUserNameFromDocument( document: DocumentRepositoryType ): string
	{
		return typeof document[ 'userFirstName' ] === 'string' && typeof document[ 'userLastName' ] === 'string' ? `${document[ 'userFirstName' ]} ${document[ 'userLastName' ]}` : ''
	}

	loadDocuments(): Promise<void>
	{
		return this.documentsFetch(
			{
				researchId: parseInt(this.$route.params.researchId),
				grouping: {
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			}
		)
	}

	@Watch('currentPage', { deep: false, immediate: false })
	private onCurrentPageChanged( newCurrentPage: number, oldCurrentPage: number )
	{
		if( Number( this.$route.query.page ) !== newCurrentPage )
			this.$router.replace({ query: { page: String( newCurrentPage ) } })

		this.loadDocuments().then(
			() => {
				if( this.documentModule.repository.count < 1 && newCurrentPage > 1 )
					this.currentPage = 1
			}
		)
	}

	created(): void
	{
		ResearchMixin.researchFetch.call( this, parseInt(this.$route.params.researchId) ).then(
			result => {
				this.research = result
				this.loadDocuments()
			}
		)
	}
}
