import { Vue, Component } from "vue-property-decorator"
import { ProvinceModuleType, ProvinceType, CityType } from "./Types"

@Component
export class ProvinceMixin extends Vue {
	protected readonly provinceModule: ProvinceModuleType = {
		"provinceRepository": {
			"loading": true,
			"count": 0,
			"data": [],
			"idMap": {}
		},
		"cityRepository": {
			"loading": true,
			"count": 0,
			"data": [],
			"idMap": {}
		}
	}

	protected async provincesFetch( grouping?: { "offset": number, "limit": number } ): Promise<void>
	{
		this.provinceModule.provinceRepository.loading = true

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/provinces${( grouping !== null && typeof grouping === "object" && typeof grouping.offset === "number" && typeof grouping.limit === "number" ) ? `?grouping=${grouping.limit}@${grouping.offset}` : ""}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const responseBody = await response.json() as { count: number, data: ProvinceType[] }

				this.provinceModule.provinceRepository.count = responseBody.count
				this.provinceModule.provinceRepository.data = []
				this.provinceModule.provinceRepository.idMap = {}
				responseBody.data.forEach(
					province => this.provinceModule.provinceRepository.idMap[ province.id ] = this.provinceModule.provinceRepository.data.push( province ) - 1
				)
			}
			else if ( response.status === 404 )
			{
				this.$notifications.warning( "هیچ استانی یافت نشد" )

				this.provinceModule.provinceRepository.count = 0
				this.provinceModule.provinceRepository.data = []
				this.provinceModule.provinceRepository.idMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری استان‌ها رخ داد" )
		}

		this.provinceModule.provinceRepository.loading = false
	}

	protected async citiesFetch( provinceId: ProvinceType[ "id" ], grouping?: { "offset": number, "limit": number } ): Promise<void>
	{
		this.provinceModule.cityRepository.loading = true

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/provinces/${provinceId}/cities${( grouping !== null && typeof grouping === "object" && typeof grouping.offset === "number" && typeof grouping.limit === "number" ) ? `?grouping=${grouping.limit}@${grouping.offset}` : ""}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const responseBody = await response.json() as { count: number, data: CityType[] }

				this.provinceModule.cityRepository.count = responseBody.count
				this.provinceModule.cityRepository.data = []
				this.provinceModule.cityRepository.idMap = {}
				responseBody.data.forEach(
					city => this.provinceModule.cityRepository.idMap[ city.id ] = this.provinceModule.cityRepository.data.push( city ) - 1
				)
			}
			else if ( response.status === 404 )
			{
				this.$notifications.warning( "هیچ شهری یافت نشد" )

				this.provinceModule.cityRepository.count = 0
				this.provinceModule.cityRepository.data = []
				this.provinceModule.cityRepository.idMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری شهرها رخ داد" )
		}

		this.provinceModule.cityRepository.loading = false
	}
}

export default ProvinceMixin
