export enum ResearchPermissionEnum {
	"Design" = "design",
	"Export" = "export",
	"Disable" = "disable",
	"ViewLogs" = "viewLogs",
	"DocumentInsert" = "documentInsert",
	"DocumentQuestionnaire" = "documentQuestionnaire",
	"DocumentEdit" = "documentEdit",
	"DocumentDelete" = "documentDelete"
}

export const ResearchPermissions = {
	[ResearchPermissionEnum.Design]: {
		name: {
			en: "Design Research",
			fa: "طراحی طرح"
		},
		description: {
			en: "Design Research",
			fa: "طراحی طرح"
		},
		thumbnail: "/file-manager/static/research-permissions/design.png"
	},

	[ResearchPermissionEnum.Export]: {
		name: {
			en: "Export Research",
			fa: "صدور خروجی از طرح"
		},
		description: {
			en: "Export Research",
			fa: "صدور خروجی از طرح"
		},
		thumbnail: "/file-manager/static/research-permissions/export.png"
	},

	[ResearchPermissionEnum.Disable]: {
		name: {
			en: "Disable Research",
			fa: "فعال / غیرفعال کردن طرح"
		},
		description: {
			en: "Disable Research",
			fa: "فعال / غیرفعال کردن طرح"
		},
		thumbnail: "/file-manager/static/research-permissions/disable.png"
	},

	[ResearchPermissionEnum.ViewLogs]: {
		name: {
			en: "View Research Logs",
			fa: "مشاهده گذارشات طرح"
		},
		description: {
			en: "View Research Logs",
			fa: "مشاهده گذارشات طرح"
		},
		thumbnail: "/file-manager/static/research-permissions/view-logs.png"
	},

	[ResearchPermissionEnum.DocumentInsert]: {
		name: {
			en: "Insert Document",
			fa: "افزودن پرونده"
		},
		description: {
			en: "Insert Document",
			fa: "افزودن پرونده"
		},
		thumbnail: "/file-manager/static/research-permissions/document-insert.png"
	},

	[ResearchPermissionEnum.DocumentQuestionnaire]: {
		name: {
			en: "Document Questionnaire",
			fa: "تکمیل پرونده"
		},
		description: {
			en: "Document Questionnaire",
			fa: "تکمیل پرونده"
		},
		thumbnail: "/file-manager/static/research-permissions/document-questionnaire.png"
	},

	[ResearchPermissionEnum.DocumentEdit]: {
		name: {
			en: "Edit Document",
			fa: "ویرایش پرونده"
		},
		description: {
			en: "Edit Document",
			fa: "ویرایش پرونده"
		},
		thumbnail: "/file-manager/static/research-permissions/document-edit.png"
	},

	[ResearchPermissionEnum.DocumentDelete]: {
		name: {
			en: "Delete Document",
			fa: "حذف پرونده"
		},
		description: {
			en: "Delete Document",
			fa: "حذف پرونده"
		},
		thumbnail: "/file-manager/static/research-permissions/document-delete.png"
	}
}
