














































import { Component, Mixins } from "vue-property-decorator"
import { BaseTable } from '../components'
import { ResearchMixin } from '../mixins'
import { ResearchPermissionEnum } from '../mixins/UserPermissionMixin'

@Component({ components: { BaseTable } })
export class SelectResearch extends Mixins( ResearchMixin )
{
	private readonly ResearchPermissionEnum = ResearchPermissionEnum

	public created(): void
	{
		this.researchesFetch({  })
	}
}

export default SelectResearch
