

























import { Vue, Component, Watch } from "vue-property-decorator"
import { ServerConnectionType } from "@smn7231/web-authentication-plugin"

@Component
export default class SetServerConnection extends Vue {
	private serverAddress: string = ''
	private serverAddressValidation: boolean = false
	private serverPort: number | null = null
	private serverPortValidation: boolean = false
	private serverSecureConnection: boolean = false

	@Watch('serverAddress', { immediate: false, deep: false })
	private onServerAddressChanged( newServerAddress: string, oldServerAddress: string )
	{
		this.serverAddressValidation = /^(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))|((([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])))$/.test( newServerAddress )
	}

	@Watch('serverPort', { immediate: false, deep: false })
	private onServerPortChanged( newServerPort: number, oldServerPort: number )
	{
		this.serverPortValidation = typeof newServerPort === "number" && newServerPort > 1 && newServerPort < 65536
	}

	private formFieldsOnFocus( event: Event )
	{
		(<HTMLDivElement>(<HTMLInputElement>event.target).parentNode).classList.remove('alert-validate')
	}

	private formFieldsOnBlur( event: Event )
	{
		if( !this.$data[ (<HTMLInputElement>event.target).getAttribute('data-validator') as unknown as number ] )
			(<HTMLDivElement>(<HTMLInputElement>event.target).parentNode).classList.add('alert-validate')
	}

	private async setServerConnectionFormOnSubmit( event: Event )
	{
		if( !this.serverAddressValidation )
			return this.$notifications.warning( "لطفاً یک نشانی سرور معتبر وارد کنید" )
		if( !this.serverPortValidation )
			return this.$notifications.warning( "لطفاً یک شماره پورت معتبر وارد کنید" )

		try
		{
			await this.$authentication.setServerConnection( { address: this.serverAddress as ServerConnectionType[ "address" ], port: this.serverPort as number, secureConnection: this.serverSecureConnection } )
		}
		catch( exception )
		{
			console.error( exception )

			this.$notifications.error( 'اتصال با سرور معرفی شده برقرار نشد' )
		}
	}
}
