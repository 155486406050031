export class RouterEventHandlerError extends Error
{
	private readonly description

	/**
	 * Instantiate a `RouterEventHandlerError` Exception.
	 *
	 * @example
	 * `throw new RouterEventHandlerError()`
	 *
	 * @usageNotes
	 *
	 * @param errorObject object describing the error condition.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	constructor( errorObject?: object )
	{
		super()

		this.description = errorObject
	}
}

export default RouterEventHandlerError
