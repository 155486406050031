




























































































































import { Component, Vue, Watch } from "vue-property-decorator"
import jMoment from "moment-jalaali"
import VuePersianDatetimePicker from "vue-persian-datetime-picker"
import { QuestionType as QuestionTypeEnum, QuestionTypes, QuestionTypeValidationError } from "../enums"
import type { ResearchType } from "../mixins/ResearchMixin"
import type { GroupType } from "../mixins/GroupMixin"
import type { QuestionType } from "../mixins/QuestionMixin"
import type { ChoiceType } from "../mixins/ChoiceMixin"
import type { ConditionType } from "../mixins/ConditionMixin"

export type DocumentForm = {
	research: ResearchType & {
		groupMap: [number, number][]
		groups: GroupType[]
		questionMap: { [key: number]: number }
		questions: (
			QuestionType & {
				choices: ChoiceType[]
				conditions: (ConditionType & { checkFlag: boolean })[]
				relatedConditions: {
					conditionalQuestionIndex: number
					conditionIndex: number
				}[]
				conditionFlag: boolean
			}
		)[]
	}
	groupId: number
	answers: { [key: number]: unknown }
}

@Component({ components: { datePicker: VuePersianDatetimePicker } })
export class DocumentQuestionnaire extends Vue
{
	private currentGroupId = Number( this.$route.query.group ) > 0 && !isNaN( this.$route.query.group as unknown as number ) && Number( this.$route.query.group ) !== Infinity && Number( this.$route.query.group ) !== -Infinity ? Number( this.$route.query.group ) : -1
	private currentGroupIndex = -1
	private currentPage = Number( this.$route.query.page ) > 0 && !isNaN( this.$route.query.page as unknown as number ) && Number( this.$route.query.page ) !== Infinity && Number( this.$route.query.page ) !== -Infinity ? Number( this.$route.query.page ) : 1
	private pagesCount = 0
	private questionType = QuestionTypeEnum
	private loading = true
	private form: DocumentForm = {} as DocumentForm
	private customWatchersUnwatches: (() => void)[] = []

	get questionsMeetsConditions(): DocumentForm[ "research" ][ "questions" ][ number ][]
	{
		const questionsMeetsConditions: DocumentForm[ "research" ][ "questions" ][ number ][] = []

		for( let index = 0; index < this.form.research.questions.length; ++index )
			if( this.form.research.questions[ index ].conditionFlag )
				questionsMeetsConditions.push( this.form.research.questions[ index ] )

		return questionsMeetsConditions
	}

	private focusOnForm(): void
	{
		(<HTMLFormElement>this.$refs.form)?.focus()
	}

	private questionLabel( question: DocumentForm[ "research" ][ "questions" ][ number ] ): string
	{
		if( this.form.research.showQuestionsTitles === true && typeof question.title === "string" && question.title.length > 0 )
			return `${question.title} - (${question.code})`

		return question.code
	}

	private choiceLabel( choice: DocumentForm[ "research" ][ "questions" ][ number ][ "choices" ][ number ] ): string
	{
		return typeof choice.title === "string" && choice.title.length > 0 ? `${choice.code} - (${choice.title})` : choice.code
	}

	private clearQuestionAnswer( questionId: number ): void
	{
		this.$set(
			this.form.answers,
			questionId,
			""
		)
	}

	private checkQuestionRelatedCondition( question: DocumentForm[ "research" ][ "questions" ][ number ], answer: unknown ): void
	{
		for( let index = 0; index < question.relatedConditions.length; ++index )
		{
			const conditionalQuestionIndex = question.relatedConditions[ index ].conditionalQuestionIndex

			try
			{
				const condition = this.form.research.questions[ conditionalQuestionIndex ].conditions[ question.relatedConditions[ index ].conditionIndex ]

				condition.checkFlag = true

				QuestionTypes[ question.type ].validateAnswer(
					answer,
					condition.match
				)
			}
			catch( error )
			{
				if( error instanceof QuestionTypeValidationError )
				{
					this.form.research.questions[ conditionalQuestionIndex ].conditions[ question.relatedConditions[ index ].conditionIndex ].checkFlag = false
					continue
				}

				throw error
			}
			finally
			{
				this.setQuestionsConditionFlag( conditionalQuestionIndex )
			}
		}
	}

	private getConditionsCheckFlag( condition: DocumentForm[ "research" ][ "questions" ][ number ][ "conditions" ][ number ] ): boolean
	{
		return condition.checkFlag
	}

	private setQuestionsConditionFlag( questionIndex: number ): void
	{
		this.form.research.questions[ questionIndex ].conditionFlag = this.form.research.questions[ questionIndex ].conditions.every( this.getConditionsCheckFlag )

		this.$set(
			this.form.research.questions,
			questionIndex,
			this.form.research.questions[ questionIndex ]
		)
	}

	private unwatchCustomWatchers(): void
	{
		for( let index = 0; index < this.customWatchersUnwatches.length; ++index )
			this.customWatchersUnwatches[ index ]()

		this.customWatchersUnwatches = []
	}

	private validateAnswers(): boolean
	{
		try
		{
			for( const [questionId, answer] of Object.entries(this.form.answers) )
			{
				const questionIndex = this.form.research.questionMap[ questionId ]
				const questionType = this.form.research.questions[ questionIndex ].type
				const questionValidation = this.form.research.questions[ questionIndex ].validation
				const questionCode = this.form.research.questions[ questionIndex ].code

				this.form.answers[ questionId ] = QuestionTypes[ questionType ].validateAnswer( answer, questionValidation, questionCode )
			}
		}
		catch( error )
		{
			if( error instanceof QuestionTypeValidationError )
				this.$notifications.error( (error as QuestionTypeValidationError).messageBag.fa )
			else
				this.$notifications.error( "خطای ناشناخته در هنگام آماده سازی اطلاعات برای ارسال رخ داد." )

			console.error( error )

			return false
		}

		return true
	}

	private async fetchData(): Promise<void>
	{
		this.loading = true

		let query = "?"
		if( typeof this.currentPage === "number" && !isNaN(this.currentPage) && this.currentPage !== Infinity && this.currentPage !== -Infinity && this.currentPage > 0 )
			query += `&page=${this.currentPage}`
		if( typeof this.currentGroupId === "number" && !isNaN(this.currentGroupId) && this.currentGroupId !== Infinity && this.currentGroupId !== -Infinity && this.currentGroupId > 0 )
			query += `&group=${this.currentGroupId}`

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${this.$route.params.researchId}/document/${this.$route.params.documentId}/questionnaire${query}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				this.unwatchCustomWatchers()
				this.form = await response.json()

				this.form.research.groupMap = []
				for( let index = 0; index < this.form.research.groups.length; ++index )
					this.form.research.groupMap.push([ index, this.form.research.groups[ index ].id ])
				this.currentGroupIndex = (this.form.research.groupMap.find( element => element[ 1 ] === this.form.groupId ) as [number, number])[ 0 ]
				this.currentGroupId = this.form.research.groups[ this.currentGroupIndex ].id

				this.form.research.questionMap = {}
				for( let index = 0; index < this.form.research.questions.length; ++index )
				{
					if( [this.questionType.Date, this.questionType.DateTime].includes( this.form.research.questions[ index ].type ) && typeof this.form.answers[ this.form.research.questions[ index ].id ] === "string" && (this.form.answers[ this.form.research.questions[ index ].id ] as unknown[]).length > 9 )
					{
						let importFormat
						let exportFormat
						if( this.form.research.questions[ index ].type === this.questionType.Date )
						{
							importFormat = "YYYY-MM-DD"
							exportFormat = this.form.research.questions[ index ].configuration.dateType === 1 ? "jYYYY-jMM-jDD [(J)]" : "YYYY-MM-DD [(G)]"
						}
						else
						{
							importFormat = "YYYY-MM-DD[T]HH:mm:ss"
							exportFormat = this.form.research.questions[ index ].configuration.dateType === 1 ? "jYYYY-jMM-jDD HH:mm:ss [(J)]" : "YYYY-MM-DD HH:mm:ss [(G)]"
						}

						this.form.answers[ this.form.research.questions[ index ].id ] = jMoment(
							this.form.answers[ this.form.research.questions[ index ].id ] as Date,
							importFormat
						).format( exportFormat )
					}

					this.form.research.questions[ index ].conditionFlag = true
					this.form.research.questions[ index ].relatedConditions = []

					this.form.research.questionMap[ this.form.research.questions[ index ].id ] = index
				}

				for( let index_1 = 0; index_1 < this.form.research.questions.length; ++index_1 )
					for( let index_2 = 0; index_2 < this.form.research.questions[ index_1 ].conditions.length; ++index_2 )
					{
						this.form.research.questions[ index_1 ].conditions[ index_2 ].checkFlag = true

						this.form.research.questions[
							this.form.research.questionMap[
								this.form.research.questions[ index_1 ].conditions[ index_2 ].conditionRelatedQuestionId
								]
							].relatedConditions.push(
							{
								conditionalQuestionIndex: index_1,
								conditionIndex: index_2
							}
						)
					}

				for( let index = 0; index < this.form.research.questions.length; ++index )
					this.customWatchersUnwatches.push(
						this.$watch(
							`form.answers.${ this.form.research.questions[ index ].id }`,
							(newAnswer, oldAnswer) => this.checkQuestionRelatedCondition( this.form.research.questions[ index ], newAnswer ),
							{ "immediate": true }
						)
					)

				this.pagesCount = Math.ceil( this.form.research.groups[ this.currentGroupIndex ].questionsCount / this.form.research.questionsPerPage )

				if( this.pagesCount < this.currentPage )
					this.currentPage = 1

				setTimeout(this.focusOnForm, 50);
			}
			else if( response.status === 404 )
				this.$notifications.warning( "هیچ پرسشنامه‌ای یافت نشد" )
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری پرسشنامه رخ داد" )
		}

		this.loading = false
	}

	private async sendData(): Promise<boolean>
	{
		this.loading = true

		for( const questionId in this.form.answers )
		{
			if( this.form.research.questions[ this.form.research.questionMap[ questionId ] ].conditionFlag )
				continue

			delete this.form.research.questions[ this.form.research.questionMap[ questionId ] ].validation[ "required" ]

			if( this.form.research.questions[ this.form.research.questionMap[ questionId ] ].type === this.questionType.SelectionMultipleAnswer )
			{
				this.form.answers[ questionId ] = []
				continue
			}
			this.form.answers[ questionId ] = null
		}

		if( Object.values( this.form.answers ).length < 1 )
		{
			this.loading = false

			return true
		}

		if( !this.validateAnswers() )
		{
			this.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${this.$route.params.researchId}/document/${this.$route.params.documentId}/questionnaire`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( this.form.answers )
				}
			)

			if( response.ok )
			{
				this.loading = false

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch( error )
		{
			console.error( error )

			this.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام تکمیل پرسشنامه رخ داد" )

			return false
		}
	}

	private async goToPage( event: Event ): Promise<void>
	{
		const inputBox = event.target as HTMLInputElement
		const newPageNumber = Number( inputBox.value )

		if( newPageNumber > 0 && newPageNumber <= this.pagesCount && newPageNumber !== this.currentPage )
			return await this.sendData().then( result => { if( result ) this.currentPage = newPageNumber } )

		inputBox.value = ""
	}

	@Watch("currentGroupId", { deep: false, immediate: false })
	private onCurrentGroupChanged( newGroupId: number, oldGroupId: number ): void
	{
		if( Number( this.$route.query.group ) === newGroupId && Number( this.$route.query.page ) === 1 )
			return

		this.$router.replace({ query: { group: newGroupId.toString(), page: "1" } })
	}

	@Watch("currentPage", { deep: false, immediate: true })
	private onCurrentPageChanged( newPage: number, oldPage: number ): void
	{
		if( ["number", "string"].includes( typeof this.$route.query.page ) && newPage > 0 )
		{
			if( this.form.research?.groups instanceof Array && newPage > this.pagesCount )
			{
				if( this.currentGroupIndex < this.form.research.groups.length - 1 )
				{
					this.$router.replace({ query: { group: this.form.research.groups[ this.currentGroupIndex + 1 ].id.toString(), page: "1" } })
					return
				}

				this.$router.push({ name: "Dashboard_Documents", params: { researchId: this.$route.params.researchId } })
				return
			}

			if( Number( this.$route.query.page ) === newPage )
				return

			this.$router.replace({ query: { ...this.$route.query, page: newPage.toString() } })
			return
		}

		this.$router.replace({ query: { ...this.$route.query, page: "1" } })
		return
	}

	created(): void
	{
		this.fetchData()
	}
}

export default DocumentQuestionnaire
