import { default as VueRouter } from 'vue-router'
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue'
import { UserRole as UserRoleEnum } from "./enums"
import * as EventHandlers from './routerEventHandlers'


/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   let res= require('../components/Dashboard/Views/' + name + '.vue')
   return res
}**/

// configure router
export const Router = new VueRouter(
	{
		mode: 'history',
		routes: [
			{
				name: 'Root',
				path: '/',
				meta: {
					title: 'صفحه اصلی'
				},
				redirect: typeof EventHandlers.Root.redirect === "function" ? EventHandlers.Root.redirect.bind( this ) : EventHandlers.Root.redirect
			},
			{
				name: 'ConnectToServer',
				path: '/connect-to-server',
				meta: {
					title: 'اتصال به سرور',
					icon: 'fa-print-search'
				},
				component: _ => import(/* webpackChunkName: "common" */ '@/pages/Researches.vue')
			},
			{
				name: 'SetServerConnection',
				path: '/set-server-connection',
				meta: {
					title: 'تنظیم اتصال سرور',
					icon: 'fa-print-search'
				},
				component: _ => import(/* webpackChunkName: "common" */ '@/pages/SetServerConnection.vue')
			},
			{
				name: 'Login',
				path: '/login',
				meta: {
					title: 'ورود',
					icon: 'fa-print-search'
				},
				component: _ => import(/* webpackChunkName: "common" */ '@/pages/Login.vue')
			},
			{
				name: 'Dashboard',
				path: '/dashboard',
				meta: {
					title: 'داشبورد'
				},
				component: DashboardLayout,
				redirect: typeof EventHandlers.Root.redirect === "function" ? EventHandlers.Root.redirect.bind( this ) : EventHandlers.Root.redirect,
				children: [
					{
						name: 'Dashboard_Researches',
						path: 'researches',
						meta: {
							title: 'طرح‌های پژوهشی',
							icon: 'fa-print-search',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Researches.vue')
					},
					{
						name: 'Dashboard_Researches_Groups',
						path: 'researches/:researchId/groups',
						meta: {
							title: 'گروه‌های طرح',
							icon: 'fa-layer-group',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardResearchesGroups.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Groups.vue'),
					},
					{
						name: 'Dashboard_Researches_Questions',
						path: 'researches/:researchId/groups/:groupId/questions',
						meta: {
							title: 'سوالات طرح',
							icon: 'fa-question-circle',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardResearchesQuestions.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Questions.vue'),
					},
					{
						name: 'Dashboard_Researches_Question_Choices',
						path: 'researches/:researchId/groups/:groupId/questions/:questionId/choice',
						meta: {
							title: 'گزینه‌های سوال',
							icon: 'fa-question-circle',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardResearchesQuestionChoices.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Choices.vue'),
					},
					{
						name: 'Dashboard_Researches_Question_Conditions',
						path: 'researches/:researchId/groups/:groupId/questions/:questionId/conditions',
						meta: {
							title: 'مدیریت شروط',
							icon: 'fa-question-circle',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardResearchesQuestionConditions.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Conditions.vue'),
					},
					{
						name: 'Dashboard_Select_Research',
						path: 'documents',
						meta: {
							title: 'انتخاب طرح پژوهشی',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/SelectResearch.vue')
					},
					{
						name: 'Dashboard_Documents_Search',
						path: 'documents/search',
						meta: {
							title: 'پرونده‌ها',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/SearchDocuments.vue')
					},
					{
						name: 'Dashboard_Documents',
						path: 'documents/research-:researchId',
						meta: {
							title: 'پرونده‌ها',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardDocuments.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Documents.vue')
					},
					{
						name: 'Dashboard_Documents_New',
						path: 'documents/research-:researchId/new',
						meta: {
							title: 'افزودن پرونده‌ها',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardDocumentsNew.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/NewDocument.vue')
					},
					{
						name: 'Dashboard_Documents_Edit',
						path: 'documents/research-:researchId/:documentId/edit',
						meta: {
							title: 'ویرایش پرونده‌ها',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardDocumentsEdit.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/EditDocument.vue')
					},
					{
						name: 'Dashboard_Documents_Questionnaire',
						path: 'documents/research-:researchId/:documentId/questionnaire',
						meta: {
							title: 'تکمیل پرسشنامه',
							icon: 'fa-file-contract',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin,
								UserRoleEnum.Agent,
								UserRoleEnum.Operator
							]
						},
						beforeEnter: EventHandlers.DashboardDocumentsQuestionnaire.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/DocumentQuestionnaire.vue')
					},
					{
						name: 'Dashboard_Users',
						path: 'users',
						meta: {
							title: 'کاربران',
							icon: 'fa-users',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin
							]
						},
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Users.vue')
					},
					{
						name: 'Dashboard_User_Permissions',
						path: 'users/:userMobile/permissions',
						meta: {
							title: 'مدیریت دسترسی‌های کاربر',
							icon: 'fa-user-unlock',
							accessControlList: [
								UserRoleEnum.SuperAdmin,
								UserRoleEnum.Admin
							]
						},
						beforeEnter: EventHandlers.DashboardUserPermissions.beforeEnter.bind( this ),
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/UserPermissions.vue')
					},
					{
						name: 'Dashboard_Profile',
						path: 'profile',
						meta: {
							title: 'حساب کاربری',
							icon: 'fa-id-card'
						},
						component: _ => import(/* webpackChunkName: "common" */ '@/pages/Profile.vue')
					},
					{
						name: 'Dashboard_Logout',
						path: 'logout',
						meta: {
							title: 'خروج از حساب کاربری',
							icon: 'fa-sign-out'
						},
						beforeEnter: EventHandlers.DashboardLogout.beforeEnter.bind( this )
					},
					{
						name: 'Dashboard_Disconnect',
						path: 'disconnect',
						meta: {
							title: 'قطع اتصال با سرور',
							icon: 'fa-plug'
						},
						beforeEnter: EventHandlers.DashboardDisconnect.beforeEnter.bind( this )
					}
				]
			},
			{
				name: 'NotFound',
				path: '/not-found',
				meta: {
					title: 'صفحه یافت نشد'
				},
				beforeEnter: EventHandlers.NotFound.beforeEnter.bind( this ),
				component: _ => import(/* webpackChunkName: "common" */ '@/pages/NotFound.vue')
			},
			{
				name: 'FallBackRoute',
				path: '*',
				meta: {
					title: 'مسیر تعریف نشده'
				},
				beforeEnter: EventHandlers.FallBackRoute.beforeEnter.bind( this )
			}
		],
		linkExactActiveClass: 'active',
		scrollBehavior: to => to.hash ? { selector: to.hash } : { x: 0, y: 0 }
	}
)

/*
if( EventHandlers.Global.beforeEach )
	Router.beforeEach( EventHandlers.Global.beforeEach( Router ) )
if( EventHandlers.Global.beforeResolve )
	Router.beforeResolve( EventHandlers.Global.beforeResolve( Router ) )
if( EventHandlers.Global.afterEach )
	Router.afterEach( EventHandlers.Global.afterEach( Router ) )
if( EventHandlers.Global.onReady )
	Router.onReady( EventHandlers.Global.onReady( Router ) )
if( EventHandlers.Global.onError )
	Router.onError( EventHandlers.Global.onError( Router ))
*/

Router.beforeEach( (EventHandlers.Global.beforeEach as ( ...args: any[] ) => any)( Router ) )

export default Router
