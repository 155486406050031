










































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { BaseTable, Paginator, Modal } from "../components"
import { ResearchMixin, DocumentMixin } from "../mixins"
import { ResearchPermissionEnum } from "../mixins/UserPermissionMixin"
import type { DocumentRepositoryType } from "../mixins/DocumentMixin"

@Component({ components: { BaseTable, Modal, Paginator } })
export default class SearchDocuments extends Mixins( ResearchMixin, DocumentMixin )
{
	ResearchPermissionEnum = ResearchPermissionEnum
	maxRecordsPerPage = 7
	currentPage = 1
	searchKeyword = ""

	get pagesCount(): number
	{
		return Math.ceil( this.documentModule.repository.count / this.maxRecordsPerPage )
	}

	getUserNameFromDocument( document: DocumentRepositoryType ): string
	{
		return typeof document[ "userFirstName" ] === "string" && typeof document[ "userLastName" ] === "string" ? `${document[ "userFirstName" ]} ${document[ "userLastName" ]}` : ""
	}

	getResearchById( researchId: number )
	{
		return this.researchModule.repository.data[ this.researchModule.repository.idMap[ researchId ] ]
	}

	loadDocuments(): void
	{
		this.researchesFetch({  }).then(
			_ => this.documentsFetch(
				{
					searchKeyword: this.searchKeyword,
					grouping: {
						limit: this.maxRecordsPerPage,
						offset: this.currentPage
					}
				}
			).catch( error => console.error( error ) ),
			error => console.error( error )
		)
	}

	searchByKeyword( event: InputEvent ): void
	{
		this.searchKeyword = (<HTMLInputElement>event.target).value
		if( this.currentPage === 1 )
		{
			this.loadDocuments()
			return
		}

		this.currentPage = 1
	}

	@Watch( "currentPage", { deep: false, immediate: true } )
	onPageChanged( newValue: number, oldValue: number ): void
	{
		this.loadDocuments()
	}
}
