const numericCharacter = {
	// 1. Persian HTML decimal
	latinNumbers: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	// 2. Arabic Numeric
	arabicNumbers: ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"],
	// 3. Persian Numeric
	persianNumbers: ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"],
	// 4. Arabic HTML decimal
	arabicDecimals: ["&#1632;", "&#1633;", "&#1634;", "&#1635;", "&#1636;", "&#1637;", "&#1638;", "&#1639;", "&#1640;", "&#1641;"],
	// 5. Persian HTML decimal
	persianDecimals: ["&#1776;", "&#1777;", "&#1778;", "&#1779;", "&#1780;", "&#1781;", "&#1782;", "&#1783;", "&#1784;", "&#1785;"]
}

export const isString = (value: unknown): boolean => typeof value === "string"
export const isNotNullString = (value: unknown): boolean => (typeof value === "string" && value.length > 0)
export const isStringNull = (value: unknown): boolean => !(typeof value === "string" && value.length > 0)
export const isNumber = (value: unknown): boolean => typeof value === "number"
export const isArray = (value: unknown): boolean => (typeof value === "object" && value instanceof Array)
export const isNotEmptyArray = (value: unknown): boolean => (typeof value === "object" && value instanceof Array && value.length > 0)
export const isEmptyArray = (value: unknown): boolean => !(typeof value === "object" && value instanceof Array && value.length > 0)
export const nullishCoalescing = (value: unknown, defaultValue: unknown): unknown => value ?? defaultValue

export const array_replace = ( baseArray: unknown[], findArray: unknown[], replaceArray: unknown[] ): unknown[] => {
	for( let findIndex = 0; findIndex < findArray.length; ++findIndex )
		// eslint-disable-next-line no-constant-condition
		while( true )
		{
			const baseIndex = baseArray.indexOf(
				findArray[ findIndex ]
			)

			if( baseIndex === -1 )
				break

			baseArray[ baseIndex ] = replaceArray[ findIndex ]
		}

	return baseArray
}

export const replaceInString = ( baseString: string, findArray: string[], replaceArray: string[] ): string => {
	if( baseString === undefined || baseString === null || !(typeof baseString === "string") || baseString === "" )
		return ""

	return array_replace( baseString.split(""), findArray, replaceArray ).join("")
}

export const convert2PersianNumber = ( inputNumber: string ): string => {
	inputNumber = replaceInString( inputNumber, numericCharacter.persianDecimals, numericCharacter.persianNumbers )
	inputNumber = replaceInString( inputNumber, numericCharacter.arabicDecimals, numericCharacter.persianNumbers )
	inputNumber = replaceInString( inputNumber, numericCharacter.arabicNumbers, numericCharacter.persianNumbers )

	return replaceInString( inputNumber, numericCharacter.latinNumbers, numericCharacter.persianNumbers )
}

export const convert2LatinNumber = ( inputNumber: string ): string => {
	inputNumber = replaceInString( inputNumber, numericCharacter.persianDecimals, numericCharacter.latinNumbers )
	inputNumber = replaceInString( inputNumber, numericCharacter.arabicDecimals, numericCharacter.latinNumbers )
	inputNumber = replaceInString( inputNumber, numericCharacter.arabicNumbers, numericCharacter.latinNumbers )

	return replaceInString( inputNumber, numericCharacter.persianNumbers, numericCharacter.latinNumbers )
}

export const randomString = ( length: number ): string => {
	const charset = "=4_Q$c+}8p&.1!JRI'3(m6lW\"<LryhD]`MACE>oG)~-#s5Kq0d%Tbv\\YX{ZF@j,e7ui[2Bw9ztNHU|^?xaSfn/:*;PVkgO", generatedString: string[] = []

	for( let i = 0, n = charset.length; i < length; ++i )
		generatedString.push(
			charset.charAt(
				Math.floor(
					Math.random() * n
				)
			)
		)

	return generatedString.join("")
}

export const calculateRowIndex = ( recordsPerPage: number, pageNumber: number, indexInCurrentPage: number ): number => ((recordsPerPage * (pageNumber - 1)) + indexInCurrentPage)
