import { PluginObject } from "vue"
import { NotificationSettingType, NotificationType, NotificationStoreType, NotificationPluginMethodsType } from "./Types"
import Notifications from './Notifications.vue'

declare module 'vue/types/vue' {
	interface Vue {
		$notifications: NotificationPluginMethodsType
	}
}

export const NotificationPlugin: PluginObject<NotificationSettingType>  = {
	install( Vue, options ) {
		const app = new Vue(
			{
				data(): { notificationStore: NotificationStoreType } {
					return {
						notificationStore: {
							state: [], // here the notifications will be added
							settings: {
								overlap: false,
								verticalAlign: 'top',
								horizontalAlign: 'right',
								type: 'info',
								timeout: 5000,
								closeOnClick: true,
								showClose: true
							}
						}
					}
				},
				methods: {
					getState(): NotificationType[] {
						return this.notificationStore.state
					},
					getSettings(): NotificationSettingType {
						return this.notificationStore.settings
					},
					setSettings( options: NotificationSettingType ): void {
						this.notificationStore.settings = Object.assign( this.notificationStore.settings, options )
					},
					removeNotification( timestamp: Date ): void {
						const indexToDelete = this.notificationStore.state.findIndex(
							(n: any) => n.timestamp === timestamp
						)
						if (indexToDelete !== -1) {
							this.notificationStore.state.splice(indexToDelete, 1)
						}
					},
					addNotification( notification: NotificationType | string ): void {
						if( typeof notification === 'string' )
							notification = { message: notification }

						notification.timestamp = new Date()
						notification.timestamp.setMilliseconds(
							notification.timestamp.getMilliseconds() + this.notificationStore.state.length
						)

						notification = Object.assign({}, this.notificationStore.settings, notification)

						this.notificationStore.state.push(notification)
					},
					notify( notification: NotificationType | NotificationType[] | string | string[] ): void {
						if ( Array.isArray(notification) )
							notification.forEach(
								notificationInstance => {
									this.addNotification(notificationInstance)
								}
							)
						else
							this.addNotification(notification)
					},
					error( message: string ): void {
						this.addNotification(
							{
								message,
								icon: 'tim-icons icon-alert-circle-exc',
								horizontalAlign: 'left',
								verticalAlign: 'bottom',
								type: 'danger',
								timeout: 9000
							}
						)
					},
					warning( message: string ): void {
						this.addNotification(
							{
								message,
								icon: 'tim-icons icon-alert-circle-exc',
								horizontalAlign: 'left',
								verticalAlign: 'bottom',
								type: 'warning',
								timeout: 9000
							}
						)
					},
					successful( message: string ): void {
						this.addNotification(
							{
								message,
								icon: 'tim-icons icon-check-2',
								horizontalAlign: 'left',
								verticalAlign: 'bottom',
								type: 'success',
								timeout: 9000
							}
						)
					},
					primary( message: string ): void {
						this.addNotification(
							{
								message,
								icon: 'tim-icons icon-email-85',
								horizontalAlign: 'left',
								verticalAlign: 'bottom',
								type: 'primary',
								timeout: 9000
							}
						)
					},
					info( message: string ): void {
						this.addNotification(
							{
								message,
								icon: 'tim-icons icon-bulb-63',
								horizontalAlign: 'left',
								verticalAlign: 'bottom',
								type: 'info',
								timeout: 9000
							}
						)
					}
				}
			}
		)

		Vue.prototype.$notifications = {
			getState: app.getState,
			getSettings: app.getSettings,
			setSettings: app.setSettings,
			removeNotification: app.removeNotification,
			addNotification: app.addNotification,
			notify: app.notify,
			error: app.error,
			warning: app.warning,
			successful: app.successful,
			primary: app.primary,
			info:app.info,
		}
		Vue.component('Notifications', Notifications)

		if( options )
			app.setSettings( options )
	}
}

export default NotificationPlugin
