import { Vue, Component } from "vue-property-decorator"
import { default as jMoment } from "moment-jalaali"
import { FetchOptionsType, GroupModuleType, GroupRepositoryType, GroupType, GroupUpdatePayloadType } from "./Types"

@Component
export class GroupMixin extends Vue {
	protected readonly groupModule: GroupModuleType = {
		"alertModal": {
			"show": false,
			"title": "",
			"body": ""
		},
		"repository": {
			"loading": true,
			"count": 0,
			"data": [],
			"idMap": {}
		},
		"new": {
			"showModal": false,
			"loading": false,
			"data": {
				"name": "",
				"priority": 32766
			}
		}
	}

	protected static researchIdValidator( id?: unknown ): boolean
	{
		return typeof id === "number" && id > 0
	}

	protected static groupNameValidator( name?: unknown ): boolean
	{
		return typeof name === "string" && name.length > 1 && name.length < 256
	}

	protected static groupPriorityValidator( priority?: unknown ): boolean
	{
		return priority === "" || ( (typeof priority === "number" && !isNaN(priority) && priority !== Infinity) && (priority > -32767 && priority < +32767) )
	}

	protected async groupsFetch( researchId: number, fetchOptions?: null | FetchOptionsType ): Promise<void>
	{
		this.groupModule.repository.loading = true

		try
		{
			let withDeleted = false
			let groupingQuery = ""
			let searchKeyword: string | null = null
			let searchConditions = {}
			if( fetchOptions && typeof fetchOptions === "object" )
			{
				if( fetchOptions[ "withDeleted" ] && typeof fetchOptions[ "withDeleted" ] === "boolean" )
					withDeleted = fetchOptions[ "withDeleted" ]

				if( fetchOptions[ "grouping" ] && typeof fetchOptions[ "grouping" ] === "object" && typeof fetchOptions[ "grouping" ].offset === "number" && typeof fetchOptions[ "grouping" ].limit === "number" )
					groupingQuery = `${fetchOptions[ "grouping" ].limit}@${fetchOptions[ "grouping" ].offset}`

				if ( typeof fetchOptions[ "searchKeyword" ] === "string" )
					searchKeyword = fetchOptions[ "searchKeyword" ]

				if( fetchOptions[ "searchConditions" ] && typeof fetchOptions[ "searchConditions" ] === "object" )
					searchConditions = fetchOptions[ "searchConditions" ]
			}

			const response = await fetch(
					searchKeyword ? `${this.$authentication.serverAddress}/research/${researchId}/group/search/${searchKeyword}?with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}` : `${this.$authentication.serverAddress}/research/${researchId}/group?with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					}
				}
			)

			if( response.ok )
			{
				const responseBody: { count: number, data: GroupType[] } = await response.json()

				this.groupModule.new.data.priority = (responseBody.count + 1) * 10;
				this.groupModule.repository.count = responseBody.count
				this.groupModule.repository.data = []
				this.groupModule.repository.idMap = {}
				responseBody.data.forEach(
					group => {
						this.groupModule.repository.idMap[ group.id ] = this.groupModule.repository.data.push(
							{
								...group,
								"createAt": jMoment.utc( group.createAt ).local(),
								"updateAt": typeof group.updateAt as string | null === "string" ? jMoment.utc( group.updateAt ).local() : null,

								"toggleRepository": {
									"loading": false
								},

								"updateRepository": {
									"showModal": false,
									"loading": false,
									"data": {
										"name": group.name,
										"priority": group.priority
									}
								},

								"copyRepository": {
									"showModal": false,
									"loading": false,
									"data": {
										"destinationResearchId": null,
										"name": `رونوشت از ${group.name}`,
										"priority": group.priority
									}
								},

								"removeRepository": {
									"showConfirmModal": false,
									"loading": false
								}
							}
						) - 1
					}
				)
			}
			else if( response.status === 404 )
			{
				if( typeof searchKeyword !== "string" )
					this.$notifications.warning( "هیچ گروهی یافت نشد" )

				this.groupModule.new.data.priority = 10
				this.groupModule.repository.count = 0
				this.groupModule.repository.data = []
				this.groupModule.repository.idMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری گروه‌ها رخ داد" )
		}

		this.groupModule.repository.loading = false
	}

	protected async groupFetch( researchId: GroupType[ "researchId" ], groupId: GroupType[ "id" ] ): Promise<GroupRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const responseBody: GroupType = await response.json()

				return {
					...responseBody,
					"createAt": jMoment.utc( responseBody.createAt ).local(),
					"updateAt": typeof responseBody.updateAt as string | null === "string" ? jMoment.utc( responseBody.updateAt ).local() : null,

					"toggleRepository": {
						"loading": false
					},

					"updateRepository": {
						"showModal": false,
						"loading": false,
						"data": {
							"name": responseBody.name,
							"priority": responseBody.priority
						}
					},

					"copyRepository": {
						"showModal": false,
						"loading": false,
						"data": {
							"destinationResearchId": null,
							"name": `رونوشت از ${responseBody.name}`,
							"priority": responseBody.priority
						}
					},

					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					}
				}
			}
			else if ( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( exception )
		{
			if( exception === 404 )
			{
				this.$notifications.warning( "گروه یافت نشد" )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری اطلاعات گروه رخ داد" )

			throw exception
		}
	}

	protected async groupAdd( researchId: GroupType[ "researchId" ] ): Promise<boolean>
	{
		this.groupModule.new.loading = true

		if( !GroupMixin.groupNameValidator( this.groupModule.new.data.name ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نام گروه نامعتبر می‌باشد" )

			this.groupModule.new.loading = false
			return false
		}

		if( !GroupMixin.groupPriorityValidator( this.groupModule.new.data.priority ) )
		{
			this.$notifications.error( "مقدار وارد شده برای الویت نمایش گروه نامعتبر می‌باشد<br\\>(مقدار باید از نوع عدد صحیح و بزرگتر از ۳۲۷۶۷− و کوچکتر از ۳۲۷۶۷+ باشد)" )

			this.groupModule.new.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/group`,
				{
					method: "PUT",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify(
						{
							"name": this.groupModule.new.data.name,
							"priority": this.groupModule.new.data.priority as unknown as string === "" ? 32766 : this.groupModule.new.data.priority
						}
					)
				}
			)

			if( response.ok )
			{
				(async () => {
					this.groupModule.new.data = {
						"name": "",
						"priority": 32766
					}

					this.$notifications.successful( "گروه با موفقیت اضافه شد" )

					this.groupModule.new.showModal = false
					this.groupModule.new.loading = false
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.groupModule.new.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام افزودن گروه رخ داد" )

			return false
		}
	}

	protected async groupEdit( group: GroupRepositoryType ): Promise<boolean>
	{
		group.updateRepository.loading = true

		const payload: GroupUpdatePayloadType = {}

		if( !GroupMixin.groupNameValidator( group.updateRepository.data.name ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نام گروه نامعتبر می‌باشد" )

			group.updateRepository.loading = false
			return false
		}
		if( group.updateRepository.data.name !== group.name )
			payload.name = group.updateRepository.data.name

		if( !GroupMixin.groupPriorityValidator( group.updateRepository.data.priority ) )
		{
			this.$notifications.error( "مقدار وارد شده برای الویت نمایش گروه نامعتبر می‌باشد<br\\>(مقدار باید از نوع عدد صحیح و بزرگتر از ۳۲۷۶۷− و کوچکتر از ۳۲۷۶۷+ باشد)" )

			group.updateRepository.loading = false
			return false
		}
		if( group.updateRepository.data.priority !== group.priority )
			payload.priority = group.updateRepository.data.priority as unknown as string === "" ? 32766 : group.updateRepository.data.priority

		if( Object.keys( payload ).length === 0 )
		{
			group.updateRepository.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${group.researchId}/group/${group.id}`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				(async () => {
					group.updateRepository.showModal = false
					group.updateRepository.loading = false

					this.$notifications.successful( "گروه با موفقیت ویرایش شد" )
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			group.updateRepository.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام ویرایش گروه رخ داد" )

			return false
		}
	}

	protected async groupCopy( group: GroupRepositoryType ): Promise<boolean>
	{
		group.copyRepository.loading = true

		if( !GroupMixin.researchIdValidator( group.copyRepository.data.destinationResearchId ) )
		{
			this.$notifications.error( "مقدار انتخاب شده برای طرح مقصد رونوشت نامعتبر می‌باشد" )

			group.copyRepository.loading = false
			return false
		}

		if( !GroupMixin.groupNameValidator( group.copyRepository.data.name ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نام گروه نامعتبر می‌باشد" )

			group.copyRepository.loading = false
			return false
		}

		if( !GroupMixin.groupPriorityValidator( group.copyRepository.data.priority ) )
		{
			this.$notifications.error( "مقدار وارد شده برای الویت نمایش گروه نامعتبر می‌باشد<br\\>(مقدار باید از نوع عدد صحیح و بزرگتر از ۳۲۷۶۷− و کوچکتر از ۳۲۷۶۷+ باشد)" )

			group.copyRepository.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${group.researchId}/group/${group.id}/copy-to/${group.copyRepository.data.destinationResearchId}`,
				{
					method: "POST",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify(
						{
							"name": group.copyRepository.data.name,
							"priority": group.copyRepository.data.priority as unknown as string === "" ? 32766 : group.copyRepository.data.priority
						}
					)
				}
			)

			if( response.ok )
			{
				(async () => {
					group.copyRepository.data = {
						"destinationResearchId": null,
						"name": `رونوشت از ${group.name}`,
						"priority": group.priority
					}

					this.$notifications.successful( "رونوشت از گروه با موفقیت انجام شد" )

					group.copyRepository.showModal = false
					group.copyRepository.loading = false
				})()

				return true
			}
			else if( response.status === 409 )
			{
				const errorCode = ( await response.json() )?.errorCode

				if( typeof errorCode === "string" )
					switch( errorCode )
					{
						case "ConflictGroupName":
							group.copyRepository.loading = false
							this.$notifications.error( "عملیات ناموفق بود!<br/>در طرح انتخاب شده یک گروه با نام مشابه یافت شد" )
							return false

						case "ConflictQuestionCode":
							group.copyRepository.loading = false
							this.$notifications.error( "عملیات ناموفق بود!<br/>در طرح انتخاب شده یک یا چند سوال با کد مشابه یافت شد" )
							return false

					}
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			group.copyRepository.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام رونوشت از گروه رخ داد" )

			return false
		}
	}

	protected async groupRemove( researchId: GroupType[ "researchId" ], groupId: GroupType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}?hard-delete=true`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "گروه با موفقیت حذف شد" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام حدف گروه رخ داد" )
		}
	}

	protected async groupDisable( researchId: GroupType[ "researchId" ], groupId: GroupType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}?hard-delete=false`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "گروه با موفقیت غیرفعال شد" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام غیرفعال کردن گروه رخ داد" )
		}
	}

	protected async groupEnable( researchId: GroupType[ "researchId" ], groupId: GroupType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/restore`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "گروه با موفقیت فعال شد" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام فعال کردن گروه رخ داد" )
		}
	}
}

export default GroupMixin
