































import { Vue, Component, Prop } from "vue-property-decorator"
import type { Route } from "vue-router"

@Component({ name: "SideBar" })
export class SideBar extends Vue
{
	@Prop({ type: String, default: "Creative Tim" })
	readonly title!: string
	@Prop({ type: String, default: "/favicon.png" })
	readonly logoSrc!: string
	@Prop({ type: String, default: "vue" })
	readonly backgroundColor!: string
	@Prop({ type: String, default: "success", validator: (activeColor: unknown) => typeof activeColor === "string" && [ "primary", "info", "success", "warning", "danger" ].includes( activeColor ) })
	readonly activeColor!: string
	@Prop({ type: Array, required: true })
	readonly sidebarRoutes!: Route[]

	get shortTitle(): string
	{
		return this.title.split(' ').map( word => word.charAt(0) ).join('').toUpperCase()
	}

	checkRouteIsActive( route: Route ): boolean
	{
		return RegExp(`^${route.fullPath}.*$`).test( this.$route.fullPath )
	}

	getRouteIcon( route: Route ): string
	{
		return `${ this.checkRouteIsActive( route ) ? "fas" : "far"} ${route.meta?.icon}`
	}
}

export default SideBar
