export enum QuestionConditionActionEnum
{
	"Hidden" = 0,
	"Show" = 1
}

export const QuestionConditionActions = {
	[QuestionConditionActionEnum.Hidden]: {
		name: {
			en: "Hidden",
			fa: "پنهان کردن",
		},
		thumbnail: "/file-manager/static/question_condition_actions/hidden.png"
	},

	[QuestionConditionActionEnum.Show]: {
		name: {
			en: "Show",
			fa: "نمایش",
		},
		thumbnail: "/file-manager/static/question_condition_actions/show.png"
	}
}
