





















import { Vue, Component, Watch } from "vue-property-decorator"
import { UserCredentialsType } from "@smn7231/web-authentication-plugin"

@Component
export default class Login extends Vue {
	private mobile: string = ''
	private mobileValidation: boolean = false
	private password: string = ''
	private passwordValidation: boolean = false
	private showPassword: boolean = false

	@Watch('mobile', { immediate: false, deep: false })
	private onMobileChanged( newMobile: string, oldMobile: string )
	{
		this.mobileValidation = /^09[0-9]{9}$/.test( newMobile )
	}

	@Watch('password', { immediate: false, deep: false })
	private onPasswordChanged( newPassword: string, oldPassword: string )
	{
		this.passwordValidation = newPassword.length > 7 && newPassword.length < 33 && /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test( newPassword )
	}

	private formFieldsOnFocus( event: Event )
	{
		(<HTMLDivElement>(<HTMLInputElement>event.target).parentNode).classList.remove('alert-validate')
	}

	private formFieldsOnBlur( event: Event )
	{
		if( !this.$data[ (<HTMLInputElement>event.target).getAttribute('data-validator') as unknown as number ] )
			(<HTMLDivElement>(<HTMLInputElement>event.target).parentNode).classList.add('alert-validate')
	}

	private async loginFormOnSubmit( event: Event )
	{
		if( !this.mobileValidation )
			return this.$notifications.warning( "لطفاً یک تلفن همراه معتبر وارد کنید" )
		if( !this.passwordValidation )
			return this.$notifications.warning( "لطفاً یک رمز عبور معتبر وارد کنید" )

		try
		{
			await this.$authentication.setUserCredentials( { mobile: this.mobile as UserCredentialsType[ "mobile" ], password: this.password } )
		}
		catch( exception: any )
		{
			console.error( exception )

			if( /^(.*)(statusCode)(['"]?(:)?(.)?)(400)(.*)$/.test(exception?.message as string ?? "") )
			{
				this.$notifications.error( "اطلاعات ارسال شده استانداردهای لازم را ندارد" )

				return
			}
			else if( /^(.*)(statusCode)(['"]?(:)?(.)?)(401)(.*)$/.test(exception?.message as string ?? "") )
			{
				this.$notifications.error( "تلفن همراه یا رمز عبور وارد شده نادرست می‌یاشد" )

				return
			}

			this.$notifications.error( "خطا در فرایند احراز هویت" )
		}
	}
}
