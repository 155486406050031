























































import { Vue, Component, Inject } from "vue-property-decorator"
import { CollapseTransition } from 'vue2-transitions'

@Component({ components: { CollapseTransition } })
export class TopNavBar extends Vue
{
	private showMenu = false

	@Inject()
	readonly isSidebarExtended!: { flag: boolean }

	private capitalizeFirstLetter( string: string ): string
	{
		return string.charAt( 0 ).toUpperCase() + string.slice( 1 )
	}

	private toggleSidebar(): void
	{
		this.isSidebarExtended.flag = !this.isSidebarExtended.flag
	}

	private toggleMenu(): void
	{
		this.showMenu = !this.showMenu
	}

	private async logout(): Promise<void>
	{
		await this.$router.push({ name: 'Dashboard_Logout' })
	}

	private async disconnect(): Promise<void>
	{
		await this.$router.push({ name: 'Dashboard_Disconnect' })
	}
}

export default TopNavBar
