import { Vue, Component } from "vue-property-decorator"
import { default as jMoment } from "moment-jalaali"
import { UserRole as UserRoleEnum } from "../../enums"
import type { ResearchModuleType, FetchOptionsType, ResearchCreatePayloadType, ResearchRepositoryType, ResearchType, ResearchUpdatePayloadType } from "./Types"

@Component
export class ResearchMixin extends Vue
{
	protected readonly researchModule: ResearchModuleType = {
		"repository": {
			"loading": true,
			"count": 0,
			"data": [],
			"idMap": {}
		},
		"new": {
			"showModal": false,
			"loading": false,
			"data": {
				"name": "",
				"description": "",
				"questionsPerPage": 9,
				"showQuestionsTitles": false
			}
		}
	}

	static researchNameValidator( name: unknown ): boolean
	{
		return typeof name === "string" && name.length > 2 && name.length < 256
	}

	static researchDescriptionValidator( description: unknown ): boolean
	{
		return typeof description === "string" && (description === "" || (description.length > 2 && description.length < 2048))
	}

	static researchQuestionsPerPageValidator( questionsPerPage: unknown ): boolean
	{
		return typeof questionsPerPage === "number" && questionsPerPage > 2 && questionsPerPage < 31 && questionsPerPage % 3 === 0
	}

	static researchShowQuestionsTitlesValidator( showQuestionsTitles: unknown ): boolean
	{
		return typeof showQuestionsTitles === "boolean" || showQuestionsTitles instanceof Boolean
	}

	async researchesFetch( fetchOptions: FetchOptionsType ): Promise<void>
	{
		this.researchModule.repository.loading = true

		try
		{
			let groupingQuery = ""
			let searchConditions = {}
			let withDisabled = false
			if( fetchOptions && typeof fetchOptions === "object" )
			{
				if( fetchOptions[ "withDisabled" ] && typeof fetchOptions[ "withDisabled" ] === "boolean" )
					withDisabled = fetchOptions[ "withDisabled" ]

				if( fetchOptions[ "grouping" ] && typeof fetchOptions[ "grouping" ] === "object" && typeof fetchOptions[ "grouping" ].offset === "number" && typeof fetchOptions[ "grouping" ].limit === "number" )
					groupingQuery = `${fetchOptions[ "grouping" ].limit}@${fetchOptions[ "grouping" ].offset}`

				if( fetchOptions[ "searchConditions" ] && typeof fetchOptions[ "searchConditions" ] === "object" )
					searchConditions = fetchOptions[ "searchConditions" ]
			}

			const response = await fetch(
				`${this.$authentication.serverAddress}/research?with-disabled=${withDisabled}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					}
				}
			)

			if( response.ok )
			{
				const responseBody = await response.json()

				this.researchModule.repository.count = responseBody.count
				this.researchModule.repository.data = []
				this.researchModule.repository.idMap = {}
				responseBody.data.forEach(
					research => {
						const length = this.researchModule.repository.data.push(
							{
								...research,
								"haveFullPermission": [ UserRoleEnum.SuperAdmin, UserRoleEnum.Admin ].includes( this.$authentication.userToken?.payloadParsed.role as number ),
								"userPermissions": !([ UserRoleEnum.SuperAdmin, UserRoleEnum.Admin ].includes( this.$authentication.userToken?.payloadParsed.role as number )) && research.userPermissions !== null && typeof research.userPermissions === "object" && research.userPermissions.permissions instanceof Array ? research.userPermissions : { "id": -1, "permissions": [], "createAt": jMoment() },
								"firstDocumentCreateAt": typeof research.firstDocumentCreateAt === "string" ? jMoment.utc( research.firstDocumentCreateAt ).local() : null,
								"lastDocumentCreateAt": typeof research.lastDocumentCreateAt === "string" ? jMoment.utc( research.lastDocumentCreateAt ).local() : null,
								"createAt": jMoment.utc( research.createAt ).local(),
								"updateAt": typeof research.updateAt === "string" ? jMoment.utc( research.updateAt ).local() : null,
								"detailRepository": {
									"showModal": false
								},
								"exportRepository": {
									"loading": false,
								},
								"toggleRepository": {
									"loading": false,
								},
								"updateRepository": {
									"showModal": false,
									"loading": false,
									"data": {
										"name": research.name,
										"description": research.description,
										"questionsPerPage": research.questionsPerPage,
										"showQuestionsTitles": research.showQuestionsTitles
									}
								},
								"removeRepository": {
									"showConfirmModal": false,
									"loading": false
								},
							}
						)

						this.researchModule.repository.idMap[ research.id ] = length - 1
					}
				)
			}
			else if( response.status === 404 )
			{
				this.$notifications.warning( "هیچ طرحی یافت نشد" )

				this.researchModule.repository.count = 0
				this.researchModule.repository.data = []
				this.researchModule.repository.idMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری طرح‌ها رخ داد" )
		}

		this.researchModule.repository.loading = false
	}

	static async researchFetch( this: Vue, researchId: ResearchType[ "id" ] ): Promise<ResearchRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				const research = ( await response.json() )

				return {
					...research,
					"haveFullPermission": [ UserRoleEnum.SuperAdmin, UserRoleEnum.Admin ].includes( this.$authentication.userToken?.payloadParsed.role as number ),
					"userPermissions": !([ UserRoleEnum.SuperAdmin, UserRoleEnum.Admin ].includes( this.$authentication.userToken?.payloadParsed.role as number )) && research.userPermissions !== null && typeof research.userPermissions === "object" && research.userPermissions.permissions instanceof Array ? research.userPermissions : { "id": -1, "permissions": [], "createAt": jMoment() },
					"firstDocumentCreateAt": typeof research.firstDocumentCreateAt === "string" ? jMoment.utc( research.firstDocumentCreateAt ).local() : null,
					"lastDocumentCreateAt": typeof research.lastDocumentCreateAt === "string" ? jMoment.utc( research.lastDocumentCreateAt ).local() : null,
					"createAt": jMoment.utc( research.createAt ).local(),
					"updateAt": typeof research.updateAt === "string" ? jMoment.utc( research.updateAt ).local() : null,
					"detailRepository": {
						"showModal": false
					},
					"exportRepository": {
						"loading": false,
					},
					"toggleRepository": {
						"loading": false,
					},
					"updateRepository": {
						"showModal": false,
						"loading": false,
						"data": {
							"name": research.name,
							"description": research.description,
							"questionsPerPage": research.questionsPerPage,
							"showQuestionsTitles": research.showQuestionsTitles
						}
					},
					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					},
				}
			}
			else if( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			if( error === 404 )
			{
				this.$notifications.warning( "طرح یافت نشد" )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری طرح رخ داد" )

			throw error
		}
	}

	async researchAdd(): Promise<boolean>
	{
		this.researchModule.new.loading = true

		if( !ResearchMixin.researchNameValidator( this.researchModule.new.data.name ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نام طرح نامعتبر می‌باشد" )

			this.researchModule.new.loading = false
			return false
		}
		if( !ResearchMixin.researchDescriptionValidator( this.researchModule.new.data.description ?? "" ) )
		{
			this.$notifications.error( "مقدار وارد شده برای توضیحات طرح نامعتبر می‌باشد" )

			this.researchModule.new.loading = false
			return false
		}
		if( !ResearchMixin.researchQuestionsPerPageValidator( this.researchModule.new.data.questionsPerPage ) )
		{
			this.$notifications.error( "مقدار وارد شده برای تعداد سوال در هر صفحه از طرح نامعتبر می‌باشد" )

			this.researchModule.new.loading = false
			return false
		}
		if( !ResearchMixin.researchShowQuestionsTitlesValidator( this.researchModule.new.data.showQuestionsTitles ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نمایش صورت سوال‌های طرح در پرسشنامه نامعتبر می‌باشد" )

			this.researchModule.new.loading = false
			return false
		}

		const payload: ResearchCreatePayloadType = {
			"name": this.researchModule.new.data.name,
			"questionsPerPage": this.researchModule.new.data.questionsPerPage,
			"showQuestionsTitles": this.researchModule.new.data.showQuestionsTitles
		}

		if( this.researchModule.new.data.description !== undefined && this.researchModule.new.data.description !== null && this.researchModule.new.data.description !== "" )
			payload.description = this.researchModule.new.data.description

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research`,
				{
					method: "PUT",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				(async () => {
					this.researchModule.new.data.name = ""
					this.researchModule.new.data.description = ""
					this.researchModule.new.data.questionsPerPage = 9
					this.researchModule.new.data.showQuestionsTitles = false

					this.$notifications.successful( "طرح با موفقیت اضافه شد" )

					this.researchModule.new.showModal = false
					this.researchModule.new.loading = false
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.researchModule.new.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام افزودن طرح رخ داد" )

			return false
		}
	}

	async researchEdit( research: ResearchRepositoryType ): Promise<boolean>
	{
		research.updateRepository.loading = true

		if( !ResearchMixin.researchNameValidator( research.updateRepository.data.name ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نام طرح نامعتبر می‌باشد" )

			research.updateRepository.loading = false
			return false
		}
		if( !ResearchMixin.researchDescriptionValidator( research.updateRepository.data.description ?? "" ) )
		{
			this.$notifications.error( "مقدار وارد شده برای توضیحات طرح نامعتبر می‌باشد" )

			research.updateRepository.loading = false
			return false
		}
		if( !ResearchMixin.researchQuestionsPerPageValidator( research.updateRepository.data.questionsPerPage ) )
		{
			this.$notifications.error( "مقدار وارد شده برای تعداد سوال در هر صفحه از طرح نامعتبر می‌باشد" )

			research.updateRepository.loading = false
			return false
		}
		if( !ResearchMixin.researchShowQuestionsTitlesValidator( research.updateRepository.data.showQuestionsTitles ) )
		{
			this.$notifications.error( "مقدار وارد شده برای نمایش صورت سوال‌های طرح در پرسشنامه نامعتبر می‌باشد" )

			research.updateRepository.loading = false
			return false
		}

		const payload: ResearchUpdatePayloadType = {}
		if( research.updateRepository.data.name !== research.name )
			payload.name = research.updateRepository.data.name
		if( research.updateRepository.data.description !== research.description )
			payload.description = (research.updateRepository.data.description ?? "") === "" ? null : research.updateRepository.data.description
		if( research.updateRepository.data.questionsPerPage !== research.questionsPerPage )
			payload.questionsPerPage = research.updateRepository.data.questionsPerPage
		if( research.updateRepository.data.showQuestionsTitles !== research.showQuestionsTitles )
			payload.showQuestionsTitles = research.updateRepository.data.showQuestionsTitles

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${research.id}`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				(async () => {
					research.updateRepository.showModal = false
					research.updateRepository.loading = false

					this.$notifications.successful( "طرح با موفقیت ویرایش شد" )
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			research.updateRepository.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام ویرایش طرح رخ داد" )

			return false
		}
	}

	async researchExport( researchId: ResearchType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/document/export/csv`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "text/csv"
					}
				}
			)

			if( response.ok )
			{
				this.$notifications.info( "در حال بارگیری اطلاعات..." )

				const link = document.createElement( "a" )

				// const documentsCount = response.headers.get( "x-documents-count" )

				let fileName = `research_${researchId}-export(${( date => `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` )( new Date )}).csv`
				const contentDispositionHeaderRegex = /^.*filename=(.+\.csv).*$/
				const contentDispositionHeader = response.headers.get("content-disposition")
				if( contentDispositionHeader && contentDispositionHeaderRegex.test( contentDispositionHeader ) )
				{
					const result = contentDispositionHeaderRegex.exec( contentDispositionHeader )
					if( result instanceof Array && result.length > 1 )
						fileName = decodeURI(result[ 1 ])
				}

				link.href = window.URL.createObjectURL( await response.blob() )

				this.$notifications.successful( "اطلاعات با موفقیت دریافت شد. شروع فرایند ذخیره سازی..." )

				link.setAttribute( "download", fileName )
				document.body.appendChild( link )
				link.click()
				link.remove()
			}
			else if( response.status === 404 )
				this.$notifications.warning( "خطای ۴۰۴" )
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام دریافت خروجی از طرح رخ داد" )
		}
	}

	async researchToggle( researchId: ResearchType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/toggle`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "وضعیت طرح با موفقیت تغییر یافت" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام تغییر وضعیت طرح رخ داد" )
		}
	}

	async researchRemove( researchId: ResearchType[ "id" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}?hard-delete=true`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( "طرح با موفقیت حذف گردید" ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام حذف طرح رخ داد" )
		}
	}
}

export default ResearchMixin
