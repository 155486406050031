export enum UserRole {
	"SuperAdmin" = 1,
	"Admin" = 2,
	"Agent" = 3,
	"Operator" = 4
}

export const UserRoles = {
	[UserRole.SuperAdmin]: {
		name: {
			en: "Super Admin",
			fa: "مدیر کل",
		},
		description: {
			en: "Super Admin",
			fa: "مدیر کل",
		},
		thumbnail: "/file-manager/static/user/roles/super_admin.png"
	},

	[UserRole.Admin]: {
		name: {
			en: "Admin",
			fa: "مدیر",
		},
		description: {
			en: "Admin",
			fa: "مدیر",
		},
		thumbnail: "/file-manager/static/user/roles/admin.png"
	},

	[UserRole.Agent]: {
		name: {
			en: "Agent",
			fa: "کارشناس",
		},
		description: {
			en: "Agent",
			fa: "کارشناس",
		},
		thumbnail: "/file-manager/static/user/roles/agent.png"
	},

	[UserRole.Operator]: {
		name: {
			en: "Operator",
			fa: "اپراتور",
		},
		description: {
			en: "Operator",
			fa: "اپراتور",
		},
		thumbnail: "/file-manager/static/user/roles/operator.png"
	}
}
