






















































































































































































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { BaseTable, Paginator, Modal } from '../components'
import { ResearchMixin, ResearchModuleType } from "../mixins/ResearchMixin"
import { UserPermissionMixin, UserPermissionRepositoryType } from "../mixins/UserPermissionMixin"

@Component({ components: { BaseTable, Modal, Paginator } })
export default class UserPermissions extends Mixins( ResearchMixin, UserPermissionMixin )
{
	private researchNameForSearch = ''
	private maxRecordsPerPage = 7
	private currentPage = Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1

	private get pagesCount(): number
	{
		return Math.ceil( this.userPermissionModule.repository.count / this.maxRecordsPerPage )
	}

	private loadUserPermissions(): Promise<void>
	{
		return this.userPermissionsFetch(
			this.$route.params.userMobile,
			{
				researchName: typeof this.researchNameForSearch === 'string' && this.researchNameForSearch.length > 0 ? this.researchNameForSearch : null,
				grouping: {
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			}
		)
	}

	private searchByResearchName( event: Event )
	{
		this.researchNameForSearch = (<HTMLInputElement>event.target).value
		if ( this.currentPage === 1 )
		{
			if( this.researchNameForSearch === "" && Number( this.$route.query ) !== this.currentPage )
				this.$router.replace({ query: { page: String( this.currentPage ) } })

			this.loadUserPermissions()
			return
		}

		this.currentPage = 1
	}

	private async doAndReload<ArgumentsType, ReturnType>( callback: ( ...args: ArgumentsType[] ) => ReturnType, ...args: ArgumentsType[] ): Promise<ReturnType>
	{
		const result = await callback( ...args )

		this.loadUserPermissions()

		return result
	}

	private async userPermissionAddAndReload( userMobile: string ): Promise<boolean>
	{
		return await this.doAndReload( this.userPermissionAdd, userMobile )
	}

	private async userPermissionEditAndReload( userPermission: UserPermissionRepositoryType ): Promise<boolean>
	{
		return await this.doAndReload( this.userPermissionEdit, userPermission )
	}

	private async userPermissionRemoveAndReload( userPermission: UserPermissionRepositoryType ): Promise<void>
	{
		userPermission.removeRepository.loading = true

		try
		{
			await this.doAndReload(
				this.userPermissionRemove as unknown as ( userMobile: string, userPermissionId: string ) => Promise<void>,
				this.$route.params.userMobile,
				userPermission.id as unknown as string
			)
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			userPermission.removeRepository.loading = false
		}
	}

	@Watch('currentPage', { deep: false, immediate: true })
	private onCurrentPageChanged( newCurrentPage: number, oldCurrentPage: number )
	{
		if( (typeof this.researchNameForSearch !== 'string' || this.researchNameForSearch.length < 1) && Number( this.$route.query.page ) !== newCurrentPage )
			this.$router.replace({ query: { page: String( newCurrentPage ) } })

		this.researchesFetch({  }).then(
			() => {
				this.loadUserPermissions().then(
					() => {
						if( this.userPermissionModule.repository.count < 1 && newCurrentPage > 1 )
							this.currentPage = 1
					}
				)
			}
		)
	}
}
