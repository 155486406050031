<template>
	<div class="card question-card">
		<div class="question-card-title">
			<h3>لیست سوالات</h3>
			<base-button class="question-add-button animation-on-hover" type="info" @click="questionModule.new.showModal = true">
				<span>افزودن سوال</span>
				<i class="far fa-plus-circle"></i>
			</base-button>
			<div class="form-group col-4 search-box">
				<input class="form-control" type="text" placeholder="جستجو بر اساس..." @input="searchByKeyword"/>
			</div>
			<modal :show.sync="questionModule.new.showModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm question-add-modal">
				<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
					<template>
						<form @submit.prevent class="col-12">
							<base-input class="col-12" :class="questionCodeValidator( questionModule.new.data.code ) ? 'has-success' : 'has-danger'" label="کد سوال" type="text" placeholder="کد یکتای سوال را وارد کنید" tabindex="1" v-model="questionModule.new.data.code"></base-input>
							<base-input class="col-12" :class="questionTitleValidator($helpers.nullishCoalescing( questionModule.new.data.title, '' )) ? 'has-success' : 'has-danger'" label="صورت سوال" type="text" placeholder="صورت سوال را وارد کنید" tabindex="2" v-model="questionModule.new.data.title"></base-input>
							<base-input class="col-12" label="نوع سوال">
								<select class="form-control" tabindex="3" v-model.number="questionModule.new.data.type">
									<option v-for="questionType in questionModule.questionTypesArray" :value="questionType" :key="questionType">{{ questionModule.questionTypes[ questionType ].name.fa }}</option>
								</select>
							</base-input>
							<base-input class="col-12" :class="questionPriorityValidator( questionModule.new.data.priority ) ? 'has-success' : 'has-danger'" label="الویت نمایش سوال" type="number" placeholder="الویت نمایش سوال را وارد کنید" tabindex="4" v-model.number="questionModule.new.data.priority"></base-input>
							<template v-if="questionModule.new.data.type === questionModule.questionTypeEnum.Integer">
								<base-input class="col-6" :class="questionValidationIntegerValidator( questionModule.new.data.validation.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="questionModule.new.data.validation.min"></base-input>
								<base-input class="col-6" :class="questionValidationIntegerValidator( questionModule.new.data.validation.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="questionModule.new.data.validation.max"></base-input>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="7">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<template v-else-if="questionModule.new.data.type === questionModule.questionTypeEnum.Float">
								<base-input class="col-6" :class="questionValidationFloatValidator( questionModule.new.data.validation.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="questionModule.new.data.validation.min"></base-input>
								<base-input class="col-6" :class="questionValidationFloatValidator( questionModule.new.data.validation.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="questionModule.new.data.validation.max"></base-input>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="7">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<template v-else-if="questionModule.new.data.type === questionModule.questionTypeEnum.String">
								<base-input class="col-6" :class="questionValidationIntegerValidator( questionModule.new.data.validation.minLength, 0, 4095 ) ? 'has-success' : 'has-danger'" label="حداقل طول مجاز" type="number" placeholder="حداقل طول مجاز را وارد کنید" tabindex="5" v-model.number="questionModule.new.data.validation.minLength"></base-input>
								<base-input class="col-6" :class="questionValidationIntegerValidator( questionModule.new.data.validation.maxLength, 1, 4095 ) ? 'has-success' : 'has-danger'" label="حداکثر طول مجاز" type="number" placeholder="حداکثر طول مجاز را وارد کنید" tabindex="6" v-model.number="questionModule.new.data.validation.maxLength"></base-input>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="7">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<template v-else-if="questionModule.new.data.type === questionModule.questionTypeEnum.Date">
								<base-input class="col-12" label="نوع تقویم">
									<select class="form-control" v-model.number="questionModule.new.data.configuration.dateType" tabindex="5">
										<option value="0">گریگوری</option>
										<option value="1">جلالی</option>
									</select>
								</base-input>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="6">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<template v-else-if="questionModule.new.data.type === questionModule.questionTypeEnum.DateTime">
								<base-input class="col-12" label="نوع تقویم">
									<select class="form-control" v-model.number="questionModule.new.data.configuration.dateType" tabindex="5">
										<option value="0">گریگوری</option>
										<option value="1">جلالی</option>
									</select>
								</base-input>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="6">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<template v-else>
								<base-checkbox class="col-12 mb-5" v-model="questionModule.new.data.validation.required" tabindex="5">
									جواب به سوال اجباری است؟
								</base-checkbox>
							</template>
							<base-button class="question-add-modal-submit-button" native-type="submit" type="primary" :loading="questionModule.new.loading" :disabled="questionModule.new.loading" @click="questionAdd( $route.params.researchId, $route.params.groupId )" tabindex="8">
								<template v-if="!questionModule.new.loading">
									ثبت
								</template>
							</base-button>
						</form>
					</template>
				</card>
			</modal>
			<!--
			<router-link class="btn animation-on-hover btn-info question-add-button ml-2" :to="{ name: 'Dashboard_Researches_Question_Choices', params: { researchId: $route.params.researchId } }">
				<span>مدیریت شروط</span>
				<i class="far fa-tasks"></i>
			</router-link>
			-->
		</div>
		<div class="card-body question-card-body">
			<div class="row">
				<div v-if="questionModule.repository.loading" class="question-card-body-loading">
					در حال بارگیری سوالات
					<i class="fas fa-spinner fa-spin"/>
				</div>
				<div v-else-if="questionModule.repository.data.length > 0" class="col-12 table-full-width table-responsive questions-container">
					<base-table class="questions-table" :data="questionModule.repository.data" :columns="['', 'الویت نمایش', 'کد سوال', 'نوع سوال', 'تعداد گزینه‌ها', 'تعداد شروط', 'عملیات']" thead-classes="text-primary">
						<template v-slot="{ index, row }">
							<td>{{ $helpers.convert2PersianNumber($helpers.calculateRowIndex(maxRecordsPerPage, currentPage, index + 1).toString()) }}</td>
							<td>{{ $helpers.convert2PersianNumber(row[ 'priority' ].toString()) }}</td>
							<td>{{ row[ 'code' ] }}</td>
							<td>{{ questionModule.questionTypes[ row[ 'type' ] ].name.fa }}</td>
							<td>{{ row[ 'choicesCount' ] > 0 ? row[ 'choicesCount' ] : '' }}</td>
							<td>{{ row[ 'conditionsCount' ] > 0 ? row[ 'conditionsCount' ] : '' }}</td>
							<td class="td-actions text-right">
								<div class="question-actions">
									<base-button class="animation-on-hover document-action-remove" type="yellow" size="sm ml-2" title="مشاهده جزئیات" icon @click="row[ 'detailRepository' ].showModal = true">
										<i class="far fa-circle-info"></i>
									</base-button>
									<template v-if="row[ 'deleted' ]">
										<base-button class="animation-on-hover" type="success" size="sm ml-2" title="فعال سازی سوال" :loading="row[ 'toggleRepository' ].loading" :disabled="row[ 'toggleRepository' ].loading" icon @click="questionEnable( row[ 'researchId' ], row[ 'researchGroupId' ], row[ 'id' ] )">
											<template v-if="!row[ 'toggleRepository' ].loading">
												<i class="far fa-check-square"></i>
											</template>
										</base-button>
									</template>
									<template v-else>
										<router-link v-if="isQuestionSelectable( row )" class="btn animation-on-hover btn-default btn-sm ml-2 btn-icon btn-fab" title="مدیریت گزینه‌ها" :to="{ name: 'Dashboard_Researches_Question_Choices', params: { researchId: row[ 'researchId' ], questionId: row[ 'id' ] } }">
											<i class="far fa-tasks"></i>
										</router-link>
										<router-link class="btn animation-on-hover btn-primary btn-sm ml-2 btn-icon btn-fab" title="مدیریت شروط" :to="{ name: 'Dashboard_Researches_Question_Conditions', params: { researchId: row[ 'researchId' ], questionId: row[ 'id' ] } }">
											<i class="far fa-eye-slash"></i>
										</router-link>
										<base-button class="animation-on-hover" type="info" size="sm ml-2" title="ویرایش" icon @click="row[ 'updateRepository' ].showModal = true">
											<i class="fad fa-edit"></i>
										</base-button>
										<base-button class="animation-on-hover" type="warning" size="sm ml-2" title="غیرفعال سازی سوال" :loading="row[ 'toggleRepository' ].loading" :disabled="row[ 'toggleRepository' ].loading" icon @click="questionDisable( row[ 'researchId' ], row[ 'researchGroupId' ], row[ 'id' ] )">
											<template v-if="!row[ 'toggleRepository' ].loading">
												<i class="far fa-window-close"></i>
											</template>
										</base-button>
									</template>
									<base-button class="animation-on-hover question-action-remove" type="danger" size="sm ml-2" title="حذف سوال" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" icon @click="row[ 'removeRepository' ].showConfirmModal = true">
										<template v-if="!row[ 'removeRepository' ].loading">
											<i class="far fa-trash-alt"></i>
										</template>
									</base-button>
									<modal :show.sync="row[ 'removeRepository' ].showConfirmModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm confirm-modal">
										<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
											<h3>
												هشدار!
											</h3>
											<p class="confirm-modal-text">
												آیا از حذف این سوال مطمئن هستید؟
												<br/>
												(این عملیات غیر قابل بازگشت می‌باشد.)
											</p>
											<base-button class="confirm-modal-cancel-button" type="info" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" tabindex="1" @click="row[ 'removeRepository' ].showConfirmModal = false">
												<template v-if="!row[ 'removeRepository' ].loading">
													انصراف
												</template>
											</base-button>
											<div class="confirm-modal-buttons-separator"></div>
											<base-button class="confirm-modal-confirm-button" type="danger" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" tabindex="2" @click="questionRemove( row[ 'researchId' ], row[ 'researchGroupId' ], row[ 'id' ] )">
												<template v-if="!row[ 'removeRepository' ].loading">
													تایید
												</template>
											</base-button>
										</card>
									</modal>
								</div>
								<modal :show.sync="row[ 'detailRepository' ].showModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm detail-modal">
									<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
										<h3>
											جزئیات سوال
										</h3>
										<div class="detail-modal-text">
											<small>
												کد:
											</small>
											<b>
												{{ row[ 'code' ] }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="row.title">
											<small>
												صورت:
											</small>
											<b>
												{{ row[ 'title' ] }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												نوع:
											</small>
											<b>
												{{ questionModule.questionTypes[ row[ 'type' ] ].name.fa }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												ایجاد شده توسط:
											</small>
											<b>
												{{ getUserNameFromQuestion( row ) }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												تاریخ ایجاد:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber( row.createAt.format("HH:mm jYYYY/jMM/jDD") ) }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="row.updateAt">
											<small>
												تاریخ بروزرسانی:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber( row.updateAt.format("HH:mm jYYYY/jMM/jDD") ) }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="isQuestionSelectable( row )">
											<small>
												تعداد گزینه‌های ایجاد شده:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber(row[ 'choicesCount' ].toString()) }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												وضعیت:
											</small>
											<b>
												{{ row[ 'deleted' ] ? 'غیرفعال' : 'فعال' }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="row.deletedAt">
											<small>
												تاریخ غیرفعال سازی:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber( row.deletedAt.format("HH:mm jYYYY/jMM/jDD") ) }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												تعداد پاسخ‌های داده‌شده:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber(row[ 'answersCount' ].toString()) }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="row.firstAnswerSubmittedAt">
											<small>
												تاریخ ثبت اولین پاسخ:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber( row.firstAnswerSubmittedAt.format("HH:mm jYYYY/jMM/jDD") ) }}
											</b>
										</div>
										<div class="detail-modal-text" v-if="row.lastAnswerSubmittedAt">
											<small>
												تاریخ ثبت آخرین پاسخ:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber( row.lastAnswerSubmittedAt.format("HH:mm jYYYY/jMM/jDD") ) }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												تعداد شرط‌های این سوال:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber(row[ 'conditionsCount' ].toString()) }}
											</b>
										</div>
										<div class="detail-modal-text">
											<small>
												تعداد شرط‌های وابسطه به این سوال:
											</small>
											<b>
												{{ $helpers.convert2PersianNumber(row[ 'relatedConditionsCount' ].toString()) }}
											</b>
										</div>
										<base-button class="detail-modal-close-button" type="danger" tabindex="1" @click="row[ 'detailRepository' ].showModal = false">
											بستن
										</base-button>
									</card>
								</modal>
								<modal :show.sync="row[ 'updateRepository' ].showModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm question-edit-modal">
									<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
										<template>
											<form @submit.prevent class="col-12">
												<base-input class="col-12" :class="questionCodeValidator( row[ 'updateRepository' ].data.code ) ? 'has-success' : 'has-danger'" label="کد سوال" type="text" placeholder="کد یکتای سوال را وارد کنید" tabindex="1" v-model="row[ 'updateRepository' ].data.code"></base-input>
												<base-input class="col-12" :class="questionTitleValidator($helpers.nullishCoalescing( row[ 'updateRepository' ].data.title, '' )) ? 'has-success' : 'has-danger'" label="صورت سوال" type="text" placeholder="صورت سوال را وارد کنید" tabindex="2" v-model="row[ 'updateRepository' ].data.title"></base-input>
												<base-input class="col-12" label="نوع سوال">
													<select class="form-control" tabindex="3" v-model.number="row[ 'updateRepository' ].data.type">
														<option v-for="questionType in questionModule.questionTypesArray" :value="questionType" :key="questionType">{{ questionModule.questionTypes[ questionType ].name.fa }}</option>
													</select>
												</base-input>
												<base-input class="col-12" :class="questionPriorityValidator( row[ 'updateRepository' ].data.priority ) ? 'has-success' : 'has-danger'" label="الویت نمایش سوال" type="number" placeholder="الویت نمایش سوال را وارد کنید" tabindex="4" v-model.number="row[ 'updateRepository' ].data.priority"></base-input>
												<template v-if="row[ 'updateRepository' ].data.type === questionModule.questionTypeEnum.Integer">
													<base-input class="col-6" :class="questionValidationIntegerValidator( row[ 'updateRepository' ].data.validation.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="row[ 'updateRepository' ].data.validation.min"></base-input>
													<base-input class="col-6" :class="questionValidationIntegerValidator( row[ 'updateRepository' ].data.validation.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="row[ 'updateRepository' ].data.validation.max"></base-input>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="7">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<template v-else-if="row[ 'updateRepository' ].data.type === questionModule.questionTypeEnum.Float">
													<base-input class="col-6" :class="questionValidationFloatValidator( row[ 'updateRepository' ].data.validation.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="row[ 'updateRepository' ].data.validation.min"></base-input>
													<base-input class="col-6" :class="questionValidationFloatValidator( row[ 'updateRepository' ].data.validation.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="row[ 'updateRepository' ].data.validation.max"></base-input>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="7">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<template v-else-if="row[ 'updateRepository' ].data.type === questionModule.questionTypeEnum.String">
													<base-input class="col-6" :class="questionValidationIntegerValidator( row[ 'updateRepository' ].data.validation.minLength, 0, 4095 ) ? 'has-success' : 'has-danger'" label="حداقل طول مجاز" type="number" placeholder="حداقل طول مجاز را وارد کنید" tabindex="5" v-model.number="row[ 'updateRepository' ].data.validation.minLength"></base-input>
													<base-input class="col-6" :class="questionValidationIntegerValidator( row[ 'updateRepository' ].data.validation.maxLength, 1, 4095 ) ? 'has-success' : 'has-danger'" label="حداکثر طول مجاز" type="number" placeholder="حداکثر طول مجاز را وارد کنید" tabindex="6" v-model.number="row[ 'updateRepository' ].data.validation.maxLength"></base-input>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="7">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<template v-else-if="row[ 'updateRepository' ].data.type === questionModule.questionTypeEnum.Date">
													<base-input class="col-12" label="نوع تقویم">
														<select class="form-control" v-model.number="row[ 'updateRepository' ].data.configuration.dateType" tabindex="5">
															<option value="0">گریگوری</option>
															<option value="1">جلالی</option>
														</select>
													</base-input>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="6">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<template v-else-if="row[ 'updateRepository' ].data.type === questionModule.questionTypeEnum.DateTime">
													<base-input class="col-12" label="نوع تقویم">
														<select class="form-control" v-model.number="row[ 'updateRepository' ].data.configuration.dateType" tabindex="5">
															<option value="0">گریگوری</option>
															<option value="1">جلالی</option>
														</select>
													</base-input>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="6">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<template v-else>
													<base-checkbox class="col-12 mb-5" v-model="row[ 'updateRepository' ].data.validation.required" tabindex="5">
														جواب به سوال اجباری است؟
													</base-checkbox>
												</template>
												<base-button class="question-edit-modal-submit-button" native-type="submit" type="primary" :loading="row[ 'updateRepository' ].loading" :disabled="row[ 'updateRepository' ].loading" @click="questionEdit( row[ 'researchId' ], row[ 'researchGroupId' ], row )" tabindex="8">
													<template v-if="!row[ 'updateRepository' ].loading">
														ویرایش
													</template>
												</base-button>
											</form>
										</template>
									</card>
								</modal>
							</td>
						</template>
					</base-table>
					<paginator v-if="pagesCount > 1" :pages-count="pagesCount" v-model="currentPage"></paginator>
				</div>
				<div v-else class="question-card-body-empty">
					سوالی یافت نشد
					<i class="tim-icons icon-refresh-02 question-card-body-empty-refresh" @click="loadQuestions"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { BaseTable, Paginator, Modal } from '../components';
import { QuestionMixin } from '../mixins';

export default {
	mixins: [
		QuestionMixin
	],
	components: {
		BaseTable,
		Modal,
		Paginator
	},
	data() {
		return {
			searchKeyword: '',
			maxRecordsPerPage: 7,
			currentPage: Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1
		};
	},
	computed: {
		pagesCount() {
			return Math.ceil( this.questionModule.repository.count / this.maxRecordsPerPage );
		}
	},
	methods: {
		getUserNameFromQuestion: question => typeof question[ "userFirstName" ] === "string" && typeof question[ "userLastName" ] === "string" ? `${question[ "userFirstName" ]} ${question[ "userLastName" ]}` : "",
		isQuestionSelectable( question ) {
			return [this.questionModule.questionTypeEnum.Boolean, this.questionModule.questionTypeEnum.SelectionSingleAnswer, this.questionModule.questionTypeEnum.SelectionMultipleAnswer].includes( question.type );
		},
		loadQuestions() {
			return this.questionsFetch(
				{
					researchId: this.$route.params.researchId,
					groupId: this.$route.params.groupId,
					includeMetadata: true,
					withDeleted: true,
					searchKeyword: typeof this.searchKeyword === 'string' && this.searchKeyword.length > 0 ? this.searchKeyword : undefined,
					grouping: {
						limit: this.maxRecordsPerPage,
						offset: this.currentPage
					}
				}
			);
		},
		searchByKeyword(event) {
			this.searchKeyword = event.target.value;
			if ( this.currentPage === 1 )
			{
				if( this.searchKeyword === "" && Number( this.$route.query ) !== this.currentPage )
					this.$router.replace({ query: { page: String( this.currentPage ) } });

				this.loadQuestions();
				return;
			}

			this.currentPage = 1;
		}
	},
	watch: {
		currentPage: {
			deep: false,
			immediate: true,
			handler( newValue, oldValue ) {
				if( (typeof this.searchKeyword !== 'string' || this.searchKeyword.length < 1) && Number( this.$route.query.page ) !== newValue )
					this.$router.replace({ query: { page: String( newValue ) } });

				this.loadQuestions().then(
					() => {
						if( this.questionModule.repository.count < 1 && newValue > 1 )
							this.currentPage = 1
					}
				);
			}
		}
	}
};
</script>

<style lang="scss">
.question-card {
	padding: 1.4% 2% 2% 2%;

	.question-card-title {
		display: block;
		margin: 1% 2.5%;

		h1, h2, h3, h4, h5, h6 {
			display: inline-block;
			margin: auto;
			text-align: right;
			float: right;
		}

		.question-add-button, .search-box {
			display: inline-block;
			float: left;

			span {
				display: inline-block;
				margin-left: 7.5%;
			}
		}

		.search-box {
			margin-top: 0.6% !important;
		}
	}

	div.question-card-body {
		padding-bottom: 0;

		div.question-card-body-loading, div.question-card-body-empty {
			margin: auto;
			text-align: center;
		}

		div.question-card-body-empty {
			i.question-card-body-empty-refresh {
				cursor: pointer;
			}
		}
		.questions-container {
			overflow: auto;
			padding-bottom: 0;

			.questions-table {
				margin-bottom: 0;
			}

			div.question-actions {
				text-align: center;

				.question-action-export, .question-action-remove {
					i.fas.fa-spinner.fa-spin {
						position: initial;
					}
				}
			}
		}
	}

	.question-add-modal, .question-edit-modal {
		transform: translate(0, 4%) !important;

		div.modal-content {
			background: transparent;

			.card.card-secondary {
				border-radius: 25px;
				box-shadow: 0 0 15px #4c4c86;
			}
		}

		form {
			div.has-success:after, div.has-danger:after {
				right: 10% !important;
				top: 45% !important;
			}

			& > div {
				display: inline-block;

				select {
					height: auto;

					& > option {

						margin: 40px;
						background: #151520;
						color: #fff;
						text-shadow: 0 1px 0 #00000066;
					}
				}
			}
		}

		.question-add-modal-submit-button, .question-edit-modal-submit-button {
			display: block;
			margin: auto;
		}
	}

	.question-edit-modal {
		.form-check label .form-check-sign::before, .form-check label .form-check-sign::after {
			top: 0;
		}
	}

	.detail-modal, .confirm-modal {
		transform: translate(0, 4%) !important;
		text-align: center;

		div.modal-content {
			background: transparent;

			.card.card-secondary {
				border-radius: 25px;
				box-shadow: 0 0 15px #4c4c86;
			}
		}

		.detail-modal-close-button, .confirm-modal-cancel-button, .confirm-modal-confirm-button {
			display: inline-block;
			margin: auto;
		}
	}

	.detail-modal {
		div.detail-modal-text {
			column-count: 2;
			column-gap: 0;
			color: inherit !important;
			padding: 0 4%;
			margin-bottom: 5%;

			small, b {
				display: block;
			}

			small {
				text-align: right;
			}

			b {
				text-align: left;
			}
		}

		.detail-modal-close-button {
			margin-top: 2%;
			margin-bottom: 1%;
		}
	}

	.confirm-modal {
		p.confirm-modal-text {
			color: inherit !important;
			margin-bottom: 6% !important;
		}

		.confirm-modal-buttons-separator {
			display: inline-block;
			min-width: 3.5%;
		}
	}
}
</style>
