<template>
	<div class="card condition-card" v-if="questionModule.repository.count > 0">
		<div class="condition-card-title">
			<h3>لیست شرط‌های سوال {{ conditionModule.question.code }}</h3>
			<base-button class="condition-add-button animation-on-hover" type="info" @click="conditionModule.new.showModal = true">
				<span>افزودن شرط</span>
				<i class="far fa-plus-circle"></i>
			</base-button>
			<modal :show.sync="conditionModule.new.showModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm condition-add-modal">
				<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
					<template>
						<form @submit.prevent class="col-12">
							<base-input class="col-12" label="وابسته به سوال:">
								<select class="form-control" tabindex="1" v-model="conditionModule.new.conditionRelatedQuestion">
									<option v-for="(questionIndex, questionId) in questionModule.repository.idMap" :value="questionModule.repository.data[ questionIndex ]" :key="questionId">{{ questionModule.repository.data[ questionIndex ].code }}</option>
								</select>
							</base-input>
							<template v-if="conditionModule.new.conditionRelatedQuestion">
								<template v-if="conditionModule.new.conditionRelatedQuestion.type === questionModule.questionTypeEnum.Integer">
									<div class="col-12 mb-4">
										<label class="control-label">
											سوال باید:
										</label>
										<br/>
										<base-radio inline name="required" v-model="conditionModule.new.dataPresent" tabindex="2">
											جواب داده شود
										</base-radio>
										<base-radio inline name="mustBeNull" v-model="conditionModule.new.dataPresent" tabindex="3">
											بدون جواب باشد
										</base-radio>
										<base-radio inline name="dosNotMatter" v-model="conditionModule.new.dataPresent" tabindex="4">
											مهم نیست
										</base-radio>
									</div>
									<template v-if="['required', 'dosNotMatter'].includes(conditionModule.new.dataPresent)">
										<base-input class="col-6" :class="$helpers.isStringNull(conditionModule.new.data.match.min) || questionValidationIntegerValidator( conditionModule.new.data.match.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="conditionModule.new.data.match.min"></base-input>
										<base-input class="col-6 mb-5" :class="$helpers.isStringNull(conditionModule.new.data.match.max) || questionValidationIntegerValidator( conditionModule.new.data.match.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="conditionModule.new.data.match.max"></base-input>
									</template>
								</template>
								<template v-else-if="conditionModule.new.conditionRelatedQuestion.type === questionModule.questionTypeEnum.Float">
									<div class="col-12 mb-4">
										<label class="control-label">
											سوال باید:
										</label>
										<br/>
										<base-radio inline name="required" v-model="conditionModule.new.dataPresent" tabindex="2">
											جواب داده شود
										</base-radio>
										<base-radio inline name="mustBeNull" v-model="conditionModule.new.dataPresent" tabindex="3">
											بدون جواب باشد
										</base-radio>
										<base-radio inline name="dosNotMatter" v-model="conditionModule.new.dataPresent" tabindex="4">
											مهم نیست
										</base-radio>
									</div>
									<template v-if="['required', 'dosNotMatter'].includes(conditionModule.new.dataPresent)">
										<base-input class="col-6" :class="$helpers.isStringNull(conditionModule.new.data.match.min) || questionValidationFloatValidator( conditionModule.new.data.match.min ) ? 'has-success' : 'has-danger'" label="کوچکترین مقدار قابل قبول" type="number" placeholder="کوچکترین مقدار قابل قبول را وارد کنید" tabindex="5" v-model.number="conditionModule.new.data.match.min"></base-input>
										<base-input class="col-6 mb-5" :class="$helpers.isStringNull(conditionModule.new.data.match.max) || questionValidationFloatValidator( conditionModule.new.data.match.max ) ? 'has-success' : 'has-danger'" label="بزرگترین مقدار قابل قبول" type="number" placeholder="بزرگترین مقدار قابل قبول را وارد کنید" tabindex="6" v-model.number="conditionModule.new.data.match.max"></base-input>
									</template>
								</template>
								<template v-else-if="conditionModule.new.conditionRelatedQuestion.type === questionModule.questionTypeEnum.String">
									<div class="col-12 mb-4">
										<label class="control-label">
											سوال باید:
										</label>
										<br/>
										<base-radio inline name="required" v-model="conditionModule.new.dataPresent" tabindex="2">
											جواب داده شود
										</base-radio>
										<base-radio inline name="mustBeNull" v-model="conditionModule.new.dataPresent" tabindex="3">
											بدون جواب باشد
										</base-radio>
										<base-radio inline name="dosNotMatter" v-model="conditionModule.new.dataPresent" tabindex="4">
											مهم نیست
										</base-radio>
									</div>
									<template v-if="['required', 'dosNotMatter'].includes(conditionModule.new.dataPresent)">
										<base-input class="col-6" :class="$helpers.isStringNull(conditionModule.new.data.match.minLength) || questionValidationIntegerValidator( conditionModule.new.data.match.minLength, 0, 4095 ) ? 'has-success' : 'has-danger'" label="حداقل طول مجاز" type="number" placeholder="حداقل طول مجاز را وارد کنید" tabindex="5" v-model.number="conditionModule.new.data.match.minLength"></base-input>
										<base-input class="col-6 mb-5" :class="$helpers.isStringNull(conditionModule.new.data.match.maxLength) || questionValidationIntegerValidator( conditionModule.new.data.match.maxLength, 1, 4095 ) ? 'has-success' : 'has-danger'" label="حداکثر طول مجاز" type="number" placeholder="حداکثر طول مجاز را وارد کنید" tabindex="6" v-model.number="conditionModule.new.data.match.maxLength"></base-input>
									</template>
								</template>
								<template v-else-if="[ QuestionType.Boolean, QuestionType.SelectionSingleAnswer, QuestionType.SelectionMultipleAnswer ].includes(conditionModule.new.conditionRelatedQuestion.type)">
									<div class="col-12 mb-4">
										<label class="control-label">
											سوال باید:
										</label>
										<br/>
										<base-radio inline name="required" v-model="conditionModule.new.dataPresent" tabindex="2">
											جواب داده شود
										</base-radio>
										<base-radio inline name="mustBeNull" v-model="conditionModule.new.dataPresent" tabindex="3">
											بدون جواب باشد
										</base-radio>
										<base-radio inline name="dosNotMatter" v-model="conditionModule.new.dataPresent" tabindex="4">
											مهم نیست
										</base-radio>
									</div>
									<template v-if="['required', 'dosNotMatter'].includes(conditionModule.new.dataPresent)">
										<base-input class="col-12" label="در صورت انتخاب این گزینه:">
											<select class="form-control" tabindex="5" v-model="conditionModule.new.questionChoice">
												<option v-for="(choiceIndex, choiceId) in choiceModule.repository.idMap" :value="choiceModule.repository.data[ choiceIndex ]" :key="choiceId">{{ choiceLabel(choiceModule.repository.data[ choiceIndex ]) }}</option>
											</select>
										</base-input>
									</template>
								</template>
								<template v-else>
									<div class="col-12 mb-5">
										<label class="control-label">
											سوال باید:
										</label>
										<br/>
										<base-radio inline name="required" v-model="conditionModule.new.dataPresent" tabindex="2">
											جواب داده شود
										</base-radio>
										<base-radio inline name="mustBeNull" v-model="conditionModule.new.dataPresent" tabindex="3">
											بدون جواب باشد
										</base-radio>
									</div>
								</template>
								<base-button v-if="conditionModule.new.dataPresent && Object.keys( conditionModule.new.data.match ).length > 0" class="condition-add-modal-submit-button" native-type="submit" type="primary" :loading="conditionModule.new.loading" :disabled="conditionModule.new.loading" @click="conditionAdd( $route.params.researchId, $route.params.groupId, $route.params.questionId )" tabindex="2">
									<template v-if="!conditionModule.new.loading">
										ثبت
									</template>
								</base-button>
							</template>
						</form>
					</template>
				</card>
			</modal>
		</div>
		<div class="card-body condition-card-body">
			<div class="row">
				<div v-if="conditionModule.repository.loading" class="condition-card-body-loading">
					در حال بارگیری شرط‌ها
					<i class="fas fa-spinner fa-spin"/>
				</div>
				<div v-else-if="conditionModule.repository.data.length > 0" class="col-12 table-full-width table-responsive conditions-container">
					<base-table class="conditions-table" :data="conditionModule.repository.data" :columns="['', 'سوال مشروط', 'وابسته به سوال', 'عملیات']" thead-classes="text-primary">
						<template v-slot="{ index, row }">
							<td>{{ $helpers.convert2PersianNumber($helpers.calculateRowIndex(maxRecordsPerPage, currentPage, index + 1).toString()) }}</td>
							<td>{{ conditionModule.question.code }}</td>
							<td>{{ questionModule.repository.data[ questionModule.repository.idMap[row[ 'conditionRelatedQuestionId' ]] ].code }}</td>
							<td class="td-actions text-right">
								<div class="condition-actions">
									<base-button class="animation-on-hover condition-action-detail" type="yellow" size="sm ml-2" title="مشاهده جزئیات" :loading="row[ 'detailRepository' ].showModal" :disabled="row[ 'detailRepository' ].showModal" icon @click="row[ 'detailRepository' ].showModal = true">
										<i class="far fa-circle-info"></i>
									</base-button>
									<base-button class="animation-on-hover condition-action-toggle" type="danger" size="sm ml-2" title="حذف شرط" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" icon @click="row[ 'removeRepository' ].showConfirmModal = true">
										<template v-if="!row[ 'removeRepository' ].loading">
											<i class="far fa-window-close"></i>
										</template>
									</base-button>
									<modal :show.sync="row[ 'detailRepository' ].showModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm detail-modal">
										<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
											<h3>
												جزئیات شرط
											</h3>
											<div class="detail-modal-text">
												<small>
													ایجاد شده توسط:
												</small>
												<b>
													{{ getUserNameFromCondition( row ) }}
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													تاریخ ایجاد شرط:
												</small>
												<b>
													{{ $helpers.convert2PersianNumber( row.createAt.format("HH:mm jYYYY/jMM/jDD") ) }}
												</b>
											</div>
											<div class="detail-modal-text" v-if="row.updateAt">
												<small>
													تاریخ بروزرسانی شرط:
												</small>
												<b>
													{{ $helpers.convert2PersianNumber( row.updateAt.format("HH:mm jYYYY/jMM/jDD") ) }}
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													نوع شرط:
												</small>
												<b>
													<template v-if="row.action == QuestionConditionActionEnum.Show">
														نمایش درصورت برقراری شرط
													</template>
													<template v-else-if="row.action == QuestionConditionActionEnum.Hidden">
														پنهان درصورت برقراری شرط
													</template>
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													سوال مشروط:
												</small>
												<b>
													{{ conditionModule.question.code }}
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													نوع سوال مشروط:
												</small>
												<b>
													{{ questionModule.questionTypes[ conditionModule.question.type ].name.fa }}
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													وابسته به سوال:
												</small>
												<b>
													{{ questionModule.repository.data[ questionModule.repository.idMap[ row.conditionRelatedQuestionId ] ].code }}
												</b>
											</div>
											<div class="detail-modal-text">
												<small>
													نوع سوال وابسته شده:
												</small>
												<b>
													{{ questionModule.questionTypes[ questionModule.repository.data[ questionModule.repository.idMap[ row.conditionRelatedQuestionId ] ].type ].name.fa }}
												</b>
											</div>
											<div class="detail-modal-text" v-if="row.match.mustBeNull === true || row.match.required === true">
												<small>
													سوال باید پاسخ:
												</small>
												<b>
													{{ row.match.mustBeNull === true ? 'نداشته باشد' : 'داشته باشد' }}
												</b>
											</div>
											<template v-if="row.match.mustBeNull !== true">
												<template v-if="[ QuestionType.Integer, QuestionType.Float ].includes( questionModule.repository.data[ questionModule.repository.idMap[ row.conditionRelatedQuestionId ] ].type )">
													<div class="detail-modal-text" v-if="typeof row.match.min === 'number'">
														<small>
															مقدار جواب باید بزرگتر از:
														</small>
														<b>
															{{ $helpers.convert2PersianNumber( row.match.min.toString() ) }} باشد
														</b>
													</div>
													<div class="detail-modal-text" v-if="typeof row.match.max === 'number'">
														<small>
															مقدار جواب باید کوچکتر از:
														</small>
														<b>
															{{ $helpers.convert2PersianNumber( row.match.max.toString() ) }} باشد
														</b>
													</div>
												</template>
												<template v-else-if="questionModule.repository.data[ questionModule.repository.idMap[ row.conditionRelatedQuestionId ] ].type === questionModule.questionTypeEnum.String">
													<div class="detail-modal-text" v-if="typeof row.match.minLength === 'number'">
														<small>
															طول جواب باید بیشتر از:
														</small>
														<b>
															{{ $helpers.convert2PersianNumber( row.match.minLength.toString() ) }} باشد
														</b>
													</div>
													<div class="detail-modal-text" v-if="typeof row.match.maxLength === 'number'">
														<small>
															طول جواب باید کمتر از:
														</small>
														<b>
															{{ $helpers.convert2PersianNumber( row.match.maxLength.toString() ) }} باشد
														</b>
													</div>
												</template>
												<template v-else-if="[ QuestionType.Boolean, QuestionType.SelectionSingleAnswer, QuestionType.SelectionMultipleAnswer ].includes( questionModule.repository.data[ questionModule.repository.idMap[ row.conditionRelatedQuestionId ] ].type )">
													<div class="detail-modal-text" v-if="typeof row.match.questionChoices === 'object' && row.match.questionChoices instanceof Array && row.match.questionChoices.length === 1 && typeof choicesLabels[ row.match.questionChoices[0] ] === 'string'">
														<small>
															گزینه‌ای که باید انتخاب شود:
														</small>
														<b>
															{{ choicesLabels[ row.match.questionChoices[0] ] }}
														</b>
													</div>
												</template>
											</template>
											<base-button class="detail-modal-close-button" type="danger" tabindex="1" @click="row[ 'detailRepository' ].showModal = false">
												بستن
											</base-button>
										</card>
									</modal>
									<modal :show.sync="row[ 'removeRepository' ].showConfirmModal" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm confirm-modal">
										<card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
											<h3>
												هشدار!
											</h3>
											<p class="confirm-modal-text">
												آیا از حذف این شرط مطمئن هستید؟
												<br/>
												(این عملیات غیر قابل بازگشت می‌باشد.)
											</p>
											<base-button class="confirm-modal-cancel-button" type="info" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" tabindex="1" @click="row[ 'removeRepository' ].showConfirmModal = false">
												<template v-if="!row[ 'removeRepository' ].loading">
													انصراف
												</template>
											</base-button>
											<div class="confirm-modal-buttons-separator"></div>
											<base-button class="confirm-modal-confirm-button" type="danger" :loading="row[ 'removeRepository' ].loading" :disabled="row[ 'removeRepository' ].loading" tabindex="2" @click="() => { row[ 'removeRepository' ].loading = true; conditionRemove($route.params.researchId, $route.params.groupId, row[ 'conditionalQuestionId' ], row[ 'id' ]).then(() => { row[ 'removeRepository' ].loading = false; }, () => { row[ 'removeRepository' ].loading = false; }); }">
												<template v-if="!row[ 'removeRepository' ].loading">
													تایید
												</template>
											</base-button>
										</card>
									</modal>
								</div>
							</td>
						</template>
					</base-table>
					<paginator v-if="pagesCount > 1" :pages-count="pagesCount" v-model="currentPage"></paginator>
				</div>
				<div v-else class="condition-card-body-empty">
					شرطی یافت نشد
					<i class="tim-icons icon-refresh-02 condition-card-body-empty-refresh" @click="loadConditions()"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { QuestionMixin, ChoiceMixin, ConditionMixin } from '../mixins';
import { QuestionConditionActionEnum } from '../mixins/ConditionMixin';
import { BaseTable, Paginator, BaseRadio, Modal } from '../components';

export default {
	mixins: [
		QuestionMixin,
		ChoiceMixin,
		ConditionMixin
	],
	components: {
		BaseTable,
		BaseRadio,
		Modal,
		Paginator
	},
	data() {
		return {
			QuestionConditionActionEnum,
			maxRecordsPerPage: 7,
			currentPage: Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1,
			choicesLabels: {}
		};
	},
	computed: {
		pagesCount() {
			return Math.ceil( this.conditionModule.repository.count / this.maxRecordsPerPage );
		}
	},
	methods: {
		choiceLabel( choice ) {
			if ( typeof choice?.title === 'string' && choice.title.length > 0 )
				return `${choice.code} - (${choice.title})`;

			return choice.code;
		},
		async fetchChoiceAndGetLabel( questionId, choiceId ) {
			return this.choiceLabel(
				await this.choiceFetch(
					this.$route.params.researchId,
					this.$route.params.groupId,
					questionId,
					choiceId
				)
			)
		},
		getUserNameFromCondition( condition ) {
			return typeof condition[ 'userFirstName' ] === 'string' && typeof condition[ 'userLastName' ] === 'string' ? `${condition[ 'userFirstName' ]} ${condition[ 'userLastName' ]}` : 'کاربر یافت نشد'
		},
		loadConditions() {
			return this.conditionsFetch(
				this.$route.params.researchId,
				this.$route.params.groupId,
				this.$route.params.questionId,
				{
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			).then(
				() => {
					for( const condition of this.conditionModule.repository.data )
						if(
							[ this.QuestionType.Boolean, this.QuestionType.SelectionSingleAnswer, this.QuestionType.SelectionMultipleAnswer ].includes(
								this.questionModule.repository.data[ this.questionModule.repository.idMap[ condition.conditionRelatedQuestionId ] ].type
							) &&
							typeof condition.match.questionChoices === 'object'&&
							condition.match.questionChoices instanceof Array &&
							condition.match.questionChoices.length === 1
						)
							this.fetchChoiceAndGetLabel( condition.conditionRelatedQuestionId, condition.match.questionChoices[ 0 ] ).then(
								choiceLabel => { this.choicesLabels[ condition.match.questionChoices[0] ] = choiceLabel; }
							);
				}
			);
		}
	},
	watch: {
		currentPage: {
			deep: false,
			immediate: false,
			handler( newValue, oldValue ) {
				if( Number( this.$route.query.page ) !== newValue )
					this.$router.replace({ query: { page: String( newValue ) } });

				this.loadConditions().then(
					() => {
						if( this.conditionModule.repository.count < 1 && newValue > 1 )
							this.currentPage = 1
					}
				);
			}
		}
	},
	created() {
		this.conditionModuleInitialize( this.$route.params.researchId, this.$route.params.groupId, this.$route.params.questionId ).then(
			result => result || this.$router.go( -1 ),
			error => { throw error }
		);
	}
};
</script>

<style lang="scss">
.condition-card {
	padding: 1.4% 2% 2% 2%;

	.condition-card-title {
		display: block;
		margin: 1% 2.5%;

		h1, h2, h3, h4, h5, h6 {
			display: inline-block;
			margin: auto;
			text-align: right;
			float: right;
		}

		.condition-add-button {
			display: inline-block;
			float: left;

			span {
				display: inline-block;
				margin-left: 7.5%;
			}
		}
	}

	div.condition-card-body {
		padding-bottom: 0;

		div.condition-card-body-loading, div.condition-card-body-empty {
			margin: auto;
			text-align: center;
		}

		div.condition-card-body-empty {
			i.condition-card-body-empty-refresh {
				cursor: pointer;
			}
		}
		.conditions-container {
			overflow: auto;
			padding-bottom: 0;

			.conditions-table {
				margin-bottom: 0;
			}

			div.condition-actions {
				text-align: center;

				.condition-action-toggle, .condition-action-detail {
					i.fas.fa-spinner.fa-spin {
						position: initial;
					}
				}
			}
		}
	}

	.condition-add-modal, .condition-edit-modal {
		transform: translate(0, 4%) !important;

		div.modal-content {
			background: transparent;

			.card.card-secondary {
				border-radius: 25px;
				box-shadow: 0 0 15px #4c4c86;
			}
		}

		form {
			div.has-success:after, div.has-danger:after {
				right: 10% !important;
				top: 45% !important;
			}

			& > div {
				display: inline-block;

				select > option {
					margin: 40px;
					background: #151520;
					color: #fff;
					text-shadow: 0 1px 0 #00000066;
				}
			}
		}

		.condition-add-modal-submit-button, .condition-edit-modal-submit-button {
			display: block;
			margin: auto;
		}
	}

	.detail-modal, .confirm-modal {
		transform: translate(0, 4%) !important;

		div.modal-content {
			background: transparent;

			.card.card-secondary {
				border-radius: 25px;
				box-shadow: 0 0 15px #4c4c86;
			}
		}

		.detail-modal-close-button, .confirm-modal-cancel-button, .confirm-modal-confirm-button {
			display: inline-block;
			margin: auto;
		}
	}

	.detail-modal {
		div.detail-modal-text {
			column-count: 2;
			column-gap: 0;
			color: inherit !important;
			padding: 0 4%;
			margin-bottom: 5%;

			small, b {
				display: block;
			}

			small {
				text-align: right;
			}

			b {
				text-align: left;
			}
		}

		.detail-modal-close-button {
			margin-top: 2%;
			margin-bottom: 1%;
		}
	}

	.confirm-modal {
		p.confirm-modal-text {
			color: inherit !important;
			margin-bottom: 6% !important;
		}

		.confirm-modal-buttons-separator {
			display: inline-block;
			min-width: 3.5%;
		}
	}
}
</style>
