






























































import { Vue, Component, Inject, Prop, VModel } from "vue-property-decorator"
import { ThemeConfigurationType } from "../types"

@Component
export default class Paginator extends Vue {
	@Inject("themeSelector")
	private themeSelector!: ThemeConfigurationType

	private get themeConfiguration() {
		return {
			'backgroundColor': this.themeSelector.backgroundColors[ this.themeSelector.theme.backgroundColor ],
			'sidebarColor': this.themeSelector.sidebarColors[ this.themeSelector.theme.sidebarColor ].value
		}
	}

	@Prop({ required: true })
	private pagesCount!: number

	@VModel({ required: true, type: Number })
	private currentPage!: number

	private changeCurrentPage( pageNumber: number )
	{
		this.currentPage = pageNumber
	}
}
