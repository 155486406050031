
























































































































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { BaseTable, Paginator, Modal } from '../components'
import { UserMixin, UserRepositoryType } from "../mixins/UserMixin"
import { UserRole as UserRoleEnum, UserRoles } from '../enums'

@Component({ components: { BaseTable, Modal, Paginator } })
export default class Users extends Mixins( UserMixin )
{
	private readonly UserRoleEnum = UserRoleEnum
	private readonly UserRoles = UserRoles
	private searchKeyword = ''
	private withDeleted = true
	private maxRecordsPerPage = 7
	private currentPage = Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1

	private readonly userNameValidator = ( name: string ): boolean => UserMixin.userNameValidator( name )
	private readonly userMobileValidator = ( mobile: string ): boolean => UserMixin.userMobileValidator( mobile )

	private get pagesCount(): number
	{
		return Math.ceil( this.userModule.repository.count / this.maxRecordsPerPage )
	}

	private loadUsers(): Promise<void>
	{
		return this.usersFetch(
			{
				searchKeyword: typeof this.searchKeyword === 'string' && this.searchKeyword.length > 0 ? this.searchKeyword : null,
				withDeleted: this.withDeleted,
				grouping: {
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			}
		)
	}

	private searchByKeyword( event: Event )
	{
		this.searchKeyword = (<HTMLInputElement>event.target).value
		if ( this.currentPage === 1 )
		{
			if( this.searchKeyword === "" && Number( this.$route.query ) !== this.currentPage )
				this.$router.replace({ query: { page: String( this.currentPage ) } })

			this.loadUsers()
			return
		}

		this.currentPage = 1
	}

	private async doAndReload<ArgumentsType, ReturnType>( callback: ( ...args: ArgumentsType[] ) => ReturnType, ...args: ArgumentsType[] ): Promise<ReturnType>
	{
		const result = await callback( ...args )

		this.loadUsers()

		return result
	}

	private async userAddAndReload(): Promise<boolean>
	{
		return await this.doAndReload( this.userAdd )
	}

	private async userToggleAndReload( user: UserRepositoryType ): Promise<void>
	{
		user.toggleRepository.loading = true

		try
		{
			await this.doAndReload( user.enabled ? this.userDisable : this.userEnable, user.mobile )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			user.toggleRepository.loading = false
		}
	}

	private async userEditAndReload( user: UserRepositoryType ): Promise<boolean>
	{
		return await this.doAndReload( this.userEdit, user )
	}

	private async userRemoveAndReload( user: UserRepositoryType ): Promise<void>
	{
		user.removeRepository.loading = true

		try
		{
			await this.doAndReload( this.userRemove, user.mobile )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			user.removeRepository.loading = false
		}
	}

	@Watch('currentPage', { deep: false, immediate: true })
	private onCurrentPageChanged( newCurrentPage: number, oldCurrentPage: number )
	{
		if( (typeof this.searchKeyword !== 'string' || this.searchKeyword.length < 1) && Number( this.$route.query.page ) !== newCurrentPage )
			this.$router.replace({ query: { page: String( newCurrentPage ) } })

		this.loadUsers().then(
			() => {
				if( this.userModule.repository.count < 1 && newCurrentPage > 1 )
					this.currentPage = 1
			}
		)
	}
}
