
































import { Component, Mixins } from "vue-property-decorator"
import { UserMixin, UserRepositoryType } from "../mixins/UserMixin"
import { UserRoles } from '../enums'
import VuePersianDatetimePicker from "vue-persian-datetime-picker"

@Component({ components: { datePicker: VuePersianDatetimePicker } })
export default class Profile extends Mixins( UserMixin )
{
	private readonly UserRoles = UserRoles//user.updateRepository.data.password
	private user: UserRepositoryType | null = null
	private newFirstName: string | null = null
	private newLastName: string | null = null
	private newPassword: string | null = null
	private newPasswordRepeat: string | null = null

	private get profileValidationFlag(): boolean
	{
		return ( this.user?.firstName !== this.newFirstName || this.user?.lastName !== this.newLastName ) && UserMixin.userNameValidator( this.newFirstName as string ) && UserMixin.userNameValidator( this.newLastName as string )
	}

	private get passwordValidationFlag(): boolean
	{
		return UserMixin.userPasswordValidator( this.newPassword as string ) && this.newPassword === this.newPasswordRepeat
	}

	private async loadUser(): Promise<void>
	{
		this.user = await this.himselfFetch()

		this.newFirstName = this.user.firstName
		this.newLastName = this.user.lastName

		this.newPassword = null
		this.newPasswordRepeat = null

		this.user.updateRepository.data = {
			firstName: this.user.updateRepository.data.firstName,
			lastName: this.user.updateRepository.data.lastName,
			password: ""
		} as UserRepositoryType[ "updateRepository" ][ "data" ]
	}

	public beforeMount(): void
	{
		this.loadUser()
	}

	private async editProfile(): Promise<void>
	{
		if( this.profileValidationFlag )
		{
			( this.user as UserRepositoryType ).updateRepository.data.firstName = this.newFirstName as string
			( this.user as UserRepositoryType ).updateRepository.data.lastName = this.newLastName as string
			( this.user as UserRepositoryType ).updateRepository.data.password = ""

			if( await this.himselfEdit( this.user as UserRepositoryType ) )
				await this.loadUser()
		}
	}

	private async editPassword(): Promise<void>
	{
		if( this.passwordValidationFlag )
		{
			( this.user as UserRepositoryType ).updateRepository.data.firstName = ( this.user as UserRepositoryType ).firstName;
			( this.user as UserRepositoryType ).updateRepository.data.lastName = ( this.user as UserRepositoryType ).lastName;
			( this.user as UserRepositoryType ).updateRepository.data.password = this.newPassword as string

			if( await this.himselfEdit( this.user as UserRepositoryType ) )
				await this.loadUser()
		}
	}
}
