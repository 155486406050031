import { Vue, Component } from "vue-property-decorator"
import { default as jMoment } from "moment-jalaali"
import { DocumentModuleType, GenderEnum, FetchOptionsType, DocumentCreatePayloadType, DocumentRepositoryType, DocumentType, DocumentUpdatePayloadType } from "./Types"

@Component
export class DocumentMixin extends Vue
{
	protected readonly documentModule: DocumentModuleType = {
		"repository": {
			"loading": true,
			"count": 0,
			"data": []
		},
		"new": {
			"loading": false,
			"data": {
				"cityId": 0,
				"code": "",
				"firstName": "",
				"lastName": "",
				"gender": null as unknown as GenderEnum,
				"nationalCode": "",
				"jQuestioningDate": "",
				"jBirthdate": "",
				"mobile": "",
				"address": ""
			}
		}
	}

	protected static dateValidator( date: unknown ): boolean
	{
		return typeof date === "string" && /^((1[8-9][0-9]{2})|(2[0-9]{3}))-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(30|1))$/.test( date )
	}

	protected static jDateValidator( jDate: unknown ): boolean
	{
		return typeof jDate === "string" && /^(1[2-9][0-9]{2})-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(3[01]))$/.test( jDate )
	}

	protected static documentResearchIdValidator( researchId: unknown ): boolean
	{
		return typeof researchId === "number" && researchId > 0
	}

	protected static documentCityIdValidator( cityId: unknown ): boolean
	{
		return typeof cityId === "number" && cityId > -1
	}

	protected static documentCodeValidator( code: unknown ): boolean
	{
		return typeof code === "string" && code.length > 0 && code.length < 33
	}

	protected static documentNameValidator( name: unknown ): boolean
	{
		return typeof name === "string" && name.length > 2 && name.length < 41
	}

	protected static documentGenderValidator( gender: unknown ): boolean
	{
		return typeof gender === "number" && (gender === 0 || gender === 1)
	}

	protected static documentNationalCodeValidator( nationalCode: unknown ): boolean
	{
		return typeof nationalCode === "string" && /^[0-9]{10}$/.test( nationalCode )
	}

	protected static documentQuestioningDateValidator( questioningDate: unknown ): boolean
	{
		return (questioningDate === undefined || questioningDate === null || questioningDate === "") || this.dateValidator( questioningDate )
	}

	protected static documentJQuestioningDateValidator( jQuestioningDate: unknown ): boolean
	{
		return (jQuestioningDate === undefined || jQuestioningDate === null || jQuestioningDate === "") || this.jDateValidator( jQuestioningDate )
	}

	protected static documentBirthdateValidator( birthdate: unknown ): boolean
	{
		return (birthdate === undefined || birthdate === null || birthdate === "") || this.dateValidator( birthdate )
	}

	protected static documentJBirthdateValidator( jBirthdate: unknown ): boolean
	{
		return (jBirthdate === undefined || jBirthdate === null || jBirthdate === "") || this.jDateValidator( jBirthdate )
	}

	protected static documentMobileValidator( mobile: unknown ): boolean
	{
		return (mobile === undefined || mobile === null || mobile === "") || (typeof mobile === "string" && /^09[0-9]{9}$/.test( mobile ))
	}

	protected static documentAddressValidator( address: unknown ): boolean
	{
		return (address === undefined || address === null || address === "") || (typeof address === "string" && address.length > 1 && address.length < 512)
	}

	protected validateDocument( document: DocumentModuleType[ "new" ] | DocumentRepositoryType[ "updateRepository" ] ): boolean
	{
		if( !DocumentMixin.documentNameValidator( document.data.firstName ) )
		{
			this.$notifications.error( "مقدار وارد شده برای فیلد نام نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentNameValidator( document.data.lastName ) )
		{
			this.$notifications.error( "مقدار وارد شده برای فیلد نام‌خانوادگی نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentCodeValidator( document.data.code ) )
		{
			this.$notifications.error( "مقدار وارد شده برای کد پرسشنامه نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentGenderValidator( document.data.gender ) )
		{
			this.$notifications.error( "مقدار وارد شده برای جنسیت نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentNationalCodeValidator( document.data.nationalCode ) )
		{
			this.$notifications.error( "مقدار وارد شده برای کدملی نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentJQuestioningDateValidator( document.data.jQuestioningDate ) )
		{
			this.$notifications.error( "مقدار وارد شده برای تاریخ پرسشگری نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentJBirthdateValidator( document.data.jBirthdate ) )
		{
			this.$notifications.error( "مقدار وارد شده برای تاریخ تولد نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentCityIdValidator( document.data.cityId ) )
		{
			this.$notifications.error( "مقدار وارد شده برای شهر نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentAddressValidator( document.data.address ) )
		{
			this.$notifications.error( "مقدار وارد شده برای آدرس نامعتبر می‌باشد" )

			return false
		}
		if( !DocumentMixin.documentMobileValidator( document.data.mobile ) )
		{
			this.$notifications.error( "مقدار وارد شده برای موبایل نامعتبر می‌باشد" )

			return false
		}

		return true
	}

	async documentsFetch( fetchOptions: FetchOptionsType ): Promise<void>
	{
		this.documentModule.repository.loading = true

		try
		{
			let groupingQuery = ""
			let searchConditions = {}
			let researchId = -1
			let searchKeyword: string | null = null
			if( fetchOptions && typeof fetchOptions === "object" )
			{
				if( fetchOptions[ "grouping" ] && typeof fetchOptions[ "grouping" ] === "object" && typeof fetchOptions[ "grouping" ].offset === "number" && typeof fetchOptions[ "grouping" ].limit === "number" )
					groupingQuery = `${fetchOptions[ "grouping" ].limit}@${fetchOptions[ "grouping" ].offset}`

				if( fetchOptions[ "searchConditions" ] && typeof fetchOptions[ "searchConditions" ] === "object" )
					searchConditions = fetchOptions[ "searchConditions" ]

				if( typeof fetchOptions[ "researchId" ] === "number" )
					researchId = fetchOptions[ "researchId" ]

				if( typeof fetchOptions[ "searchKeyword" ] === "string" )
					searchKeyword = fetchOptions[ "searchKeyword" ]
			}

			const response = await fetch(
				typeof searchKeyword === "string" ? `${this.$authentication.serverAddress}/research/${researchId}/document/search/${searchKeyword}?search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}` : `${this.$authentication.serverAddress}/research/${researchId}/document?search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					}
				}
			)

			if( response.ok )
			{
				const responseBody: { count: number, data: any[] } = await response.json()

				this.documentModule.repository.count = responseBody.count
				this.documentModule.repository.data = []
				responseBody.data.forEach(
					document => {
						const record: DocumentRepositoryType = {
							...document,
							"questioningDate": typeof document.questioningDate === "string" && /^([0-9]{4})-((0[1-9])|(1[0-2]))-((0[1-9])|([12][0-9])|(3[01]))$/.test( document.questioningDate ) ? jMoment( document.questioningDate, "YYYY-MM-DD" ) : null,
							"jQuestioningDate": undefined as unknown as DocumentRepositoryType[ "jQuestioningDate" ],
							"birthdate": typeof document.birthdate === "string" && /^([0-9]{4})-((0[1-9])|(1[0-2]))-((0[1-9])|([12][0-9])|(3[01]))$/.test( document.birthdate ) ? jMoment( document.birthdate, "YYYY-MM-DD" ) : null,
							"jBirthdate": undefined as unknown as DocumentRepositoryType[ "jBirthdate" ],
							"createAt": jMoment.utc( document.createAt ).local(),
							"updateAt": typeof document.updateAt === "string" ? jMoment.utc( document.updateAt ).local() : null,
							"questionnaireCreateAt": typeof document.questionnaireCreateAt === "string" ? jMoment.utc( document.questionnaireCreateAt ).local() : null,
							"questionnaireUpdateAt": typeof document.questionnaireUpdateAt === "string" ? jMoment.utc( document.questionnaireUpdateAt ).local() : null,
							"detailRepository": {
								"showModal": false
							},
							"removeRepository": {
								"showConfirmModal": false,
								"loading": false
							},
							"updateRepository": {
								"loading": false,
								"data": {
									"cityId": document.cityId,
									"code": document.code,
									"firstName": document.firstName,
									"lastName": document.lastName,
									"gender": document.gender,
									"nationalCode": document.nationalCode,
									"questioningDate": document.questioningDate,
									"birthdate": document.birthdate,
									"mobile": document.mobile,
									"address": document.address
								}
							}
						}
						record.jQuestioningDate = record.questioningDate ? record.questioningDate.format( "jYYYY-jMM-jDD" ) as DocumentRepositoryType[ "jQuestioningDate" ] : ""
						record.jBirthdate = record.birthdate ? record.birthdate.format( "jYYYY-jMM-jDD" ) as DocumentRepositoryType[ "jBirthdate" ] : ""
						record.updateRepository.data.jQuestioningDate = record.jQuestioningDate
						record.updateRepository.data.jBirthdate = record.jBirthdate

						this.documentModule.repository.data.push( record )
					}
				)
			}
			else if( response.status === 404 )
			{
				if( typeof searchKeyword !== "string" )
					this.$notifications.warning( "هیچ پرونده‌ای یافت نشد" )

				this.documentModule.repository.count = 0
				this.documentModule.repository.data = []
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری پرونده‌ها رخ داد" )
		}

		this.documentModule.repository.loading = false
	}

	async documentFetch( documentId: DocumentType[ "id" ] ): Promise<DocumentRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${this.$route.params.researchId}/document/${documentId}`,
				{
					method: "GET",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				const document: DocumentRepositoryType = {
					...( await response.json() ),
					"detailRepository": {
						"showModal": false
					},
					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					},
					"updateRepository": {
						"loading": false,
						"data": null
					}
				}
				document.questioningDate = typeof document.questioningDate as string | null === "string" && /^([0-9]{4})-((0[1-9])|(1[0-2]))-((0[1-9])|([12][0-9])|(3[01]))$/.test( document.questioningDate as unknown as string ) ? jMoment( document.questioningDate, "YYYY-MM-DD" ) : null
				document.jQuestioningDate = document.questioningDate ? document.questioningDate.format( "jYYYY-jMM-jDD" ) as DocumentRepositoryType[ "jQuestioningDate" ] : ""
				document.birthdate = typeof document.birthdate as string | null === "string" && /^([0-9]{4})-((0[1-9])|(1[0-2]))-((0[1-9])|([12][0-9])|(3[01]))$/.test( document.birthdate as unknown as string ) ? jMoment( document.birthdate, "YYYY-MM-DD" ) : null
				document.jBirthdate = document.birthdate ? document.birthdate.format( "jYYYY-jMM-jDD" ) as DocumentRepositoryType[ "jBirthdate" ] : ""
				document.createAt = jMoment.utc( document.createAt ).local()
				document.updateAt = typeof document.updateAt === "string" as string | null ? jMoment.utc( document.updateAt ).local() : null
				document.questionnaireCreateAt = typeof document.questionnaireCreateAt === "string" as string | null ? jMoment.utc( document.questionnaireCreateAt ).local() : null
				document.questionnaireUpdateAt = typeof document.questionnaireUpdateAt === "string" as string | null ? jMoment.utc( document.questionnaireUpdateAt ).local() : null
				document.updateRepository.data = {
					"cityId": document.cityId,
					"code": document.code,
					"firstName": document.firstName,
					"lastName": document.lastName,
					"gender": document.gender,
					"nationalCode": document.nationalCode,
					"questioningDate": document.questioningDate as unknown as string,
					"jQuestioningDate": document.jQuestioningDate,
					"birthdate": document.birthdate as unknown as string,
					"jBirthdate": document.jBirthdate,
					"mobile": document.mobile,
					"address": document.address
				}

				return document
			}
			else if( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch( error )
		{
			if( error === 404 )
			{
				this.$notifications.warning( "پرونده یافت نشد" )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( "خطای ناشناخته در هنگام بارگیری پرونده رخ داد" )

			throw error
		}
	}

	async documentAdd(): Promise<boolean>
	{
		this.documentModule.new.loading = true

		if( !DocumentMixin.documentResearchIdValidator( parseInt(this.$route.params.researchId) ) )
		{
			this.$notifications.error( "مقدار وارد شده برای طرح پژوهشی نامعتبر می‌باشد" )

			this.documentModule.new.loading = false
			return false
		}

		if( !this.validateDocument( this.documentModule.new ) )
		{
			this.documentModule.new.loading = false
			return false
		}

		const payload: DocumentCreatePayloadType = {
			"cityId": this.documentModule.new.data.cityId,
			"code": this.documentModule.new.data.code,
			"firstName": this.documentModule.new.data.firstName,
			"lastName": this.documentModule.new.data.lastName,
			"gender": this.documentModule.new.data.gender,
			"nationalCode": this.documentModule.new.data.nationalCode
		}

		if( this.documentModule.new.data.jQuestioningDate !== undefined && this.documentModule.new.data.jQuestioningDate !== null && this.documentModule.new.data.jQuestioningDate !== "" )
			payload.questioningDate = jMoment( this.documentModule.new.data.jQuestioningDate, "jYYYY-jMM-jDD" ).format( "YYYY-MM-DD" )

		if( this.documentModule.new.data.jBirthdate !== undefined && this.documentModule.new.data.jBirthdate !== null && this.documentModule.new.data.jBirthdate !== "" )
			payload.birthdate = jMoment( this.documentModule.new.data.jBirthdate, "jYYYY-jMM-jDD" ).format( "YYYY-MM-DD" )

		if( this.documentModule.new.data.mobile !== undefined && this.documentModule.new.data.mobile !== null && this.documentModule.new.data.mobile !== "" )
			payload.mobile = this.documentModule.new.data.mobile

		if( this.documentModule.new.data.address !== undefined && this.documentModule.new.data.address !== null && this.documentModule.new.data.address !== "" )
			payload.address = this.documentModule.new.data.address

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${this.$route.params.researchId}/document`,
				{
					method: "PUT",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				this.documentModule.new.data = {
					"cityId": 0,
					"code": "",
					"firstName": "",
					"lastName": "",
					"gender": null as unknown as GenderEnum,
					"nationalCode": "",
					"jQuestioningDate": "",
					"jBirthdate": "",
					"mobile": "",
					"address": ""
				}

				this.documentModule.new.loading = false

				this.$notifications.successful( "پرونده با موفقیت اضافه شد" )

				try
				{
					await this.$router.push({ name: "Dashboard_Documents_Questionnaire", params: { researchId: this.$route.params.researchId, documentId: ( await response.json() ).id } })
				}
				catch( error )
				{
					await this.$router.push( { name: "Dashboard_Documents", params: { researchId: this.$route.params.researchId } } )
				}

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch( error )
		{
			console.error( error )

			this.documentModule.new.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام افزودن پرونده رخ داد" )

			return false
		}
	}

	async documentEdit( document: DocumentRepositoryType ): Promise<boolean>
	{
		document.updateRepository.loading = true

		if( !this.validateDocument( document.updateRepository ) )
		{
			document.updateRepository.loading = false
			return false
		}

		const payload: DocumentUpdatePayloadType = {}
		if( document.updateRepository.data.cityId !== document.cityId )
			payload.cityId = document.updateRepository.data.cityId
		if( document.updateRepository.data.code !== document.code )
			payload.code = document.updateRepository.data.code
		if( document.updateRepository.data.firstName !== document.firstName )
			payload.firstName = document.updateRepository.data.firstName
		if( document.updateRepository.data.lastName !== document.lastName )
			payload.lastName = document.updateRepository.data.lastName
		if( document.updateRepository.data.gender !== document.gender )
			payload.gender = document.updateRepository.data.gender
		if( document.updateRepository.data.nationalCode !== document.nationalCode )
			payload.nationalCode = document.updateRepository.data.nationalCode
		if( document.updateRepository.data.jQuestioningDate !== document.jQuestioningDate )
			payload.questioningDate = (document.updateRepository.data.jQuestioningDate ?? "") === "" ? null : jMoment( document.updateRepository.data.jQuestioningDate, "jYYYY-jMM-jDD" ).format( "YYYY-MM-DD" )
		if( document.updateRepository.data.jBirthdate !== document.jBirthdate )
			payload.birthdate = (document.updateRepository.data.jBirthdate ?? "") === "" ? null : jMoment( document.updateRepository.data.jBirthdate, "jYYYY-jMM-jDD" ).format( "YYYY-MM-DD" )
		if( document.updateRepository.data.mobile !== document.mobile )
			payload.mobile = (document.updateRepository.data.mobile ?? "") === "" ? null : document.updateRepository.data.mobile
		if( document.updateRepository.data.address !== document.address )
			payload.address = (document.updateRepository.data.address ?? "") === "" ? null : document.updateRepository.data.address

		if( Object.values( payload ).length < 1 )
		{
			document.updateRepository.loading = false

			try
			{
				await this.$router.go( -1 )
			}
			catch( error )
			{
				await this.$router.push( { name: "Dashboard_Documents", params: { researchId: this.$route.params.researchId } } )
			}

			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${this.$route.params.researchId}/document/${document.id}`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken,
						"Content-Type": "application/json"
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				document.updateRepository.loading = false

				this.$notifications.successful( "طرح با موفقیت ویرایش شد" )

				try
				{
					await this.$router.go( -1 )
				}
				catch( error )
				{
					await this.$router.push( { name: "Dashboard_Documents", params: { researchId: this.$route.params.researchId } } )
				}

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch( error )
		{
			console.error( error )

			document.updateRepository.loading = false

			this.$notifications.error( "خطای ناشناخته در هنگام ویرایش طرح رخ داد" )

			return false
		}
	}

	async documentRemove( documentId: DocumentType[ "id" ], researchId = this.$route.params.researchId ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/document/${documentId}?hard-delete=true`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				this.$notifications.successful( "پرونده با موفقیت حذف گردید" )

				try
				{
					await this.$router.go( 0 )
				}
				catch( error )
				{
					await this.$router.push( { name: "Dashboard_Documents", params: { researchId } } )
				}

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
		)
		}
		catch( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام حذف پرونده رخ داد" )
		}
	}

	async documentDisable( documentId: DocumentType[ "id" ], researchId = this.$route.params.researchId ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/document/${documentId}?hard-delete=false`,
				{
					method: "DELETE",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				this.$notifications.successful( "پرونده با موفقیت غیرفعال شد" )

				try
				{
					await this.$router.go( 0 )
				}
				catch( error )
				{
					await this.$router.push( { name: "Dashboard_Documents", params: { researchId } } )
				}

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام غیرفعال کردن پرونده رخ داد" )
		}
	}

	async documentEnable( documentId: DocumentType[ "id" ], researchId = this.$route.params.researchId ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/research/${researchId}/document/${documentId}/restore`,
				{
					method: "PATCH",
					mode: "cors",
					referrerPolicy: "no-referrer",
					headers: {
						"Authorization": this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				this.$notifications.successful( "پرونده با موفقیت فعال شد" )

				try
				{
					await this.$router.go( 0 )
				}
				catch( error )
				{
					await this.$router.push( { name: "Dashboard_Documents", params: { researchId } } )
				}

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch( error )
		{
			console.error( error )

			this.$notifications.error( "خطای ناشناخته در هنگام فعال کردن پرونده رخ داد" )
		}
	}
}

export default DocumentMixin
