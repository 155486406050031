import * as jMoment from "moment-jalaali";
import { QuestionType } from "../../enums";

export default {
	data() {
		return {
			'QuestionType': QuestionType,
			'conditionModule': {
				'question': null,
				'repository': {
					'loading': true,
					'count': 0,
					'data': [],
					'idMap': {}
				},
				'new': {
					'showModal': false,
					'loading': false,
					'conditionRelatedQuestion': null,
					'dataPresent': null,
					'questionChoice': null,
					'data': {
						'conditionRelatedQuestionId': null,
						'match': {}
					}
				}
			}
		};
	},
	methods: {
		async conditionsFetch( researchId, groupId, questionId, grouping ) {
			this.conditionModule.repository.loading = true;

			try
			{
				let groupingQuery = undefined
				if ( grouping !== null && typeof grouping === 'object' && typeof grouping.offset === 'number' && typeof grouping.limit === 'number' )
					groupingQuery = `?grouping=${grouping.limit}@${grouping.offset}`

				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/condition${groupingQuery ?? ''}`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					const responseBody = await response.json();

					this.conditionModule.repository.count = responseBody.count;
					this.conditionModule.repository.data = [];
					this.conditionModule.repository.idMap = {};
					responseBody.data.forEach(
						condition => {
							this.conditionModule.repository.idMap[ condition.id ] = this.conditionModule.repository.data.push(
								{
									...condition,
									'createAt': jMoment.utc( condition.createAt ).local(),
									'updateAt': typeof condition.updateAt === "string" ? jMoment.utc( condition.updateAt ).local() : null,
									'detailRepository': {
										'showModal': false
									},
									'removeRepository': {
										'showConfirmModal': false,
										'loading': false,
									}
								}
							) - 1;
						}
					);
				}
				else if ( response.status === 404 )
				{
					this.$notifications.warning( 'هیچ شرطی یافت نشد' );

					this.conditionModule.repository.count = 0;
					this.conditionModule.repository.data = [];
					this.conditionModule.repository.idMap = {};
				}
				else
					throw new Error(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json()
						}
					);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری شروط رخ داد' );
			}

			this.conditionModule.repository.loading = false;
		},
		async conditionAdd( researchId, groupId, questionId ) {
			this.conditionModule.new.loading = true;

			if (typeof this.conditionModule.new.data.conditionRelatedQuestionId !== 'number')
			{
				this.$notifications.error( 'مقدار وارد شده برای سوال وابسته نامعتبر می‌باشد' );

				this.conditionModule.new.showModal = false;
				this.conditionModule.new.loading = false;
				return false;
			}
			if (typeof this.conditionModule.new.data.match !== 'object')
			{
				this.$notifications.error( 'خطا در تعریف نوع شرط' );

				this.conditionModule.new.showModal = false;
				this.conditionModule.new.loading = false;
				return false;
			}

			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/condition`,
					{
						method: 'PUT',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(
							{
								'conditionRelatedQuestionId': this.conditionModule.new.data.conditionRelatedQuestionId,
								'action': 1,
								'match': this.conditionModule.new.data.match
							}
						)
					}
				);

				if ( response.ok )
				{
					this.conditionModule.new.conditionRelatedQuestion = null;
					this.conditionModule.new.dataPresent = null;
					this.conditionModule.new.questionChoice = null;
					this.conditionModule.new.data = {
						'conditionRelatedQuestionId': null,
						'match': {}
					};

					this.loadConditions();

					this.conditionModule.new.showModal = false;
					this.conditionModule.new.loading = false;

					this.$notifications.successful( 'شرط با موفقیت اضافه شد' );

					return true;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.conditionModule.new.showModal = false;
				this.conditionModule.new.loading = false;

				this.$notifications.error( 'خطای ناشناخته در هنگام افزودن شرط رخ داد' );

				return false;
			}
		},
		async conditionRemove( researchId, groupId, questionId, conditionId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/condition/${conditionId}`,
					{
						method: 'DELETE',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'شرط با موفقیت حذف شد.' );

					this.loadConditions();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام حذف شرط رخ داد' );
			}
		},
		async conditionModuleInitialize( researchId, groupId, questionId ) {
			this.conditionModule.question = await this.questionFetch( researchId, groupId, questionId );
			//if ( this.conditionModule.question?.validation?.required )
			//	return false;

			await this.questionsFetch({ researchId: researchId, groupId: groupId });
			delete this.questionModule.repository.idMap[ questionId ];

			this.loadConditions();

			return true;
		}
	},
	computed: {
		conditionModule_new_conditionRelatedQuestion() {
			return this.conditionModule.new.conditionRelatedQuestion;
		},
		conditionModule_new_dataPresent() {
			return this.conditionModule.new.dataPresent;
		},
		conditionModule_new_questionChoice() {
			return this.conditionModule.new.questionChoice;
		},
		conditionModule_new_data_match() {
			return this.conditionModule.new.data.match;
		}
	},
	watch: {
		conditionModule_new_conditionRelatedQuestion(newConditionRelatedQuestion, oldConditionRelatedQuestion) {
			this.conditionModule.new.dataPresent = null;
			this.conditionModule.new.questionChoice = null;
			this.conditionModule.new.data.match = {};
			this.conditionModule.new.data.conditionRelatedQuestionId = newConditionRelatedQuestion?.id;

			if ( typeof newConditionRelatedQuestion?.id === 'number' && [ QuestionType.Boolean, QuestionType.SelectionSingleAnswer, QuestionType.SelectionMultipleAnswer ].includes(newConditionRelatedQuestion?.type) )
				this.choicesFetch(this.$route.params.researchId, this.$route.params.groupId, newConditionRelatedQuestion.id)
		},
		conditionModule_new_dataPresent(newDataPresent, oldDataPresent) {
			delete this.conditionModule.new.data.match[ 'required' ];
			delete this.conditionModule.new.data.match[ 'mustBeNull' ];

			switch (newDataPresent)
			{
				case 'required':
					this.conditionModule.new.data.match[ 'required' ] = true;
					break;
				case 'mustBeNull':
					this.conditionModule.new.questionChoice = null;
					this.conditionModule.new.data[ 'match' ] = {};
					this.conditionModule.new.data.match[ 'mustBeNull' ] = true;
					break;
			}
		},
		conditionModule_new_questionChoice(newQuestionChoice, oldQuestionChoice) {
			delete this.conditionModule.new.data.match[ 'questionChoices' ];

			if ( typeof newQuestionChoice?.id === 'number' )
				this.conditionModule.new.data.match[ 'questionChoices' ] = [ newQuestionChoice.id ]
		},
		conditionModule_new_data_match: {
			handler: function (newMatch, oldMatch) {
				for ( const key of Object.keys(newMatch) )
					if ( newMatch[ key ] === undefined || newMatch[ key ] === null || newMatch[ key ] === '' )
						delete newMatch[ key ];
			},
			deep: true
		},
	}
}
