import * as jMoment from "moment-jalaali"

export default {
	data() {
		return {
			'choiceModule': {
				'repository': {
					'loading': true,
					'count': 0,
					'data': [],
					'idMap': {}
				},
				'new': {
					'showModal': false,
					'loading': false,
					'data': {
						'code': '',
						'title': ''
					}
				}
			}
		};
	},
	methods: {
		async choicesFetch( researchId, groupId, questionId, fetchOptions ) {
			this.choiceModule.repository.loading = true;

			try
			{
				let withDeleted = false
				let groupingQuery = '';
				let searchConditions = {};
				if ( fetchOptions && typeof fetchOptions === 'object' )
				{
					if( fetchOptions[ 'withDeleted' ] && typeof fetchOptions[ 'withDeleted' ] === 'boolean' )
						withDeleted = fetchOptions[ 'withDeleted' ]

					if ( fetchOptions[ 'grouping' ] && typeof fetchOptions[ 'grouping' ] === 'object' && typeof fetchOptions[ 'grouping' ].offset === 'number' && typeof fetchOptions[ 'grouping' ].limit === 'number' )
						groupingQuery = `${fetchOptions[ 'grouping' ].limit}@${fetchOptions[ 'grouping' ].offset}`;

					if ( fetchOptions[ 'searchConditions' ] && typeof fetchOptions[ 'searchConditions' ] === 'object' )
						searchConditions = fetchOptions[ 'searchConditions' ];
				}

				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice?include-metadata=true&with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					const responseBody = await response.json();

					this.choiceModule.repository.count = responseBody.count;
					this.choiceModule.repository.data = [];
					this.choiceModule.repository.idMap = {};
					responseBody.data.forEach(
						choice => {
							const length = this.choiceModule.repository.data.push(
								{
									...choice,
									"disabledAt": typeof choice.disabledAt === "string" ? jMoment.utc( choice.disabledAt ).local() : null,
									"firstAnswerSubmittedAt": typeof choice.firstAnswerSubmittedAt === "string" ? jMoment.utc( choice.firstAnswerSubmittedAt ).local() : null,
									"lastAnswerSubmittedAt": typeof choice.lastAnswerSubmittedAt === "string" ? jMoment.utc( choice.lastAnswerSubmittedAt ).local() : null,
									"createAt": jMoment.utc( choice.createAt ).local(),
									"updateAt": typeof choice.updateAt === "string" ? jMoment.utc( choice.updateAt ).local() : null,
									"detailRepository": {
										"showModal": false
									},
									'toggleRepository': {
										'loading': false
									},
									'updateRepository': {
										'showModal': false,
										'loading': false,
										'data': {
											'code': choice.code,
											'title': choice.title
										}
									},
									'removeRepository': {
										'showConfirmModal': false,
										'loading': false
									}
								}
							);

							this.choiceModule.repository.idMap[ choice.id ] = length - 1;
						}
					);
				}
				else if ( response.status === 404 )
				{
					this.$notifications.warning( 'هیچ گزینه‌ای یافت نشد' );

					this.choiceModule.repository.count = 0;
					this.choiceModule.repository.data = [];
					this.choiceModule.repository.idMap = {};
				}
				else
					throw new Error(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json()
						}
					);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری گزینه‌ها رخ داد' );
			}

			this.choiceModule.repository.loading = false;
		},
		async choiceFetch( researchId, groupId, questionId, choiceId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice/${choiceId}?include-metadata=true`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					const choice = await response.json();

					return {
						...choice,
						"disabledAt": typeof choice.disabledAt === "string" ? jMoment.utc( choice.disabledAt ).local() : null,
						"firstAnswerSubmittedAt": typeof choice.firstAnswerSubmittedAt === "string" ? jMoment.utc( choice.firstAnswerSubmittedAt ).local() : null,
						"lastAnswerSubmittedAt": typeof choice.lastAnswerSubmittedAt === "string" ? jMoment.utc( choice.lastAnswerSubmittedAt ).local() : null,
						"createAt": jMoment.utc( choice.createAt ).local(),
						"updateAt": typeof choice.updateAt === "string" ? jMoment.utc( choice.updateAt ).local() : null,
						"detailRepository": {
							"showModal": false
						},
						'toggleRepository': {
							'loading': false
						},
						'updateRepository': {
							'showModal': false,
							'loading': false,
							'data': {
								'code': choice.code,
								'title': choice.title
							}
						},
						'removeRepository': {
							'showConfirmModal': false,
							'loading': false
						}
					}
				}
				else if ( response.status === 404 )
					this.$notifications.warning( 'گزینه یافت نشد' );
				else
					throw new Error(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json()
						}
					);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری گزینه رخ داد' );
			}
		},
		choiceCodeValidator: code => (typeof code === 'string' || code instanceof String) && code.length > 0 && code.length < 33,
		choiceTitleValidator: title => (typeof title === 'string' || title instanceof String) && ( title === '' || (title.length > 1 && title.length < 512) ),
		async choiceAdd( researchId, groupId, questionId ) {
			this.choiceModule.new.loading = true;

			if (!this.choiceCodeValidator( this.choiceModule.new.data.code ))
			{
				this.$notifications.error( 'مقدار وارد شده برای کد گزینه نامعتبر می‌باشد' );

				this.choiceModule.new.showModal = false;
				this.choiceModule.new.loading = false;
				return false;
			}
			if (!this.choiceTitleValidator( this.choiceModule.new.data.title ?? '' ))
			{
				this.$notifications.error( 'مقدار وارد شده برای نام گزینه نامعتبر می‌باشد' );

				this.choiceModule.new.showModal = false;
				this.choiceModule.new.loading = false;
				return false;
			}

			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice`,
					{
						method: 'PUT',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(
							{
								'code': this.choiceModule.new.data.code,
								'title': (this.choiceModule.new.data.title ?? '') === '' ? null : this.choiceModule.new.data.title
							}
						)
					}
				);

				if ( response.ok )
				{
					this.loadChoices();

					this.choiceModule.new.data.code = '';
					this.choiceModule.new.data.title = '';

					this.choiceModule.new.showModal = false;
					this.choiceModule.new.loading = false;

					this.$notifications.successful( 'گزینه با موفقیت اضافه شد' );

					return true;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.choiceModule.new.showModal = false;
				this.choiceModule.new.loading = false;

				this.$notifications.error( 'خطای ناشناخته در هنگام افزودن گزینه رخ داد' );

				return false;
			}
		},
		async choiceEdit( researchId, groupId, questionId, choice ) {
			choice.updateRepository.loading = true;

			if (!this.choiceCodeValidator( choice.updateRepository.data.code ))
			{
				this.$notifications.error( 'مقدار وارد شده برای کد گزینه نامعتبر می‌باشد' );

				choice.updateRepository.showModal = false;
				choice.updateRepository.loading = false;
				return false;
			}
			if (!this.choiceTitleValidator( choice.updateRepository.data.title ?? '' ))
			{
				this.$notifications.error( 'مقدار وارد شده برای نام گزینه نامعتبر می‌باشد' );

				choice.updateRepository.showModal = false;
				choice.updateRepository.loading = false;
				return false;
			}

			const payload = {};
			if ( choice.updateRepository.data.code !== choice.code )
				payload.code = choice.updateRepository.data.code;
			if ( choice.updateRepository.data.title !== choice.title )
				payload.title = (choice.updateRepository.data.title ?? '') === '' ? null : choice.updateRepository.data.title;

			if (Object.values( payload ).length < 1)
			{
				choice.updateRepository.showModal = false;
				choice.updateRepository.loading = false;

				return false;
			}

			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${choice.questionId}/choice/${choice.id}`,
					{
						method: 'PATCH',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify( payload )
					}
				);

				if ( response.ok )
				{
					this.loadChoices();

					choice.updateRepository.showModal = false;
					choice.updateRepository.loading = false;

					this.$notifications.successful( 'گزینه با موفقیت ویرایش شد' );

					return true;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				choice.updateRepository.showModal = false;
				choice.updateRepository.loading = false;

				this.$notifications.error( 'خطای ناشناخته در هنگام ویرایش گزینه رخ داد' );

				return false;
			}
		},
		async choiceRemove( researchId, groupId, questionId, choiceId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice/${choiceId}?hard-delete=true`,
					{
						method: 'DELETE',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'گزینه با موفقیت حذف شد.' );

					this.loadChoices();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام حذف گزینه رخ داد' );
			}
		},
		async choiceDisable( researchId, groupId, questionId, choiceId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice/${choiceId}?hard-delete=false`,
					{
						method: 'DELETE',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'گزینه با موفقیت غیرفعال شد' );

					this.loadChoices();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام غیرفعال کزدن گزینه رخ داد' );
			}
		},
		async choiceEnable( researchId, groupId, questionId, choiceId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/choice/${choiceId}/restore`,
					{
						method: 'PATCH',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'گزینه با موفقیت فعال شد' );

					this.loadChoices();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام فعال کزدن گزینه رخ داد' );
			}
		}
	}
}
