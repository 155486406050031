import * as jMoment from "moment-jalaali";
import { QuestionType as QuestionTypeEnum, QuestionTypes, QuestionTypesArray } from "../../enums";

export default {
	data() {
		return {
			'questionModule': {
				'questionTypesArray': QuestionTypesArray,
				'questionTypes': QuestionTypes,
				'questionTypeEnum': QuestionTypeEnum,
				'repository': {
					'loading': true,
					'count': 0,
					'data': [],
					'idMap': {}
				},
				'new': {
					'showModal': false,
					'loading': false,
					'data': {
						'researchGroupId': null,
						'code': '',
						'title': '',
						'type': 1,
						'priority': 32766,
						'validation': {
							'required': false,
							'min': null,
							'max': null,
							'minLength': null,
							'maxLength': null
						},
						'configuration': {
							'dateType': 1
						}
					}
				}
			}
		};
	},
	methods: {
		async questionsFetch( fetchOptions ) {
			this.questionModule.repository.loading = true;

			try
			{
				let includeMetadata = false;
				let withDeleted = false;
				let groupingQuery = '';
				let searchConditions = {};
				let searchKeyword = undefined;
				if ( fetchOptions && typeof fetchOptions === 'object' )
				{
					if( fetchOptions[ 'includeMetadata' ] && typeof fetchOptions[ 'includeMetadata' ] === 'boolean' )
						includeMetadata = fetchOptions[ 'includeMetadata' ]

					if( fetchOptions[ 'withDeleted' ] && typeof fetchOptions[ 'withDeleted' ] === 'boolean' )
						withDeleted = fetchOptions[ 'withDeleted' ]

					if ( fetchOptions[ 'grouping' ] && typeof fetchOptions[ 'grouping' ] === 'object' && typeof fetchOptions[ 'grouping' ].offset === 'number' && typeof fetchOptions[ 'grouping' ].limit === 'number' )
						groupingQuery = `${fetchOptions[ 'grouping' ].limit}@${fetchOptions[ 'grouping' ].offset}`;

					if ( fetchOptions[ 'searchConditions' ] && typeof fetchOptions[ 'searchConditions' ] === 'object' )
						searchConditions = fetchOptions[ 'searchConditions' ];

					if ( typeof fetchOptions[ 'searchKeyword' ] === 'string' )
						searchKeyword = fetchOptions[ 'searchKeyword' ];
				}

				const response = await fetch(
					searchKeyword ? `${this.$authentication.serverAddress}/research/${fetchOptions[ 'researchId' ]}/group/${fetchOptions[ 'groupId' ]}/question/search/${searchKeyword}?include-metadata=${includeMetadata}&with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}` : `${this.$authentication.serverAddress}/research/${fetchOptions[ 'researchId' ]}/group/${fetchOptions[ 'groupId' ]}/question?include-metadata=${includeMetadata}&with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						}
					}
				);

				if ( response.ok )
				{
					const responseBody = await response.json();

					this.questionModule.new.data.priority = (responseBody.count + 1) * 10;
					this.questionModule.repository.data = [];
					this.questionModule.repository.count = responseBody.count;
					this.questionModule.repository.idMap = {};
					responseBody.data.forEach(
						question => {
							this.questionModule.repository.idMap[ question.id ] = this.questionModule.repository.data.push(
								{
									...question,
									"firstAnswerSubmittedAt": typeof question.firstAnswerSubmittedAt === "string" ? jMoment.utc( question.firstAnswerSubmittedAt ).local() : null,
									"lastAnswerSubmittedAt": typeof question.lastAnswerSubmittedAt === "string" ? jMoment.utc( question.lastAnswerSubmittedAt ).local() : null,
									"createAt": jMoment.utc( question.createAt ).local(),
									"updateAt": typeof question.updateAt === "string" ? jMoment.utc( question.updateAt ).local() : null,
									"deletedAt": typeof question.disabledAt === "string" ? jMoment.utc( question.disabledAt ).local() : null,
									"detailRepository": {
										"showModal": false
									},
									'toggleRepository': {
										'loading': false
									},
									'updateRepository': {
										'showModal': false,
										'loading': false,
										'data': {
											'researchGroupId': question.researchGroupId,
											'code': question.code,
											'title': question.title,
											'type': question.type,
											'priority': question.priority,
											'validation': {
												'required': question.validation.required ?? false,
												'min': question.validation.min ?? null,
												'max': question.validation.max ?? null,
												'minLength': question.validation.minLength ?? null,
												'maxLength': question.validation.maxLength ?? null
											},
											'configuration': {
												'dateType': question.configuration.dateType ?? 1
											}
										}
									},
									'removeRepository': {
										'showConfirmModal': false,
										'loading': false
									}
								}
							) - 1;
						}
					);
				}
				else if ( response.status === 404 )
				{
					if( typeof searchKeyword !== "string" )
						this.$notifications.warning( 'هیچ سوالی یافت نشد' );

					this.questionModule.new.data.priority = 10;
					this.questionModule.repository.count = 0;
					this.questionModule.repository.data = []
					this.questionModule.repository.idMap = {};
				}
				else
					throw new Error(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json()
						}
					);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری سوال‌ها رخ داد' );
			}

			this.questionModule.repository.loading = false;
		},
		async questionFetch( researchId, groupId, questionId, includeMetadata = false ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}?include-metadata=${includeMetadata}`,
					{
						method: 'GET',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					const question = await response.json();

					return {
						...question,
						"firstAnswerSubmittedAt": typeof question.firstAnswerSubmittedAt === "string" ? jMoment.utc( question.firstAnswerSubmittedAt ).local() : null,
						"lastAnswerSubmittedAt": typeof question.lastAnswerSubmittedAt === "string" ? jMoment.utc( question.lastAnswerSubmittedAt ).local() : null,
						"createAt": jMoment.utc( question.createAt ).local(),
						"updateAt": typeof question.updateAt === "string" ? jMoment.utc( question.updateAt ).local() : null,
						"deletedAt": typeof question.disabledAt === "string" ? jMoment.utc( question.disabledAt ).local() : null,
						"detailRepository": {
							"showModal": false
						},
						'toggleRepository': {
							'loading': false
						},
						'updateRepository': {
							'showModal': false,
							'loading': false,
							'data': {
								'researchGroupId': question.researchGroupId,
								'code': question.code,
								'title': question.title,
								'type': question.type,
								'priority': question.priority,
								'validation': {
									'required': question.validation.required ?? false,
									'min': question.validation.min ?? null,
									'max': question.validation.max ?? null,
									'minLength': question.validation.minLength ?? null,
									'maxLength': question.validation.maxLength ?? null
								},
								'configuration': {
									'dateType': question.configuration.dateType ?? 1
								}
							},
							'removeRepository': {
								'showConfirmModal': false,
								'loading': false
							}
						}
					}
				}
				else if ( response.status === 404 )
					this.$notifications.warning( 'سوال یافت نشد' );
				else
					throw new Error(
						{
							'status': response.status,
							'statusText': response.statusText,
							'response': await response.json()
						}
					);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری سوال رخ داد' );
			}
		},
		questionCodeValidator: code => (typeof code === 'string' || code instanceof String) && code.length > 0 && code.length < 33,
		questionTitleValidator: title => (typeof title === 'string' || title instanceof String) && ( title === '' || (title.length > 1 && title.length < 1024) ),
		questionTypeValidator( questionsType ) {
			return (typeof questionsType === 'number' || questionsType instanceof Number) && this.questionModule.questionTypesArray.includes( questionsType );
		},
		questionPriorityValidator: priority => priority === '' || ( (typeof priority === 'number' && !isNaN(priority)) && (priority > -32767 && priority < +32767) ),
		questionValidationRequiredValidator: validationRequired => typeof validationRequired === 'boolean' || validationRequired instanceof Boolean,
		questionValidationIntegerValidator( questionValidationInteger, min = undefined, max = undefined ) {
			try
			{
				return questionValidationInteger === null || questionValidationInteger === '' || ( (typeof questionValidationInteger === 'number' || questionValidationInteger instanceof Number) && this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( questionValidationInteger, { required: true, min: min, max: max } ) );
			}
			catch ( error )
			{
				return false;
			}
		},
		questionValidationFloatValidator( questionValidationFloat ) {
			try
			{
				return questionValidationFloat === null || questionValidationFloat === '' || ( (typeof questionValidationFloat === 'number' || questionValidationFloat instanceof Number) && this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Float ].validateAnswer( questionValidationFloat, { required: true } ) );
			}
			catch ( error )
			{
				return false;
			}
		},
		questionConfigurationDateTypeValidator: configurationDateType => typeof configurationDateType === 'number' && configurationDateType > -1 && configurationDateType < 2,
		async questionAdd( researchId, groupId ) {
			this.questionModule.new.loading = true;

			if (!this.questionCodeValidator( this.questionModule.new.data.code ))
			{
				this.$notifications.error( 'مقدار وارد شده برای کد سوال نامعتبر می‌باشد' );

				this.questionModule.new.loading = false;
				return false;
			}
			if (!this.questionTitleValidator( this.questionModule.new.data.title ?? '' ))
			{
				this.$notifications.error( 'مقدار وارد شده برای صورت سوال نامعتبر می‌باشد' );

				this.questionModule.new.loading = false;
				return false;
			}
			if (!this.questionTypeValidator( this.questionModule.new.data.type ))
			{
				this.$notifications.error( 'مقدار وارد شده برای نوع سوال نامعتبر می‌باشد' );

				this.questionModule.new.loading = false;
				return false;
			}
			if (!this.questionPriorityValidator( this.questionModule.new.data.priority ))
			{
				this.$notifications.error( 'مقدار وارد شده برای الویت نمایش سوال نامعتبر می‌باشد<br/>(مقدار باید از نوع عدد صحیح و بزرگتر از ۳۲۷۶۷− و کوچکتر از ۳۲۷۶۷+ باشد)' );

				this.questionModule.new.loading = false;
				return false;
			}
			if (!this.questionValidationRequiredValidator( this.questionModule.new.data.validation.required ))
			{
				this.$notifications.error( 'مقدار وارد شده برای اجباری بودن سوال نامعتبر می‌باشد' );

				this.questionModule.new.loading = false;
				return false;
			}

			const validation = {
				required: this.questionModule.new.data.validation.required
			};
			const configuration = {};

			switch ( this.questionModule.new.data.type )
			{
				case this.questionModule.questionTypeEnum.Integer:
					try
					{
						validation.min = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( this.questionModule.new.data.validation.min )

						if ( validation.min === null )
							delete validation.min
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای کوچکترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					try
					{
						validation.max = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( this.questionModule.new.data.validation.max )

						if ( validation.max === null )
							delete validation.max
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای بزرگترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.Float:
					try
					{
						validation.min = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Float ].validateAnswer( this.questionModule.new.data.validation.min )

						if ( validation.min === null )
							delete validation.min
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای کوچکترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					try
					{
						validation.max = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Float ].validateAnswer( this.questionModule.new.data.validation.max )

						if ( validation.max === null )
							delete validation.max
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای بزرگترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.String:
					try
					{
						validation.minLength = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( this.questionModule.new.data.validation.minLength, { min: 0, max: 4095 } )

						if ( validation.minLength === null )
							delete validation.minLength
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای حداقل طول رشته نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					try
					{
						validation.maxLength = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( this.questionModule.new.data.validation.maxLength, { min: 1, max: 4095 } )

						if ( validation.maxLength === null )
							delete validation.maxLength
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای حداکثر طول رشته نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.Date:
				case this.questionModule.questionTypeEnum.DateTime:
					if (!this.questionConfigurationDateTypeValidator( this.questionModule.new.data.configuration.dateType ))
					{
						this.$notifications.error( 'مقدار وارد شده برای نوع تقویم نامعتبر می‌باشد' );

						this.questionModule.new.loading = false;
						return false;
					}

					configuration[ 'dateType' ] = this.questionModule.new.data.configuration.dateType;

					break;
			}

			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question`,
					{
						method: 'PUT',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(
							{
								'code': this.questionModule.new.data.code,
								'title': (this.questionModule.new.data.title ?? '') === '' ? null : this.questionModule.new.data.title,
								'type': this.questionModule.new.data.type,
								'priority': this.questionModule.new.data.priority === '' ? 32766 : this.questionModule.new.data.priority,
								'configuration': configuration,
								'validation': validation
							}
						)
					}
				);

				if ( response.ok )
				{
					this.questionModule.new.data.code = '';
					this.questionModule.new.data.title = '';
					this.questionModule.new.data.type = 1;
					this.questionModule.new.data.priority = 32766;
					this.questionModule.new.data.validation = {
						'required': false,
						'min': null,
						'max': null,
						'minLength': null,
						'maxLength': null
					};

					this.loadQuestions();

					this.questionModule.new.showModal = false;
					this.questionModule.new.loading = false;

					this.$notifications.successful( 'سوال با موفقیت اضافه شد' );

					return true;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.questionModule.new.loading = false;

				this.$notifications.error( 'خطای ناشناخته در هنگام افزودن سوال رخ داد' );

				return false;
			}
		},
		async questionEdit( researchId, groupId, question ) {
			question.updateRepository.loading = true;

			if (!this.questionCodeValidator( question.updateRepository.data.code ))
			{
				this.$notifications.error( 'مقدار وارد شده برای کد سوال نامعتبر می‌باشد' );

				question.updateRepository.loading = false;
				return false;
			}
			if (!this.questionTitleValidator( question.updateRepository.data.title ?? '' ))
			{
				this.$notifications.error( 'مقدار وارد شده برای صورت سوال نامعتبر می‌باشد' );

				question.updateRepository.loading = false;
				return false;
			}
			if (!this.questionTypeValidator( question.updateRepository.data.type ))
			{
				this.$notifications.error( 'مقدار وارد شده برای نوع سوال نامعتبر می‌باشد' );

				question.updateRepository.loading = false;
				return false;
			}
			if (!this.questionPriorityValidator( question.updateRepository.data.priority ))
			{
				this.$notifications.error( 'مقدار وارد شده برای الویت نمایش سوال نامعتبر می‌باشد<br/>(مقدار باید از نوع عدد صحیح و بزرگتر از ۳۲۷۶۷− و کوچکتر از ۳۲۷۶۷+ باشد)' );

				question.updateRepository.loading = false;
				return false;
			}
			if (!this.questionValidationRequiredValidator( question.updateRepository.data.validation.required ))
			{
				this.$notifications.error( 'مقدار وارد شده برای اجباری بودن سوال نامعتبر می‌باشد' );

				question.updateRepository.loading = false;
				return false;
			}

			const payload = {
				validation: {
					required: question.updateRepository.data.validation.required
				},
				configuration: {}
			};
			if ( question.updateRepository.data.code !== question.code )
				payload.code = question.updateRepository.data.code;
			if ( question.updateRepository.data.title !== question.title )
				payload.title = (question.updateRepository.data.title ?? '') === '' ? null : question.updateRepository.data.title;
			if ( question.updateRepository.data.type !== question.type )
				payload.type = question.updateRepository.data.type;
			if ( question.updateRepository.data.priority !== question.priority )
				payload.priority = question.updateRepository.data.priority === '' ? 32766 : question.updateRepository.data.priority;

			switch ( question.updateRepository.data.type )
			{
				case this.questionModule.questionTypeEnum.Integer:
					try
					{
						payload.validation.min = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( question.updateRepository.data.validation.min )

						if ( payload.validation.min === null )
							delete payload.validation.min
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای کوچکترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					try
					{
						payload.validation.max = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( question.updateRepository.data.validation.max )

						if ( payload.validation.max === null )
							delete payload.validation.max
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای بزرگترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.Float:
					try
					{
						payload.validation.min = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Float ].validateAnswer( question.updateRepository.data.validation.min )

						if ( payload.validation.min === null )
							delete payload.validation.min
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای کوچکترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					try
					{
						payload.validation.max = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Float ].validateAnswer( question.updateRepository.data.validation.max )

						if ( payload.validation.max === null )
							delete payload.validation.max
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای بزرگترین مقداری که عدد میتواند داشته‌باشد نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.String:
					try
					{
						payload.validation.minLength = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( question.updateRepository.data.validation.minLength, { min: 0, max: 4095 } )

						if ( payload.validation.minLength === null )
							delete payload.validation.minLength
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای حداقل طول رشته نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					try
					{
						payload.validation.maxLength = this.questionModule.questionTypes[ this.questionModule.questionTypeEnum.Integer ].validateAnswer( question.updateRepository.data.validation.maxLength, { min: 1, max: 4095 } )

						if ( payload.validation.maxLength === null )
							delete payload.validation.maxLength
					}
					catch
					{
						this.$notifications.error( 'مقدار وارد شده برای حداکثر طول رشته نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					break;
				case this.questionModule.questionTypeEnum.Date:
				case this.questionModule.questionTypeEnum.DateTime:
					if (!this.questionConfigurationDateTypeValidator( question.updateRepository.data.configuration.dateType ))
					{
						this.$notifications.error( 'مقدار وارد شده برای نوع تقویم نامعتبر می‌باشد' );

						question.updateRepository.loading = false;
						return false;
					}

					payload.configuration[ 'dateType' ] = question.updateRepository.data.configuration.dateType;

					break;
			}

			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${question.id}`,
					{
						method: 'PATCH',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify( payload )
					}
				);

				if ( response.ok )
				{
					this.loadQuestions();

					question.updateRepository.showModal = false;
					question.updateRepository.loading = false;

					this.$notifications.successful( 'سوال با موفقیت ویرایش شد' );

					return true;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				question.updateRepository.loading = false;

				this.$notifications.error( 'خطای ناشناخته در هنگام ویرایش سوال رخ داد' );

				return false;
			}
		},
		async questionRemove( researchId, groupId, questionId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}?hard-delete=true`,
					{
						method: 'DELETE',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'سوال با موفقیت حذف گردید' );

					this.loadQuestions();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام حذف سوال رخ داد' );
			}
		},
		async questionDisable( researchId, groupId, questionId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}?hard-delete=false`,
					{
						method: 'DELETE',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'سوال با موفقیت غیرفعال شد' );

					this.loadQuestions();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام غیرفعال کردن سوال رخ داد' );
			}
		},
		async questionEnable( researchId, groupId, questionId ) {
			try
			{
				const response = await fetch(
					`${this.$authentication.serverAddress}/research/${researchId}/group/${groupId}/question/${questionId}/restore`,
					{
						method: 'PATCH',
						mode: 'cors',
						referrerPolicy: 'no-referrer',
						headers: {
							'Authorization': this.$authentication.jsonWebToken
						}
					}
				);

				if ( response.ok )
				{
					this.$notifications.successful( 'سوال با موفقیت فعال شد' );

					this.loadQuestions();

					return;
				}

				throw new Error(
					{
						'status': response.status,
						'statusText': response.statusText,
						'response': await response.json()
					}
				);
			}
			catch ( error )
			{
				console.error( error );

				this.$notifications.error( 'خطای ناشناخته در هنگام فعال کردن سوال رخ داد' );
			}
		}
	}
}
