import { Vue, Component } from "vue-property-decorator"
import { default as jMoment } from "moment-jalaali"
import { UserRole as UserRoleEnum } from "../../enums"
import { randomString as Helpers_randomString } from "../../helpers"
import { FetchOptionsType, UserModuleType, UserRepositoryType, UserType, UserUpdatePayloadType } from "./Types"

@Component
export class UserMixin extends Vue {
	protected readonly userModule: UserModuleType = {
		'alertModal': {
			'show': false,
			'title': '',
			'body': ''
		},
		'repository': {
			'loading': true,
			'count': 0,
			'data': [],
			'mobileMap': {}
		},
		'new': {
			'showModal': false,
			'loading': false,
			'data': {
				'role': UserRoleEnum.Admin,
				'mobile': '',
				'firstName': '',
				'lastName': '',
				'password': Helpers_randomString( 16 )
			}
		}
	}

	protected static userRoleValidator( role: number ): boolean
	{
		return typeof role === 'number' && [ UserRoleEnum.Admin, UserRoleEnum.Operator ].includes( role )
	}

	protected static userMobileValidator( mobile: string ): boolean
	{
		return typeof mobile === 'string' && /^09[0-9]{9}$/.test( mobile )
	}

	protected static userNameValidator( name: string ): boolean
	{
		return typeof name === 'string' && name.length > 2 && name.length < 41
	}

	protected static userPasswordValidator( password: string ): boolean
	{
		return typeof password === 'string' && password.length > 7 && password.length < 33 && /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test( password )
	}

	protected async usersFetch( fetchOptions?: null | FetchOptionsType ): Promise<void>
	{
		this.userModule.repository.loading = true

		try
		{
			let withDeleted = false
			let groupingQuery = ''
			let searchKeyword: string | null = null
			let searchConditions = {}
			if( fetchOptions && typeof fetchOptions === 'object' )
			{
				if( fetchOptions[ 'withDeleted' ] && typeof fetchOptions[ 'withDeleted' ] === 'boolean' )
					withDeleted = fetchOptions[ 'withDeleted' ]

				if( fetchOptions[ 'grouping' ] && typeof fetchOptions[ 'grouping' ] === 'object' && typeof fetchOptions[ 'grouping' ].offset === 'number' && typeof fetchOptions[ 'grouping' ].limit === 'number' )
					groupingQuery = `${fetchOptions[ 'grouping' ].limit}@${fetchOptions[ 'grouping' ].offset}`

				if ( typeof fetchOptions[ 'searchKeyword' ] === 'string' )
					searchKeyword = fetchOptions[ 'searchKeyword' ]

				if( fetchOptions[ 'searchConditions' ] && typeof fetchOptions[ 'searchConditions' ] === 'object' )
					searchConditions = fetchOptions[ 'searchConditions' ]
			}

			const response = await fetch(
				searchKeyword ? `${this.$authentication.serverAddress}/user/search/${searchKeyword}?with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}` : `${this.$authentication.serverAddress}/user?with-deleted=${withDeleted}&search-conditions=${JSON.stringify( searchConditions )}&grouping=${groupingQuery}`,
				{
					method: 'GET',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken,
						'Content-Type': 'application/json'
					}
				}
			)

			if( response.ok )
			{
				const responseBody: { count: number, data: UserType[] } = await response.json()

				this.userModule.repository.count = responseBody.count
				this.userModule.repository.data = []
				this.userModule.repository.mobileMap = {}
				responseBody.data.forEach(
					user => {
						const length = this.userModule.repository.data.push(
							{
								...user,
								"mobile": user.mobile.padStart(11, '0'),
								"createAt": jMoment.utc( user.createAt ).local(),
								"updateAt": typeof user.updateAt as string | null === "string" ? jMoment.utc( user.updateAt ).local() : null,

								"toggleRepository": {
									"loading": false
								},

								"updateRepository": {
									"showModal": false,
									"loading": false,
									"data": {
										"role": user.role,
										"firstName": user.firstName,
										"lastName": user.lastName,
										"password": "",
									}
								},

								"removeRepository": {
									"showConfirmModal": false,
									"loading": false
								}
							}
						)

						this.userModule.repository.mobileMap[ user.mobile ] = length - 1
					}
				)
			}
			else if( response.status === 404 )
			{
				if( typeof searchKeyword !== "string" )
					this.$notifications.warning( 'هیچ کاربری یافت نشد' )

				this.userModule.repository.count = 0
				this.userModule.repository.data = []
				this.userModule.repository.mobileMap = {}
			}
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری کاربران رخ داد' )
		}

		this.userModule.repository.loading = false
	}

	protected async userFetch( userMobile: UserType[ "mobile" ] ): Promise<UserRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}?with-deleted=true`,
				{
					method: 'GET',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const responseBody: UserType = await response.json()

				return {
					...responseBody,
					"mobile": responseBody.mobile.padStart(11, '0'),
					"createAt": jMoment.utc( responseBody.createAt ).local(),
					"updateAt": typeof responseBody.updateAt as string | null === "string" ? jMoment.utc( responseBody.updateAt ).local() : null,

					"toggleRepository": {
						"loading": false
					},

					"updateRepository": {
						"showModal": false,
						"loading": false,
						"data": {
							"role": responseBody.role,
							"firstName": responseBody.firstName,
							"lastName": responseBody.lastName,
							"password": "",
						}
					},

					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					}
				}
			}
			else if ( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( exception )
		{
			if( exception === 404 )
			{
				this.$notifications.warning( 'کاربر یافت نشد' )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری اطلاعات کاربر رخ داد' )

			throw exception
		}
	}

	protected async himselfFetch(): Promise<UserRepositoryType>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/profile`,
				{
					method: 'GET',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken
					}
				}
			)

			if ( response.ok )
			{
				const responseBody: UserType = await response.json()

				return {
					...responseBody,
					"mobile": responseBody.mobile.padStart(11, '0'),
					"createAt": jMoment.utc( responseBody.createAt ).local(),
					"updateAt": typeof responseBody.updateAt as string | null === "string" ? jMoment.utc( responseBody.updateAt ).local() : null,

					"toggleRepository": {
						"loading": false
					},

					"updateRepository": {
						"showModal": false,
						"loading": false,
						"data": {
							"role": responseBody.role,
							"firstName": responseBody.firstName,
							"lastName": responseBody.lastName,
							"password": "",
						}
					},

					"removeRepository": {
						"showConfirmModal": false,
						"loading": false
					},
				}
			}
			else if ( response.status === 404 )
				throw 404
			else
				throw new Error(
					`{
						status: ${response.status},
						statusText: ${response.statusText},
						response: ${JSON.stringify( await response.json() )}
					}`
				)
		}
		catch ( exception )
		{
			if( exception === 404 )
			{
				this.$notifications.warning( 'کاربر یافت نشد' )

				throw new Error(
					`{
						status: 404,
						statusText: Not Found
					}`
				)
			}

			this.$notifications.error( 'خطای ناشناخته در هنگام بارگیری اطلاعات کاربر رخ داد' )

			throw exception
		}
	}

	protected async userAdd(): Promise<boolean>
	{
		this.userModule.new.loading = true

		if( !UserMixin.userRoleValidator( this.userModule.new.data.role ) )
		{
			this.$notifications.error( 'مقدار وارد شده برای سطح دسترسی کاربر نامعتبر می‌باشد' )

			this.userModule.new.loading = false
			return false
		}

		if( !UserMixin.userMobileValidator( this.userModule.new.data.mobile ) )
		{
			this.$notifications.error( 'مقدار وارد شده برای موبایل کاربر نامعتبر می‌باشد' )

			this.userModule.new.loading = false
			return false
		}

		if( !UserMixin.userNameValidator( this.userModule.new.data.firstName ) )
		{
			this.$notifications.error( 'مقدار وارد شده برای نام کاربر نامعتبر می‌باشد' )

			this.userModule.new.loading = false
			return false
		}

		if( !UserMixin.userNameValidator( this.userModule.new.data.lastName ) )
		{
			this.$notifications.error( 'مقدار وارد شده برای نام‌خانوادگی کاربر نامعتبر می‌باشد' )

			this.userModule.new.loading = false
			return false
		}

		if( !UserMixin.userPasswordValidator( this.userModule.new.data.password ) )
		{
			this.$notifications.error( 'مقدار وارد شده برای رمزعبور کاربر نامعتبر می‌باشد' )

			this.userModule.new.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user`,
				{
					method: 'PUT',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(
						{
							'role': this.userModule.new.data.role,
							'mobile': this.userModule.new.data.mobile,
							'firstName': this.userModule.new.data.firstName,
							'lastName': this.userModule.new.data.lastName,
							'password': this.userModule.new.data.password
						}
					)
				}
			)

			if( response.ok )
			{
				(async () => {
					this.$notifications.successful( 'کاربر با موفقیت اضافه شد' )

					this.userModule.alertModal.title = `رمز عبور کاربر ${this.userModule.new.data.mobile}`
					this.userModule.alertModal.body = this.userModule.new.data.password
					this.userModule.alertModal.show = true

					this.userModule.new.data = {
						'role': UserRoleEnum.Admin,
						'mobile': '',
						'firstName': '',
						'lastName': '',
						'password': Helpers_randomString( 16 )
					}

					this.userModule.new.showModal = false
					this.userModule.new.loading = false
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.userModule.new.loading = false

			this.$notifications.error( 'خطای ناشناخته در هنگام افزودن کاربر رخ داد' )

			return false
		}
	}

	protected async userEdit( user: UserRepositoryType ): Promise<boolean>
	{
		user.updateRepository.loading = true

		const payload: UserUpdatePayloadType = {}

		if( user.updateRepository.data.password?.length > 0 )
		{
			if( !UserMixin.userPasswordValidator( user.updateRepository.data.password ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای رمزعبور کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}

			payload.password = user.updateRepository.data.password
		}
		else
		{
			if( !UserMixin.userRoleValidator( user.updateRepository.data.role ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای سطح دسترسی کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}
			if( user.updateRepository.data.role !== user.role )
				payload.role = user.updateRepository.data.role

			if( !UserMixin.userNameValidator( user.updateRepository.data.firstName ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای نام کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}
			if( user.updateRepository.data.firstName !== user.firstName )
				payload.firstName = user.updateRepository.data.firstName

			if( !UserMixin.userNameValidator( user.updateRepository.data.lastName ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای نام‌خانوادگی کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}
			if( user.updateRepository.data.lastName !== user.lastName )
				payload.lastName = user.updateRepository.data.lastName
		}

		if( Object.keys( payload ).length === 0 )
		{
			user.updateRepository.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${user.mobile}`,
				{
					method: 'PATCH',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				(async () => {
					user.updateRepository.showModal = false
					user.updateRepository.loading = false

					if( typeof payload.password === "string" && payload.password.length > 0 )
					{
						this.$notifications.successful( 'رمزعبور کاربر با موفقیت تغییر کرد' )

						this.userModule.alertModal.title = `رمز عبور کاربر ${user.mobile}`
						this.userModule.alertModal.body = payload.password
						this.userModule.alertModal.show = true
					}
					else
						this.$notifications.successful( 'کاربر با موفقیت ویرایش شد' )
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			user.updateRepository.loading = false

			this.$notifications.error( 'خطای ناشناخته در هنگام ویرایش کاربر رخ داد' )

			return false
		}
	}

	protected async himselfEdit( user: UserRepositoryType ): Promise<boolean>
	{
		user.updateRepository.loading = true

		const payload: UserUpdatePayloadType = {}

		if( user.updateRepository.data.password?.length > 0 )
		{
			if( !UserMixin.userPasswordValidator( user.updateRepository.data.password ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای رمزعبور کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}

			payload.password = user.updateRepository.data.password
		}
		else
		{
			if( !UserMixin.userNameValidator( user.updateRepository.data.firstName ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای نام کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}
			if( user.updateRepository.data.firstName !== user.firstName )
				payload.firstName = user.updateRepository.data.firstName

			if( !UserMixin.userNameValidator( user.updateRepository.data.lastName ) )
			{
				this.$notifications.error( 'مقدار وارد شده برای نام‌خانوادگی کاربر نامعتبر می‌باشد' )

				user.updateRepository.loading = false
				return false
			}
			if( user.updateRepository.data.lastName !== user.lastName )
				payload.lastName = user.updateRepository.data.lastName
		}

		if( Object.keys( payload ).length === 0 )
		{
			user.updateRepository.loading = false
			return false
		}

		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/profile`,
				{
					method: 'PATCH',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify( payload )
				}
			)

			if( response.ok )
			{
				(async () => {
					user.updateRepository.showModal = false
					user.updateRepository.loading = false

					if( typeof payload.password === "string" && payload.password.length > 0 )
						this.$notifications.successful( 'رمزعبور شما با موفقیت تغییر کرد' )
					else
						this.$notifications.successful( 'پروفایل شما با موفقیت ویرایش شد' )
				})()

				return true
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			user.updateRepository.loading = false

			this.$notifications.error( 'خطای ناشناخته در هنگام ویرایش پروفایل کاربر رخ داد' )

			return false
		}
	}

	protected userResetPassword( user: UserRepositoryType ): Promise<boolean>
	{
		user.updateRepository.data.password = Helpers_randomString( 16 )
		return this.userEdit( user )
	}

	protected async userRemove( userMobile: UserType[ "mobile" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}?hard-delete=true`,
				{
					method: 'DELETE',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( 'کاربر با موفقیت حذف شد' ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( 'خطای ناشناخته در هنگام حدف کاربر رخ داد' )
		}
	}

	protected async userDisable( userMobile: UserType[ "mobile" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}?hard-delete=false`,
				{
					method: 'DELETE',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( 'کاربر با موفقیت غیرفعال شد' ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( 'خطای ناشناخته در هنگام غیرفعال سازی کاربر رخ داد' )
		}
	}

	protected async userEnable( userMobile: UserType[ "mobile" ] ): Promise<void>
	{
		try
		{
			const response = await fetch(
				`${this.$authentication.serverAddress}/user/${userMobile}/restore`,
				{
					method: 'PATCH',
					mode: 'cors',
					referrerPolicy: 'no-referrer',
					headers: {
						'Authorization': this.$authentication.jsonWebToken
					}
				}
			)

			if( response.ok )
			{
				(async () => this.$notifications.successful( 'کاربر با موفقیت فعال شد' ))()

				return
			}

			throw new Error(
				`{
					status: ${response.status},
					statusText: ${response.statusText},
					response: ${JSON.stringify( await response.json() )}
				}`
			)
		}
		catch ( error )
		{
			console.error( error )

			this.$notifications.error( 'خطای ناشناخته در هنگام فعال سازی کاربر رخ داد' )
		}
	}
}

export default UserMixin
