




























































































































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { BaseTable, Paginator, Modal } from '../components'
import { ResearchMixin, ResearchModuleType } from "../mixins/ResearchMixin"
import { GroupMixin, GroupRepositoryType } from "../mixins/GroupMixin"


@Component({ components: { BaseTable, Modal, Paginator } })
export default class Groups extends Mixins( ResearchMixin, GroupMixin )
{
	private searchKeyword = ''
	private withDeleted = true
	private maxRecordsPerPage = 7
	private currentPage = Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1

	private readonly groupNameValidator = ( name: string ): boolean => GroupMixin.groupNameValidator( name )
	private readonly groupPriorityValidator = ( priority: number | '' ): boolean => GroupMixin.groupPriorityValidator( priority )

	private get pagesCount(): number
	{
		return Math.ceil( this.groupModule.repository.count / this.maxRecordsPerPage )
	}

	private get researchName(): string
	{
		return this.researchModule.repository.data.length > 0 && Object.keys(this.researchModule.repository.idMap).includes(this.$route.params.researchId) ? this.researchModule.repository.data[this.researchModule.repository.idMap[ this.$route.params.researchId ]].name : ""
	}

	private loadGroups(): Promise<void>
	{
		return this.groupsFetch(
			Number( this.$route.params.researchId ),
			{
				searchKeyword: typeof this.searchKeyword === 'string' && this.searchKeyword.length > 0 ? this.searchKeyword : null,
				withDeleted: this.withDeleted,
				grouping: {
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			}
		)
	}

	private searchByKeyword( event: Event )
	{
		this.searchKeyword = (<HTMLInputElement>event.target).value
		if ( this.currentPage === 1 )
		{
			if( this.searchKeyword === "" && Number( this.$route.query ) !== this.currentPage )
				this.$router.replace({ query: { page: String( this.currentPage ) } })

			this.loadGroups()
			return
		}

		this.currentPage = 1
	}

	private async doAndReload<ArgumentsType, ReturnType>( callback: ( ...args: ArgumentsType[] ) => ReturnType, ...args: ArgumentsType[] ): Promise<ReturnType>
	{
		const result = await callback( ...args )

		this.loadGroups()

		return result
	}

	private async groupAddAndReload( researchId: number ): Promise<boolean>
	{
		return await this.doAndReload( this.groupAdd, researchId )
	}

	private async groupToggleAndReload( group: GroupRepositoryType ): Promise<void>
	{
		group.toggleRepository.loading = true

		try
		{
			await this.doAndReload( group.deleted ? this.groupEnable : this.groupDisable, Number( this.$route.params.researchId ), group.id )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			group.toggleRepository.loading = false
		}
	}

	private async groupEditAndReload( group: GroupRepositoryType ): Promise<boolean>
	{
		return await this.doAndReload( this.groupEdit, group )
	}

	private async groupRemoveAndReload( group: GroupRepositoryType ): Promise<void>
	{
		group.removeRepository.loading = true

		try
		{
			await this.doAndReload( this.groupRemove, Number( this.$route.params.researchId ), group.id )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			group.removeRepository.loading = false
		}
	}

	@Watch('currentPage', { deep: false, immediate: true })
	private onCurrentPageChanged( newCurrentPage: number, oldCurrentPage: number )
	{
		if( (typeof this.searchKeyword !== 'string' || this.searchKeyword.length < 1) && Number( this.$route.query.page ) !== newCurrentPage )
			this.$router.replace({ query: { page: String( newCurrentPage ) } })

		this.researchesFetch({  })
		this.loadGroups().then(
			() => {
				if( this.groupModule.repository.count < 1 && newCurrentPage > 1 )
					this.currentPage = 1
			}
		)
	}
}
