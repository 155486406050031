import * as jMoment from "moment-jalaali"

export class ValidationError extends Error {
	constructor(messageBag) {
		super();

		this.messageBag = messageBag;
	}
}

const answerValidationException = (filedName, errorCase) => new ValidationError(
	{
		'en': `Invalid value for filed ${filedName} given, the value must be ${errorCase.en}`,
		'fa': `مقدار دریافتی برای فیلد ${filedName} نامعتبر است، مقدار باید ${errorCase.fa} `
	}
);

export const QuestionTypesArray = [ 1, 2, 3, 5, 6, 7, 8, 9, 10 ];

export const QuestionType = {
	1: "Integer",
	"Integer": 1,
	2: "Float",
	"Float": 2,
	3: "String",
	"String": 3,
	4: "Geographic",
	"Geographic": 4,
	5: "Time",
	"Time": 5,
	6: "Date",
	"Date": 6,
	7: "DateTime",
	"DateTime": 7,
	8: "Boolean",
	"Boolean": 8,
	9: "SelectionSingleAnswer",
	"SelectionSingleAnswer": 9,
	10: "SelectionMultipleAnswer",
	"SelectionMultipleAnswer": 10,
	11: "File",
	"File": 11
};

export const QuestionTypes = {
	[QuestionType.Integer]: {
		'name': {
			'en': "Integer",
			'fa': "عدد صحیح",
		},
		'description': {
			'en': "Answer to the question must be integer",
			'fa': "پاسخ سوال باید عدد صحیح باشد",
		},
		'thumbnail': "/static/question-types/integer.png",
		'regexMatch': /^(0+|((-|\+)?(0*[1-9][0-9]*)))$/,
		'validateAnswer': (answer, validations, filedName = 'integer') => {
			try
			{
				answer = ( answer === undefined || answer === null || isNaN( answer ) || answer === '' ) ? null : Number( answer )

				if( isNaN( answer ) || ( answer !== null && !QuestionTypes[QuestionType.Integer].regexMatch.test( answer.toString() ) ) )
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'an integer', fa: 'یک عدد صحیح باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( ( typeof validations.required === 'boolean' && validations.required === true ) && answer === null )
					throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )

				if( typeof answer === 'number' )
				{
					if( QuestionTypes[QuestionType.Integer].regexMatch.test( String( validations.min ) ) && answer < Number( validations.min ) )
						throw answerValidationException( filedName, { en: `not lower than ${validations.min}`, fa: `کوچکتر از ${validations.min} نباشد` } )

					if( QuestionTypes[QuestionType.Integer].regexMatch.test( String( validations.max ) ) && answer > Number( validations.max ) )
						throw answerValidationException( filedName, { en: `not grater than ${validations.max}`, fa: `بزرگتر از ${validations.max} نباشد` } )
				}
			}

			return answer
		}
	},
	[QuestionType.Float]: {
		'name': {
			'en': "Float",
			'fa': "عدد اعشار",
		},
		'description': {
			'en': "Answer to the question must be floating point number with two digits precision",
			'fa': "پاسخ سوال باید عدد اعشار با دو رقم دقت باشد",
		},
		'thumbnail': "/static/question-types/float.png",
		'regexMatch': /^((0+|([-+]?(0*[1-9][0-9]{0,8})))(\.[0-9]{1,5})?)$/,
		'validateAnswer': (answer, validations, filedName = 'float') => {
			try
			{
				answer = ( answer === undefined || answer === null || isNaN( answer ) || answer === '' ) ? null : Number( answer )

				if( isNaN( answer ) || ( answer !== null && !QuestionTypes[QuestionType.Float].regexMatch.test( answer.toString() ) ) )
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'a floating point number', fa: 'یک عدد اعشاری باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( ( typeof validations.required === 'boolean' && validations.required === true ) && answer === null )
					throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )

				if( typeof answer === 'number' )
				{
					if( QuestionTypes[QuestionType.Integer].regexMatch.test( String( validations.min ) ) && answer < Number( validations.min ) )
						throw answerValidationException( filedName, { en: `not lower than ${validations.min}`, fa: `کوچکتر از ${validations.min} نباشد` } )

					if( QuestionTypes[QuestionType.Integer].regexMatch.test( String( validations.max ) ) && answer > Number( validations.max ) )
						throw answerValidationException( filedName, { en: `not grater than ${validations.max}`, fa: `بزرگتر از ${validations.max} نباشد` } )
				}
			}

			return answer
		}
	},
	[QuestionType.String]: {
		'name': {
			'en': "String",
			'fa': "رشته‌ی کاراکتری",
		},
		'description': {
			'en': "Answer to the question must be string of supported characters",
			'fa': "پاسخ سوال باید رشته‌ای از کاراکترهای مجاز باشد",
		},
		'thumbnail': "/static/question-types/string.png",
		'regexMatch': undefined,
		'validateAnswer': (answer, validations, filedName = 'string') => {
			try
			{
				answer = ( answer === undefined || answer === null || answer === '' ) ? null : String( answer )

				if( answer === '' )
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'a string of characters', fa: 'یک رشته‌ی کاراکتری باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( ( typeof validations.required === 'boolean' && validations.required === true ) && answer === null )
					throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )

				if( typeof answer === 'string' )
				{
					if( typeof validations.minLength === 'number' && answer.length < validations.minLength )
						throw answerValidationException( filedName, { en: `with length not lower than ${validations.minLength}`, fa: `دارای طولی کوچکتر از ${validations.minLength} نباشد` } )

					if( answer.length > 4096 || ( typeof validations.maxLength === 'number' && answer.length > validations.maxLength ) )
						throw answerValidationException( filedName, { en: `with length not grater than ${validations?.maxLength ?? 4096}`, fa: `دارای طولی بزرگتر از ${validations.maxLength} نباشد` } )
				}
			}

			return answer
		}
	},
	[QuestionType.Geographic]: {
		'name': {
			'en': "Geographic",
			'fa': "مختصات جغرافیایی",
		},
		'description': {
			'en': "Answer to the question must be a geographic point that contains latitude and longitude",
			'fa': "پاسخ سوال باید عدد یک مختصات جغرافیایی شامل طول و عرض جغرافیایی باشد",
		},
		'thumbnail': "/static/question-types/geographic.png",
		'regexMatch': /^(((-|)\d*\.\d*)|((-|)\d*(\.|,)\d*))$/,
		'validateAnswer': (answer, validations, filedName = 'geographic') => {
			try
			{
				if(
					( answer.latitude === undefined || answer.latitude === null || isNaN( answer.latitude ) || answer.latitude === '' ) ||
					( answer.longitude === undefined || answer.longitude === null || isNaN( answer.longitude ) || answer.longitude === '' )
				)
				{
					answer.latitude = null
					answer.longitude = null
				}
				else
				{
					answer.latitude = Number( answer.latitude )
					answer.longitude = Number( answer.longitude )
				}

				if(
					( isNaN( answer.latitude ) || ( answer.latitude !== null && !QuestionTypes[QuestionType.Geographic].regexMatch.test( answer.latitude.toString() ) ) ) ||
					( isNaN( answer.longitude ) || ( answer.longitude !== null && !QuestionTypes[QuestionType.Geographic].regexMatch.test( answer.longitude.toString() ) ) )
				)
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'contains valid latitude and longitude information', fa: 'شامل طول و عرض جغرافیایی معتبر باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && ( answer.latitude !== null || answer.longitude !== null ) )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( ( typeof validations.required === 'boolean' && validations.required === true ) && ( answer.latitude === null || answer.longitude === null ) )
					throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
			}

			return { latitude: answer.latitude, longitude: answer.longitude }
		}
	},
	[QuestionType.Time]: {
		'name': {
			'en': "Time",
			'fa': "زمان",
		},
		'description': {
			'en': "Answer to the question must be time that determine hour and minute of a moment",
			'fa': "پاسخ سوال باید از نوع زمان و شامل ساعت و دقیقه باشد",
		},
		'thumbnail': "/static/question-types/time.png",
		'regexMatch': /^((((0|1)[0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9]))$/,
		'validateAnswer': (answer, validations, filedName = 'time') => {
			let answerObject

			try
			{
				answer = ( answer === undefined || answer === null || answer === '' ) ? null : String( answer )

				if( answer === null )
					answerObject = null
				else if( !QuestionTypes[QuestionType.Time].regexMatch.test( answer ) )
					throw new Error
				else
					answerObject = new Date(`1970-01-01T${answer}.000Z`)
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'a time with hh:ii:ss format', fa: 'یک زمان با فرمت hh:ii:ss باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( answerObject === null )
				{
					if( ( typeof validations.required === 'boolean' && validations.required === true ) )
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
				}
				else
				{
					if( typeof answerObject === 'object' && answerObject instanceof Date )
					{
						if(
							( typeof validations.min === 'string' && QuestionTypes[QuestionType.Time].regexMatch.test( validations.min ) ) &&
							( answerObject.getTime() < ( new Date(`1970-01-01T${validations.min}.000Z`) ).getTime() )
						)
							throw answerValidationException( filedName, { en: `not lower than ${validations.min}`, fa: `کوچکتر از ${validations.min} نباشد` } )

						if(
							( typeof validations.max === 'string' && QuestionTypes[QuestionType.Time].regexMatch.test( validations.max ) ) &&
							( answerObject.getTime() > ( new Date(`1970-01-01T${validations.max}.000Z`) ).getTime() )
						)
							throw answerValidationException( filedName, { en: `not grater than ${validations.max}`, fa: `بزرگتر از ${validations.max} نباشد` } )
					}
				}
			}

			return answer
		}
	},
	[QuestionType.Date]: {
		'name': {
			'en': "Date",
			'fa': "تاریخ",
		},
		'description': {
			'en': "Answer to the question must be date in yyyy-mm-dd format",
			'fa': "پاسخ سوال باید تاریخ و شامل سال، ماه و روز باشد",
		},
		'thumbnail': "/static/question-types/date.png",
		'regexMatch': /^(([12][0-9]{3})-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(3([01])))) \(([GgJj])\)$/,
		'validateAnswer': (answer, validations, filedName = 'time') => {
			let answerObject

			try
			{
				answer = ( answer === undefined || answer === null || answer === '' ) ? null : String( answer )

				if( answer === null )
					answerObject = null
				else
				{
					const matchResult = QuestionTypes[QuestionType.Date].regexMatch.exec( answer )

					if( matchResult )
					{
						if(['J', 'j'].includes( matchResult[ 11 ] ))
							answer = jMoment( matchResult[ 1 ], 'jYYYY-jMM-jDD' ).format( 'YYYY-MM-DD' )
						else
							answer = matchResult[ 1 ]

						answerObject = new Date(`${answer}T00:00:00.000Z`)
					}
					else
						throw new Error
				}
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'a time with {yyyy-mm-dd (G|J)} format', fa: 'یک زمان با فرمت {yyyy-mm-dd (G|J)} باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( answerObject === null )
				{
					if( ( typeof validations.required === 'boolean' && validations.required === true ) )
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
				}
				else
				{
					if( typeof answerObject === 'object' && answerObject instanceof Date )
					{
						if(
							( typeof validations.min === 'string' && QuestionTypes[QuestionType.Date].regexMatch.test( validations.min ) ) &&
							( answerObject.getTime() < ( new Date(`${validations.min}T00:00:00.000Z`) ).getTime() )
						)
							throw answerValidationException( filedName, { en: `not lower than ${validations.min}`, fa: `کوچکتر از ${validations.min} نباشد` } )

						if(
							( typeof validations.max === 'string' && QuestionTypes[QuestionType.Date].regexMatch.test( validations.max ) ) &&
							( answerObject.getTime() > ( new Date(`${validations.max}T00:00:00.000Z`) ).getTime() )
						)
							throw answerValidationException( filedName, { en: `not grater than ${validations.max}`, fa: `بزرگتر از ${validations.max} نباشد` } )
					}
				}
			}

			return answer
		}
	},
	[QuestionType.DateTime]: {
		'name': {
			'en': "DateTime",
			'fa': "تاریخ و زمان",
		},
		'description': {
			'en': "Answer to the question must be contain date and time in standard formats",
			'fa': "پاسخ سوال باید شامل تاریخ و زمان باشد",
		},
		'thumbnail': "/static/question-types/date-time.png",
		'regexMatch': /^(([12][0-9]{3})-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2][0-9])|(3([01]))) ((([01])[0-9])|(2[0-3])):([0-5][0-9]):([0-5][0-9])) \(([GgJj])\)$/,
		'validateAnswer': (answer, validations, filedName = 'date_time') => {
			let answerObject

			try
			{
				answer = ( answer === undefined || answer === null || answer === '' ) ? null : String( answer )

				if( answer === null )
					answerObject = null
				else
				{
					const millisecond = answer.match(/(\.\d{3}Z) \([GgJj]\)$/)
					if( millisecond && 1 in millisecond )
						answer = answer.replace( millisecond[ 1 ], '' )

					const matchResult = QuestionTypes[QuestionType.DateTime].regexMatch.exec( answer )

					if( matchResult )
					{
						answer = jMoment(
							matchResult[ 1 ],
							['J', 'j'].includes( matchResult[ 17 ] ) ? 'jYYYY-jMM-jDD HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss'
						).format( 'YYYY-MM-DDTHH:mm:ss' )

						answerObject = new Date( `${answer}.000Z` )
					}
					else
						throw new Error
				}
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'a date time with {yyyy-mm-dd hh-ii-ss (G|J)} format', fa: 'یک تاریخ و زمان با فرمت {yyyy-mm-dd hh-ii-ss (G|J)} باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if( answerObject === null )
				{
					if( ( typeof validations.required === 'boolean' && validations.required === true ) )
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
				}
				else
				{
					if( typeof answerObject === 'object' && answerObject instanceof Date )
					{
						if(
							( typeof validations.min === 'string' && QuestionTypes[QuestionType.DateTime].regexMatch.test( validations.min ) ) &&
							( answerObject.getTime() < (new Date( `${validations.min}.000Z` )).getTime() )
						)
							throw answerValidationException( filedName, { en: `not lower than ${validations.min}`, fa: `کوچکتر از ${validations.min} نباشد` } )

						if(
							( typeof validations.max === 'string' && QuestionTypes[QuestionType.DateTime].regexMatch.test( validations.max ) ) &&
							( answerObject.getTime() > (new Date( `${validations.max}.000Z` )).getTime() )
						)
							throw answerValidationException( filedName, { en: `not grater than ${validations.max}`, fa: `بزرگتر از ${validations.max} نباشد` } )
					}
				}
			}

			return answer
		}
	},
	[QuestionType.Boolean]: {
		'name': {
			'en': "Boolean",
			'fa': "دو گزینه‌ای",
		},
		'description': {
			'en': "Answer to the question must be selected among two defined options",
			'fa': "پاسخ سوال باید از بین یکی از دو گزینه تعریف شده انتخاب شود",
		},
		'thumbnail': "/static/question-types/boolean.png",
		'regexMatch': /^\+?(0*[1-9][0-9]*)$/,
		'validateAnswer': (answer, validations, filedName = 'boolean') => {
			try
			{
				answer = ( answer === undefined || answer === null || isNaN( answer ) || answer === '' ) ? null : Number( answer )

				if( isNaN( answer ) || ( answer !== null && !QuestionTypes[QuestionType.Boolean].regexMatch.test( answer.toString() ) ) )
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'an integer', fa: 'یک عدد صحیح باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( answer === null )
				{
					if( typeof validations.required === 'boolean' && validations.required === true )
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
				}
				else
				{
					if( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true )
						throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

					compareAnswerWithChoices:
						if( validations.questionChoices instanceof Array && validations.questionChoices.length > 0 )
						{
							for( const questionChoice of Object.values( validations.questionChoices ).slice(0, 2) )
								if(
									QuestionTypes[QuestionType.Boolean].regexMatch.test( questionChoice.toString() ) &&
									answer === Number( questionChoice )
								)
									break compareAnswerWithChoices

							throw answerValidationException( filedName, { en: `in range of the question choices`, fa: `باید یکی از گزینه‌های سوال مورد نظر باشد` } )
						}
				}
			}

			return answer
		}
	},
	[QuestionType.SelectionSingleAnswer]: {
		'name': {
			'en': "Selection with single answer",
			'fa': "چند گزینه‌ای (یک جوابی)",
		},
		'description': {
			'en': "A single answer to the question must be selected among all of the defined options",
			'fa': "تنها یک پاسخ برای سوال باید از میان همه‌ی گزینه‌هاي تعریف شده انتخاب شود",
		},
		'thumbnail': "/static/question-types/selection-single-answer.png",
		'regexMatch': /^\+?(0*[1-9][0-9]*)$/,
		'validateAnswer': (answer, validations, filedName = 'selection_single_answer') => {
			try
			{
				answer = ( answer === undefined || answer === null || isNaN( answer ) || answer === '' ) ? null : Number( answer )

				if( isNaN( answer ) || ( answer !== null && !QuestionTypes[QuestionType.SelectionSingleAnswer].regexMatch.test( answer.toString() ) ) )
					throw new Error
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'an integer', fa: 'یک عدد صحیح باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( answer === null )
				{
					if( typeof validations.required === 'boolean' && validations.required === true )
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
				}
				else
				{
					if( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true )
						throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

					compareAnswerWithChoices:
						if( validations.questionChoices instanceof Array && validations.questionChoices.length > 0 )
						{
							for( const choice of validations.questionChoices )
								if(
									QuestionTypes[QuestionType.SelectionSingleAnswer].regexMatch.test( choice.toString() ) &&
									answer === Number( choice)
								)
									break compareAnswerWithChoices

							throw answerValidationException( filedName, { en: `in range of the question choices`, fa: `باید یکی از گزینه‌های سوال مورد نظر باشد` } )
						}
				}
			}

			return answer
		}
	},
	[QuestionType.SelectionMultipleAnswer]: {
		'name': {
			'en': "Selection with multiple answers",
			'fa': "چند گزینه‌ای (چند جوابی)",
		},
		'description': {
			'en': "Multiple answers to the question can be selected among all of the defined options",
			'fa': "چندین پاسخ مختلف برای سوال می‌تواند از میان همه‌ی گزینه‌هاي تعریف شده انتخاب شود",
		},
		'thumbnail': "/static/question-types/selection-multiple-answer.png",
		'regexMatch': /^\+?(0*[1-9][0-9]*)$/,
		'validateAnswer': (answersArgs, validations, filedName = 'selection_multiple_answer') => {
			let answers

			try
			{
				if( !( answersArgs instanceof Array ) )
					throw new Error

				answers = answersArgs.map(
					( element ) => {
						const newElement = ( element === undefined || element === null || isNaN( element ) || element === '' ) ? null : Number( element )

						if( isNaN( newElement ) || ( newElement !== null && !QuestionTypes[QuestionType.SelectionMultipleAnswer].regexMatch.test( newElement.toString() ) ) )
							throw new Error

						return newElement
					}
				)
			}
			catch( error )
			{
				throw answerValidationException( filedName, { en: 'an integer', fa: 'یک عدد صحیح باشد' } )
			}

			if( validations !== null && typeof validations === 'object' )
			{
				if( typeof validations.required === 'boolean' && validations.required === true )
				{
					if( answers.length < 1 )
					{
						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
					}

					checkAnswersNotEmpty:
					{
						for( const answer of answers )
						{
							if ( answer !== null )
								break checkAnswersNotEmpty
						}

						throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
					}
				}

				if( answers.length > 0 )
				{
					if( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true )
						throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

					answers = [
						...new Set( answers )
					];

					if( validations.questionChoices instanceof Array && validations.questionChoices.length > 0 )
					{
						for( const answer of answers )
						{
							compareAnswerWithChoices:
							{
								for( const choice of validations.questionChoices )
								{
									if(
										QuestionTypes[QuestionType.SelectionMultipleAnswer].regexMatch.test( choice.toString() ) &&
										answer === Number( choice )
									)
										break compareAnswerWithChoices
								}

								throw answerValidationException( filedName, { en: `in range of the question choices`, fa: `باید یکی از گزینه‌های سوال مورد نظر باشد` } )
							}
						}
					}
				}
			}

			return answers
		}
	},
	[QuestionType.File]: {
		'name': {
			'en': "File",
			'fa': "مستندات",
		},
		'description': {
			'en': "Answer to the question must be file or files that attachments to form",
			'fa': "در پاسخ سوال باید فایل یا فایل‌های مربوطه پیوست شوند",
		},
		'thumbnail': "/static/question-types/file.png",
		'regexMatch': undefined,
		'validateAnswer': (answer, validations, filedName = 'file') => {
			if( validations !== null && typeof validations === 'object' )
			{
				if( ( typeof validations.mustBeNull === 'boolean' && validations.mustBeNull === true ) && answer !== null )
					throw answerValidationException( filedName, { en: 'empty', fa: 'خالی' } )

				if(
					( typeof validations.required === 'boolean' && validations.required === true ) &&
					( answer === undefined || answer === null || isNaN( answer ) )
				)
					throw answerValidationException( filedName, { en: 'not empty', fa: 'ارسال شود' } )
			}
		}
	}
};
