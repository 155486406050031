















































import { Component, Mixins } from "vue-property-decorator"
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import { ProvinceMixin, DocumentMixin } from '../mixins'
import type { DocumentRepositoryType } from '../mixins/DocumentMixin'

@Component({ components: { datePicker: VuePersianDatetimePicker } })
export default class EditDocument extends Mixins( ProvinceMixin, DocumentMixin )
{
	private document: DocumentRepositoryType | null = null

	beforeMount(): void
	{
		this.provincesFetch()

		this.documentFetch( parseInt(this.$route.params.documentId) ).then(
			document => {
				this.document = document

				this.$watch(
					"document.provinceId",
					(newProvinceId, oldProvinceId) => {
						this.provinceModule.cityRepository.data = []
						this.documentModule.new.data.cityId = 0

						this.citiesFetch( newProvinceId )
					},
					{ "immediate": true }
				)
			},
			error => { throw error }
		)
	}
}
