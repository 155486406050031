














































import { Component, Mixins, Watch } from "vue-property-decorator"
import { ProvinceMixin, DocumentMixin } from '../mixins'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

@Component({ components: { datePicker: VuePersianDatetimePicker } })
export default class NewDocument extends Mixins( ProvinceMixin, DocumentMixin )
{
	provinceId = 0

	@Watch( "provinceId", { deep: false, immediate: false } )
	provinceChanged( newProvinceId: number, oldProvinceId: number ): void
	{
		this.provinceModule.cityRepository.data = []
		this.documentModule.new.data.cityId = 0

		this.citiesFetch( newProvinceId )
	}

	created(): void
	{
		this.provincesFetch()
		this.citiesFetch( 0 )
	}
}
