






















































































































































































































































import { Component, Mixins, Watch } from "vue-property-decorator"
import { ResearchMixin } from '../mixins'
import { BaseTable, Paginator, Modal } from '../components'
import { UserRole as UserRoleEnum } from '../enums'
import { ResearchPermissionEnum } from '../mixins/UserPermissionMixin'
import type { ResearchRepositoryType } from '../mixins/ResearchMixin'

@Component({ components: { BaseTable, Modal, Paginator } })
export class Researches extends Mixins( ResearchMixin )
{
	ResearchPermissionEnum = ResearchPermissionEnum
	isAdmin = (this.$authentication.userToken?.payloadParsed.role as number) < UserRoleEnum.Agent
	withDisabled = true
	maxRecordsPerPage = 7
	currentPage = Number( this.$route.query.page ) > 0 && Number( this.$route.query.page ) !== Infinity ? Number( this.$route.query.page ) : 1
	readonly researchNameValidator = ResearchMixin.researchNameValidator
	readonly researchDescriptionValidator = ResearchMixin.researchDescriptionValidator

	get pagesCount(): number
	{
		return Math.ceil( this.researchModule.repository.count / this.maxRecordsPerPage )
	}

	getUserNameFromResearch( research: ResearchRepositoryType ): string
	{
		return typeof research[ 'userFirstName' ] === 'string' && typeof research[ 'userLastName' ] === 'string' ? `${research[ 'userFirstName' ]} ${research[ 'userLastName' ]}` : ''
	}

	loadResearches(): Promise<void>
	{
		return this.researchesFetch(
			{
				withDisabled: this.withDisabled,
				grouping: {
					limit: this.maxRecordsPerPage,
					offset: this.currentPage
				}
			}
		);
	}

	private async doAndReload<ArgumentsType, ReturnType>( callback: ( ...args: ArgumentsType[] ) => ReturnType, ...args: ArgumentsType[] ): Promise<ReturnType>
	{
		const result = await callback( ...args )

		this.loadResearches()

		return result
	}

	private async researchAddAndReload(): Promise<boolean>
	{
		return await this.doAndReload( this.researchAdd )
	}

	private async researchToggleAndReload( research: ResearchRepositoryType ): Promise<void>
	{
		research.toggleRepository.loading = true

		try
		{
			await this.doAndReload( this.researchToggle, research.id )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			research.toggleRepository.loading = false
		}
	}

	private async researchEditAndReload( research: ResearchRepositoryType ): Promise<boolean>
	{
		return await this.doAndReload( this.researchEdit, research )
	}

	private async researchRemoveAndReload( research: ResearchRepositoryType ): Promise<void>
	{
		research.removeRepository.loading = true

		try
		{
			await this.doAndReload( this.researchRemove, research.id )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			research.removeRepository.loading = false
		}
	}

	private async researchExportCSV( research: ResearchRepositoryType ): Promise<void>
	{
		research.exportRepository.loading = true

		try
		{
			await this.researchExport( research.id )
		}
		catch( exception )
		{
			console.error( exception )
		}
		finally
		{
			research.exportRepository.loading = false
		}
	}

	@Watch('currentPage', { deep: false, immediate: true })
	private onCurrentPageChanged( newCurrentPage: number, oldCurrentPage: number ): void
	{
		if( Number( this.$route.query.page ) !== newCurrentPage )
			this.$router.replace({ query: { page: String( newCurrentPage ) } })

		this.loadResearches().then(
			() => {
				if( this.researchModule.repository.count < 1 && newCurrentPage > 1 )
					this.currentPage = 1
			}
		)
	}
}

export default Researches
